export const setCameraStatus = (flag) =>
({
    type: 'SET_CAMERA_STATUS',
    payload: { value: flag }
});

export const setVideoDevices = (update) =>
({
    type: 'SET_VIDEO_DEVICES',
    payload: update
});

export const setCurrentVideoDevice = (update) =>
({
    type: 'SET_CURRENT_VIDEO_DEVICE',
    payload: update
});

export const setCurrentVideoQuality = (update) =>
({
    type: 'SET_CURRENT_VIDEO_QUALITY',
    payload: update
})

export const setIsUserFacingCamera = (value) =>
({
    type: 'SET_IS_USER_FACING_CAMERA',
    payload: { value: value }
})

export const setStopAllVideos = (flag) =>
({
    type: 'SET_STOP_ALL_VIDEOS',
    payload: { value: flag }
})

export const setVideoPermissionDenied = (flag) =>
({
    type: 'SET_VIDEO_PERMISSION_DENIED',
    payload: { value: flag }
})