import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { englishLang } from './en/en';
import { hindiLang } from './hi/hi';
import { indonesiaLang } from './ind/ind';
import { VietnamLang } from './vn/vn';
import { arbaicLang } from './ar/ar';


import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: englishLang

            },
            hi: {
                translation: hindiLang
            },
            ind: {
                translation: indonesiaLang
            },
            vn: {
                translation: VietnamLang
            },
            ar: {
                translation: arbaicLang
            }
        }
    });


export default i18n;