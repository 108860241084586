import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { setUnreadMsgCount } from "../../../redux/actions/chatActions"
import { BOP } from "../../../constants/Enums"
import ChatAudio from "../../components/chatAudio.mp3"

export default function Chat({

}) {
    const dispatch = useDispatch()
    const { selectOption } = useSelector(state => state.videoroom)
    const { chatMessageArray, unreadMsgCount } = useSelector(state => state.chat)

    useEffect(() => {
        if (selectOption === BOP.CHAT) {
            dispatch(setUnreadMsgCount(0))
        }
    }, [selectOption])

    useEffect(() => {
        if (chatMessageArray.length > 0) {
            updateUnreadMsgCount()
        }
    }, [chatMessageArray.length])

    const updateUnreadMsgCount = () => {
        let msgCount = unreadMsgCount
        if (selectOption === BOP.CHAT) {
            msgCount = 0;
        }
        else {
            msgCount += 1;
            if (msgCount === 1) {
                const audio = new Audio(ChatAudio);
                audio.play();
            }
        }
        dispatch(setUnreadMsgCount(msgCount))
    }

    return null
}