import React from "react";
import history from './history';
import { Router, Switch, Route } from 'react-router-dom';
import axios from 'axios';
import ErrorPage from './errorPage/errorPage';
import Videoroom from './videoroom/videoroom';
// import Test from './test/test';

axios.defaults.withCredentials = true;

export default function App(props) {
    return (
        <Router history={history}>
            <Switch>
                <Route path="/videoroom/:roomId/:userId/:hotKey" exact component={Videoroom} />
                <Route path="/error" exact component={ErrorPage} />
                {/* <Route path = "/test" exact component={Test}/> */}
            </Switch>
        </Router>
    );
}