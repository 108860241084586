import axios from "axios";
import { api } from "../../constants/envVariables";
import { ERROR } from "../../constants/Enums";

const headers = {
    Accept: "application/json"
}

export const utilsGetRoomInfo = (classId, userId, authKey) => {
    return new Promise((resolve, reject)=>{
        axios({
            method: "GET",
            url: `${api}roominfo/${classId}/${userId}/${authKey}`,
            headers: headers
        })
        .then((response)=>{
            if(response && response.data && response.data.status === true){
                resolve({ data: response.data.obj });
            }
            else{
                reject({ errorMsg: ERROR.ROOMINFO })
            }
        })
        .catch(()=>{
            reject({ errorMsg: ERROR.ROOMINFO })
        })
    })
}

export const utilsUpdateRoomInfo = (roomInfo, classId, userId, authKey) => {
    return new Promise((resolve, reject)=>{
        axios({
            method: "PUT",
            url: `${api}roominfo/${classId}/${userId}/${authKey}`,
            headers: headers,
            data: roomInfo
        })
        .then((res)=>{
            if(res && res.data && res.data.status){
                resolve({ data: res.data.obj});
            }
            else{
                reject({ errorOccured: true })
            }
        })
        .catch((err)=>{
            reject({ errorOccured: true })
        })
    })
}

export const utilsGenerateSignedUrl = (classId, userId, authKey, fileName) => {
    const body = {
        filename: fileName
    }
    return new Promise((resolve, reject)=>{
        axios({
            method: "POST",
            url:  `${api}generate-signed-url/${classId}/${userId}/${authKey}`,
            headers: headers,
            data: body
        })
        .then((res)=>{
            if(res && res.data && res.data.status){
                resolve({data: res.data.obj});
            }
            else{
                reject({errorOccured: true});
            }
        })
        .catch((err)=>{
            reject({errorOccured: true});
        })
    })
}

export const utilsUploadFileToSignedUrl = (signedUrl, file) => {
    let fd = new FormData();
    fd.append("file", file);
    return new Promise((resolve, reject)=>{
        axios({
            method: "POST",
            url: signedUrl,
            headers: headers,
            withCredentials: false,
            data: fd
        })
        .then(()=>{
            resolve({ success: true })
        })
        .catch(()=>{
            reject({ errorOccured: true })
        })
    })
}

export const utilsUpdateAttachmentInfo = (classId, userId, authKey, fileName, path, sessionId) => {
    const body = {
        "filename": fileName,
        "filetype": "StudyMaterial",
        "filepath": path,
        "session_id": sessionId
    }
    return new Promise((resolve, reject)=>{
        axios({
            method: "POST",
            url: `${api}update-attachment-info/${classId}/${userId}/${authKey}`,
            headers: headers,
            data: body
        })
        .then((res)=>{
            if(res && res.data && res.data.status){
                resolve({ data: res.data.obj})
            }
            else{
                reject({ errorOccured: true })
            }
        })
        .catch(()=>{
            reject({ errorOccured: true })
        })
    })
}

export const utilsCreateFeedback = (classId, userId, authKey, feedback) => {
    const body = {
        text: feedback,
        ref: "web"
    }
    return new Promise((resolve, reject)=>{
        axios({
            method: "POST",
            url: `${api}feedback/${classId}/${userId}/${authKey}`,
            headers: headers,
            data: body
        })
        .then((res)=>{
            if(res && res.data && res.data.status){
                resolve({ data: res.data.obj })
            }
            else{
                reject({ errorOccured: true })
            }
        })
        .catch((err)=>{
            console.log(err);
        })
    })
}

export const getConfigDetails = (classId, userId, authKey) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: `${api}event-config/${classId}>/${userId}/${authKey}`,
            headers: headers,
        })
            .then((response) => {
                if (response && response.data && response.data.status) {
                    resolve(response.data.obj);
                }
                else{
                    reject({ errorOccured: true })
                }
            })
            .catch((error) => {
                reject({ errorOccured: true });
            });
    });
};

export const log_event = (classId, userId, authKey, eventID, data) => {
    return new Promise((resolve, reject) => {
        const body = {
            event_id: eventID,
            data: data
        }

        axios({
            method: "POST",
            url: `${api}log-event/${classId}/${userId}/${authKey}`,
            headers: {
                Accept: "application/json",
            },
            data: body
        })
            .then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.status === true
                ) {
                    resolve({ response });
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
};