export const updateStreamingStatus = (flag) =>
({
    type: "UPDATE_STREAMING_STATUS",
    payload: { value: flag }
})

export const updateIsGoogleSignedIn = (flag) =>
({
    type: "UPDATE_IS_GOOGLE_SIGNED_IN",
    payload: { value: flag }
})

export const updateBasicProfile = (update) =>
({
    type: "UPDATE_BASIC_PROFILE",
    payload: update
})

export const updateCurrStreamOption = (value) =>
({
    type: "UPDATE_CURRENT_STREAMING_OPTION",
    payload: { value: value }
})

export const updateBulkStreaming = (updates) =>
({
    type: "BULK_UPDATE_STREAMING",
    payload: updates
})

export const updateIsLiveStreamingDisabled = (flag) =>
({
    type: "UPDATE_LIVE_STREAMING_DISABLED",
    payload: { value: flag }
})

export const updateYoutubeIntegrationStatus = (value) =>
({
    type: "UPDATE_YOUTUBE_INTEGRATION_STATUS",
    payload: { value: value }
})

export const updateYoutubeBroadcastId = (value) =>
({
    type: "UPDATE_YOUTUBE_BROADCAST_ID",
    payload: { value: value }
})

export const resetAllStreamingStates = () =>
({
    type: "RESET_ALL_STREAMING_STATES"
})