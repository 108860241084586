import { VIDEO_OPTIONS, QUALITY } from "../../constants/Enums";

const initialState = {
    camera: false,
    videoDevices: [],
    currVideoDevice: {},
    currVideoQuality: VIDEO_OPTIONS[QUALITY.HIGH],
    isUserFacingCam: false,
    stopAllVideos: false,
    videoPermissionDenied: false
}

export function videoReducer(state = initialState, { type, payload }) {
    let newState;
    switch (type) {
        case "SET_CAMERA_STATUS":
            newState = { ...state, camera: payload.value }
            return newState
        case "SET_VIDEO_DEVICES":
            newState = { ...state, videoDevices: payload }
            return newState
        case "SET_CURRENT_VIDEO_DEVICE":
            newState = { ...state, currVideoDevice: payload }
            return newState
        case "SET_CURRENT_VIDEO_QUALITY":
            newState = { ...state, currVideoQuality: payload }
            return newState
        case "SET_IS_USER_FACING_CAMERA":
            newState = { ...state, isUserFacingCam: payload.value }
            return newState
        case "SET_STOP_ALL_VIDEOS":
            newState = { ...state, stopAllVideos: payload.value }
            return newState
        case "SET_VIDEO_PERMISSION_DENIED":
            newState = { ...state, videoPermissionDenied: payload.value }
            return newState
        default:
            return state
    }
}