import { ROOM_STATE, STATUS, STREAM, NEW_TOAST_TYPES } from "../../constants/Enums";
import i18n from "../../i18n/i18n";
import { getName } from "../utils/userUtils";
import store from "../../redux/store";
import { updateSetting } from "../../redux/actions/videoroomActions"
import { creatToast } from "../containers/Toast/toastCreator"
import { addToast } from "../../redux/actions/toasts"
import { isPresenter } from "../utils/userUtils"
import { resetAllStreamingStates, updateBulkStreaming } from "../../redux/actions/streamingAction";
import { closeAllStreamingModals } from "../../redux/actions/modals";

export default class Room {
    constructor(roomState, utype, publisher, videoroom) {
        this.roomState = roomState
        this.utype = utype
        this.publisher = publisher
        this.videoroom = videoroom
        this.presenterId = null;
    }

    setRoomState = (data, from) => {
        const v = this.videoroom;
        const user = v.props.users[from];
        const roomState = data;

        let diff = roomState ^ v.props.videoroom.roomState;
        if (diff)

            if (diff & (1 << ROOM_STATE.RECORDING)) {
                let bitVal = (roomState >> ROOM_STATE.RECORDING) & 1;
                if (user) {
                    store.dispatch(addToast(creatToast(NEW_TOAST_TYPES.GENERIC_TOAST, `${getName(user)} ${bitVal ? i18n.t("hasStartedRecording") : i18n.t("hasPausedRecording")}`)))
                }
                if (isPresenter(this.publisher)) { //if user is presenter and recording is on/off, set the presenter's recording bit on/off
                    let control = v.state.control
                    control = bitVal ? ((1 << STATUS.RECORDING) | control)
                        : (control & ~(1 << STATUS.RECORDING));
                    v.sendStatus(control);
                }
                store.dispatch(updateSetting({
                    setting: "recording",
                    value: Boolean(bitVal)
                }))
            }

        if (diff & (1 << ROOM_STATE.YOUTUBE)) {
            let bitVal = (roomState >> ROOM_STATE.YOUTUBE) & 1;
            if (user) {
                store.dispatch(addToast(creatToast(NEW_TOAST_TYPES.GENERIC_TOAST, `${getName(user)} ${bitVal ? i18n.t("hasStartedStreaming") : i18n.t("hasStoppedStreaming")}`)))
            }
            if (!bitVal) {//if streaming is stopped/failed clear the modal states
                store.dispatch(resetAllStreamingStates())
                store.dispatch(closeAllStreamingModals())
            }
            if (isPresenter(this.publisher)) {  //if user is presenter and youtube streaming is on/off, set the presenter's streaming bit on/off
                let control = v.state.control
                control = bitVal ? ((1 << STATUS.STREAMING) | control)
                    : (control & ~(1 << STATUS.STREAMING));
                v.sendStatus(control);
            }
            store.dispatch(updateBulkStreaming({
                currStreamOption: bitVal ? STREAM.YOUTUBE : null,
                isStreaming: Boolean(bitVal)
            }))
        }

        if (diff & (1 << ROOM_STATE.STREAMING)) {
            let bitVal = (roomState >> ROOM_STATE.STREAMING) & 1;
            if (user) {
                store.dispatch(addToast(creatToast(NEW_TOAST_TYPES.GENERIC_TOAST, `${getName(user)} ${bitVal ? i18n.t("hasStartedStreaming") : i18n.t("hasStoppedStreaming")}`)))
            }
            if (!bitVal) {//if streaming is stopped/failed clear the modal states
                store.dispatch(resetAllStreamingStates())
                store.dispatch(closeAllStreamingModals())
            }
            if (isPresenter(this.publisher)) { //if user is presenter and custom streaming is on/off, set the presenter's streaming bit on/off
                let control = v.state.control
                control = bitVal ? ((1 << STATUS.STREAMING) | control)
                    : (control & ~(1 << STATUS.STREAMING));
                v.sendStatus(control);
            }
            store.dispatch(updateBulkStreaming({
                currStreamOption: bitVal ? STREAM.CUSTOM : null,
                isStreaming: Boolean(bitVal)
            }))
        }
        this.roomState = roomState;
    }
}