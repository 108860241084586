let api = "";
//hide console statements
if (process.env.NODE_ENV !== "development") console.log = () => { };

const { REACT_APP_API_URL, REACT_APP_TEACHMINT_WEBSITE } = process.env;
api = REACT_APP_API_URL;
const teachmintWebsite = REACT_APP_TEACHMINT_WEBSITE || '/';
// api = "https://video-api-uat.teachmint.com/"

export { api, teachmintWebsite };

