import { v4 as uuidv4 } from "uuid";
import { log_event } from "../requests/Request";

export default class EventManager{
    constructor(classId, uid, authKey){
        this.classId = classId;
        this.uid = uid;
        this.authKey = authKey
        this.eventConfig = null;
    }

    getAppID = () => {
        let appId = "";
        try{
            appId = window.localStorage.getItem("app_id");
            if (!appId) {
                appId = uuidv4();
                window.localStorage.setItem("app_id", appId);
            }
        }
        catch{}
        return appId;
    };

    get_default_data = () => {
        return {
            t: new Date().getTime(),
            uid: this.uid,
            app_id: this.getAppID(),
            app_type: "WEB",
        };
    };

    send_event = async (eventID, data) => {
        try{
            await log_event(this.classId, this.uid, this.authKey, eventID, data);
        }
        catch{}
    };

    send_live_class_ongoing = (class_id) => {
        this.send_event(
            "LIVE_CLASS_ONGOING",
            Object.assign(this.get_default_data(), { class_id: class_id }),
            false
        );
    };

    live_class_started = (class_id) => {
        this.send_event(
            "LIVE_CLASS_STARTED",
            Object.assign(this.get_default_data(), { class_id: class_id }),
            false
        );
    };

    live_class_started_with_student = (class_id) => {
        this.send_event(
            "LIVE_CLASS_STARTED_WITH_STUDENT_WEB",
            Object.assign(this.get_default_data(), { class_id: class_id })
        );
    };

    live_class_aggregated_data_duration = (eventData) => {
        this.send_event(
            "LIVE_CLASS_AGGREGATED_DATA_DURATION",
            Object.assign(this.get_default_data(), eventData)
        );
    };

    live_class_aggregated_data_clicks = (clickData) => {
        this.send_event(
            "LIVE_CLASS_AGGREGATED_DATA_CLICKS",
            Object.assign(this.get_default_data(), clickData)
        );
    };

    network_unstable = (data)=>{
        this.send_event(
            "NETWORK_UNSTABLE",data
        )
    }
}