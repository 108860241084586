import React, { useEffect } from "react";
import store from "../../../redux/store";
import { updateSetting, bulkUpdateSettings } from "../../../redux/actions/videoroomActions";
import { useDispatch, useSelector } from "react-redux";
import { RECORD_LOG, ROOM_STATE, STATUS, ROOM_META } from "../../../constants/Enums";
import { getTimestamp } from "../../utils/Utils";
import WSConnection from "../../connections/WSConnection";
import { updatedSelectedPresenterID } from "../../../redux/actions/presenter";
import { updateUser } from "../../../redux/actions/usersActions";

export default function Recording({ sendStatus, eventClicks, control }) {
    const wsConnection = new WSConnection();
    const dispatch = useDispatch();
    const {
        recordingActivity,
        roomState,
        recording,
        PCCState,
        userId,
        updateRecordingCounter,
        autoStartRecording,
    } = useSelector((state) => state.videoroom);
    const { canSetRecordingStatus } = useSelector((state) => state.permissions);
    const { presenterUid } = useSelector((state) => state.presenter);
    const users = useSelector((state) => state.users);
    const currUser = users[userId];

    const updateStartActivity = () => {
        let lastItem = recordingActivity[recordingActivity.length - 1];

        if (!lastItem || lastItem[RECORD_LOG.STOP_TIME]) {
            const timestamp = getTimestamp() + wsConnection.timestampMargin;
            let newRecordingActivity = [...recordingActivity];
            newRecordingActivity.push({
                [RECORD_LOG.START_TIME]: timestamp,
            });
            dispatch(
                updateSetting({
                    setting: "recordingActivity",
                    value: newRecordingActivity,
                })
            );
            wsConnection.sendRoomMeta(ROOM_META.RECORD_LOG, newRecordingActivity);
        }
    };

    const updateStopActivity = () => {
        let lastItem = recordingActivity[recordingActivity.length - 1];

        if (lastItem && !lastItem[RECORD_LOG?.STOP_TIME]) {
            const timestamp = getTimestamp() + wsConnection.timestampMargin;
            lastItem[RECORD_LOG.STOP_TIME] = timestamp;
            lastItem[RECORD_LOG.DURATION] =
                lastItem[RECORD_LOG.STOP_TIME] - lastItem[RECORD_LOG.START_TIME];
        }

        let newRecordingActivity = [...recordingActivity];
        newRecordingActivity.pop();
        newRecordingActivity.push(lastItem);
        dispatch(
            updateSetting({
                setting: "recordingActivity",
                value: newRecordingActivity,
            })
        );
        wsConnection.sendRoomMeta(ROOM_META.RECORD_LOG, newRecordingActivity);
    };

    const handleStartRecording = () => {
        let newRoomState = (1 << ROOM_STATE.RECORDING) | roomState;
        if (!presenterUid) {
            dispatch(updatedSelectedPresenterID(userId));
        } else if (presenterUid === userId) {
            //if user is presenter, set recording bit to true
            let newControl = control;
            newControl = (1 << STATUS.RECORDING) | newControl;
            sendStatus(newControl);
        }
        eventClicks.recording_on.push(new Date().getTime());
        dispatch(bulkUpdateSettings({ recording: true, roomState: newRoomState }));
        wsConnection.sendRoomMeta(ROOM_META.ROOM_STATE, newRoomState);
        updateStartActivity();
    };

    const handleStopRecording = () => {
        let newRoomState = roomState & ~(1 << ROOM_STATE.RECORDING);

        if (presenterUid === userId) {
            let newControl = control;
            newControl = newControl & ~(1 << STATUS.RECORDING);
            sendStatus(newControl);
        }
        eventClicks.recording_off.push(new Date().getTime());
        dispatch(bulkUpdateSettings({ recording: false, roomState: newRoomState }));
        wsConnection.sendRoomMeta(ROOM_META.ROOM_STATE, newRoomState);
        updateStopActivity();
    };

    useEffect(() => {
        if (PCCState === "connected") {
            const isRecording = (roomState >> ROOM_STATE.RECORDING) & 1;
            if (canSetRecordingStatus && !isRecording && recording) {
                dispatch(
                    updateSetting({
                        setting: "autoStartRecording",
                        value: true,
                    })
                );
                //if nobody is recording but recording state is ON
                dispatch(
                    updateUser({
                        uid: userId,
                        prop: "isRecording",
                        value: recording,
                    })
                );
            }
        }
    }, [PCCState]);

    useEffect(
        () => {
            if ((updateRecordingCounter || autoStartRecording) && PCCState) {
                let { recording } = store.getState().videoroom;
                if (autoStartRecording) {
                    handleStartRecording();
                    dispatch(
                        updateSetting({
                            setting: "autoStartRecording",
                            value: false,
                        })
                    );
                } else {
                    !recording ? handleStartRecording() : handleStopRecording();
                }
            }
        },
        // [currUser && currUser.isRecording]
        [updateRecordingCounter, autoStartRecording]
    );

    return null;
}
