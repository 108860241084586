export const VietnamLang = {
    teacher: 'Giáo Viên',
    student: 'Học Sinh',
    dashboardSubheaderTextChat: 'Trò Chuyện',
    subjectTeacherDashboardCapitalText: 'MÔN HỌC',
    goLiveText: 'Tham Gia Trực Tiếp',
    classroomIdTeacherDashboardCapitalText: 'ID LỚP HỌC',
    cameraText: 'Máy Ảnh',
    microphoneText: 'Micro',
    onlyEnrollStudentCanJoinText: 'Lớp học của bạn an toàn. Chỉ các học sinh đã ghi danh có thể tham gia lớp học trực tiếp.',
    goLiveMicrophoneOnText: 'Micro bật',
    goLiveMicrophoneOffText: 'Micro tắt',
    goLiveMicOnText: 'Mic bật',
    goLiveMicOffText: 'Mic tắt',
    goLiveVideoOnText: 'Video bật',
    goLiveVideoOffText: 'Video tắt',
    goLiveRecordingOnText: 'Chế độ ghi hình bật',
    goLiveRecordingOffText: 'Chế độ ghi hình tắt',
    goLiveClassRecordingOnText: 'Ghi Hình Lớp Học bật',
    goLiveClassRecordingOffText: 'Ghi Hình Lớp Học tắt',
    goLiveMicButtonText: 'Mic',
    goLiveVideoButtonText: 'Video',
    goLiveStartRecordingButtonText: 'Bắt đầu ghi',
    goLiveStopRecordingButtonText: 'Dừng ghi',
    goLivePauseRecordingButtonText: 'Tạm dừng ghi',
    goLiveShareFilesButtonText: 'Chia Sẻ Tập Tin',
    goLiveStudentControlsButtonText: 'Kiểm Soát Học Sinh',
    goLiveMoreButtonText: 'Thêm',
    goLivePeopleButtonText: 'Mọi Người',
    goLiveOpenWhiteboardText: 'Mở Bảng Trắng',
    goLiveShareMyScreenText: 'Chia Sẻ Màn Hình Của Tôi',
    goLiveAllowStudentToChatText: 'Cho phép học sinh trò chuyện',
    goLiveDisabledStudentToChatText: 'Giáo viên đã đóng Trò Chuyện',
    goLiveEmptyChatText: 'Chưa có tin nhắn nào ở đây…',
    goLiveEmptyChatSubText: 'Làm quen với phần trò chuyện trong Lớp Học Trực Tiếp',
    goLiveInteractWithStudentsText: 'Tương tác với học sinh của bạn',
    goLiveInteractWithClassmatesText: 'Tương tác với bạn học của bạn',
    goLivePollsButtonText: 'Các khảo sát ý kiến',
    goLiveInstantPollText: 'Bắt đầu một khảo sát ý kiến tức thì',
    goLivePollsLiveButtonText: 'Khởi Động',
    goLiveCreateNewPollText: 'Tạo một khảo sát ý kiến mới',
    goLivePollsEmptyText: 'Tăng các tương tác trong lớp học của bạn bằng cách tạo các khảo sát ý kiến',
    goLiveEndClassButtonText: 'Dừng Lớp Học cho tất cả',
    goLiveEndClassButtonSubText: 'Dừng lớp học cho tất cả người tham gia?',
    goLiveClassroomEndedButtonText: 'Lớp học đã kết thúc',
    linkButton: 'Sao Chép Liên Kết',
    inviteStudentsButton: 'Mời học sinh',
    createTestFormAlphabetA: 'A',
    createTestFormAlphabetB: 'B',
    createTestFormAlphabetC: 'C',
    createTestFormAlphabetD: 'D',
    createTestFormAlphabetE: 'E',
    backButtonText: 'Quay Lại',
    createFormHeaderDeleteButton: 'Xóa',
    closeButtonText: 'Đóng',
    createFormDescreptionText: 'Mô Tả',
    createHomeworkContinueButtonText: 'Tiếp Tục',
    teacherDashboardStudyMaterialSearchPlaceholder: 'Tìm Kiếm',
    teacherDashboardStudyMaterialSortByNameText: 'Tên',
    teacherDashboardStudyMaterialSortByDateAdded: 'Ngày Thêm',
    teacherDashboardStudyMaterialSortByText: 'Lọc Theo',
    teacherDashboardUploadFileFormDragDropText: 'Kéo và thả một tệp, hoặc Duyệt',
    teacherDashboardUploadFileFormDescreptionText: 'Mô tả',
    saveButtonText: 'Lưu',
    teacherDashboardNoticeBoardFormAddAttachmentText: 'Thêm Tệp Đính Kèm*',
    teacherDashboardNoticeBoardFormChooseFileButtonText: 'Chọn tệp',
    teacherDashboardNoticeBoardFormChooseFileButtonPlaceholderText: 'Không có tệp nào Được Chọn',
    createClassroomFormClassroomNameText: 'Tên Lớp Học',
    fromText: 'Từ',
    toText: 'Dến',
    createClassroomCancelButtonText: 'Hủy bỏ',
    profileAboutYouText: 'Về Bạn',
    aboutYouAddButtonText: 'Thêm',
    logoutText: 'Đăng xuất',
    feedbackText: 'Phản hồi',
    feedbackLabel: 'Hãy cho chúng tôi biết nếu bạn đang gặp phải bất kỳ vấn đề nào. Chúng tôi sẽ liên hệ lại với bạn.',
    required: 'Bắt buộc',
    sendFeedbackButton: 'Gửi phản hồi',
    joinLiveClassText: 'Tham Gia Lớp Học Trực Tiếp',
    goLiveButtonStudentDashboardText: 'Âm thanh và video của bạn sẽ tắt khi bạn tham gia lớp học',
    goLiveStudentDashboardEnterTheClassroomButtonText: 'Vào Lớp Học',
    goLiveStudentDashboardExitButtonText: 'Lối ra',
    goLiveStudentDashboardWaitingForTeacherText: 'Đang Đợi Giáo Viên',
    unblockUser: 'Bỏ chặn',
    blockUser: 'Chặn',
    emptyStudyMaterialText: 'Chưa có Tài Liệu học tập nào được thêm vào',
    createNoticeBoardCreateButtonText: 'Tạo',
    dashboardChatStartConversationText: 'Bắt Đầu Cuộc Trò Chuyện',
    dashboardChatSendGroupMessageText: 'Gửi tin nhắn vào trong nhóm',
    dashboardChatDisabledChatByTeacherText: 'Phần Trò Chuyện đã được tắt bởi giáo viên',
    dashboardStudyMaterialAddNewText: 'Thêm Mới',
    testDurationMinsText: 'phút',
    createDraftText: 'Bản Nháp',
    dashboardStudentJoinRequestText: 'Yêu Cầu Tham Gia của Học Sinh',
    dashboardEnrolledStudentsText: 'Học Sinh Đã Ghi Danh',
    dashboardAcceptText: 'Chấp Nhận',
    dashboardRejectText: 'Từ Chối',
    dashboardSearchNamePhoneText: 'Tìm Kiếm Tên hoặc Số Điện Thoại',
    dashboardSearchNameText: 'Tìm Kiếm Tên',
    goLiveStudentDashboardNotEnrolledText: 'Bạn chưa được ghi danh vào lớp học. Vui lòng liên hệ với giáo viên để lấy số ID lớp học',
    goLiveStudentDashboardHandRaiseText: 'Giơ Tay',
    goLiveStudentDashboardHandLowerText: 'Hạ Tay',
    goLiveStudentDashboardAudioOnlyText: 'Chỉ có âm thanh',
    goLiveStudentDashboardReportAbuseText: 'Báo Cáo Lạm Dụng',
    goLiveStudentDashboardSubmitFeedbackText: 'Gửi Phản Hồi',
    dashboardStudentDeletePopUpText: 'Bạn có chắc muốn gỡ Học Sinh này?',
    dashboardStudentsCopyClipboard: 'Đã sao chép lên bảng ghim',
    goLiveTeacherDashboardAllowStudentToSpeakText: 'Cho phép học sinh nói',
    goLiveTeacherDashboardAllowStudentVideoText: 'Cho phép học sinh bật hình ảnh',
    goLiveTeacherDashboardStreamLiveOnYoutubeText: 'Phát trực tuyến trên YouTube',
    goLiveTeacherDashboardStreamLiveCustomText: 'Phát trực tuyến tùy chỉnh trên các nền tảng khác',
    goLiveTeacherDashboardCloseWhiteboardText: 'Đóng Bảng Trắng',
    goLiveTeacherDashboardExitWhiteboardText: 'Thoát Bảng Trắng',
    goLiveTeacherDashboardExitWhiteBoardExitContentText: 'Bạn có muốn lưu bảng trắng này trước khi thoát không?',
    goLiveTeacherDashboardExitWhiteBoardCloseText: 'Vẫn Đóng',
    goLiveTeacherDashboardWhiteBoardNoHandRaisedText: 'Chưa có ai giơ tay',
    goLiveTeacherDashboardWhiteBoardSaveAndUploadedCloseText: 'Đã lưu bảng trắng & cập nhật trong tài liệu học tập',
    goLiveTeacherDashboardWhiteBoardLowerAllHandsText: 'Hạ tất cả tay',
    goLiveTeacherDashboardWaitingRoomText: 'Phòng Chờ',
    goLiveTeacherDashboardMuteAllText: 'Tắt tiếng tất cả',
    goLiveTeacherDashboardYoutubeStreamingLiveText: 'Phát Trực Tuyến Trực Tiếp Trên YouTube',
    goLiveTeacherDashboardScreenSharingText: 'Bạn đang trình chiếu màn hình của mình cho mọi người',
    goLiveTeacherDashboardStopPresentingText: 'Dừng Trình Chiếu',
    goLiveTeacherDashboardClassroomSafeText: 'Lớp học của bạn an toàn và bảo mật',
    goLiveTeacherDashboardLeaveClassText: 'Rời Khỏi Lớp Học',
    goLiveTeacherDashboardLeaveTheClassLetOthersContinueText: 'Chỉ có bạn muốn rời khỏi lớp học và để mọi người tiếp tục?',
    enrolledStudentsTextClassroomIDShared: 'Số ID Lớp Học Ghi Danh được giáo viên của bạn chia sẻ để ghi danh vào một lớp học',
    enrolledStudentsTextEnroll: 'Ghi Danh',
    enrolledStudentsTextInviteTeacher: 'Mời Giáo Viên',
    enrolledStudentsTextClassroomInvite: 'Lớp Học Teachmint chỉ được mời, tức là chỉ giáo viên mới có thể thiết lập và mời các học sinh vào ID lớp học của họ',
    enrolledStudentsTextAsk: 'Nếu giáo viên của bạn đang dạy trên Teachmint, hãy yêu cầu họ cung cấp ID Lớp Học',
    enrolledStudentsTextClassroomYet: 'Nếu giáo viên của bạn chưa tạo Lớp Học, hãy chia sẻ ứng dụng này',
    enrolledStudentsTextClassroomId: 'Bạn không có số id lớp học?',
    enrolledStudentsTextNote: 'Xin Lưu Ý:',
    chatTextChatSettings: 'Cài Đặt Trò Chuyện',
    chatTextChatDisabled: 'Phần Trò Chuyện đã bị tắt bởi',
    chatTextChatNotBeAble: 'sẽ không thể gửi tin nhắn',
    chatTextEnableTeacher: 'Bật chế độ chỉ dành cho giáo viên trong phần trò chuyện lớp học',
    chatTextNotSendMessages: 'Các em học sinh sẽ không thể gửi tin nhắn',
    chatTextEnableChat: 'Bật phần trò chuyện học sinh với học sinh',
    chatTextStudentChat: 'Các em học sinh có thể trò chuyện với nhau',
    chatTextDisableChat: 'Tắt phần trò chuyện lớp học',
    chatTextNoOneSend: 'Sẽ không ai có thể gửi được tin nhắn',
    hourText: 'giờ',
    minuteText: 'phút',
    secondText: 'giây',
    hideText: 'Ẩn',
    offlineText: 'Ngoại tuyến',
    somethingWentWrongText: 'Có gì đó không ổn',
    deletePageTextWithoutSaving: 'Thoát mà không tiết kiệm',
    allChangesLostText: 'Bạn có chắc không? Tất cả các thay đổi sẽ bị mất.',
    unsavedChangesText: 'Thay đổi chưa được lưu',
    noButtonText: 'không',
    yesButtonText: 'Vâng',
    liveClassSettingsText: 'Cài đặt lớp trực tiếp',
    classroomSettingsText: 'Cài đặt lớp học',
    allowStudentsChatText: 'Cho phép sinh viên trò chuyện',
    liveClassSettingsTextDesc: 'Học sinh của bạn có thể bật mic của họ và nói',
    allowStudentsChatTextDesc: 'Học sinh của bạn có thể trò chuyện với nhau trong lớp học trực tiếp',
    allowStudentsVideoText: 'Cho phép sinh viên video',
    allowStudentsVideoTextDesc: 'Học sinh của bạn có thể bật video của họ',
    scoreText: 'Ghi bàn',
    rankText: 'Cấp',
    yourclassEndText: 'Lớp học của bạn đã kết thúc',
    doneText: 'Xong',
    SkipForNowText: 'Bỏ qua bây giờ',
    playingPictureAndPictureModeText: 'Phát ở chế độ hình trong hình',
    stoppingPictureAndPictureModeText: 'Dừng chụp ảnh trong ảnh',
    qualityOfVideoText: 'Phẩm chất',
    clearAllText: 'Làm sạch tất cả',
    clearAllAnnotationToolsText: 'Bạn có chắc chắn muốn xóa tất cả?',
    answeredSmallText: 'Trả Lời',
    stopInstantPollText: 'Dừng bỏ phiếu ngay lập tức',
    viewPollAfterpollEnds: ' Xem phân tích bình chọn ở đây sau khi cuộc thăm dò kết thúc',
    screenPollStudentsText: 'Màn hình chia sẻ sẽ trở thành một cuộc thăm dò và các sinh viên có thể chọn một tùy chọn duy nhất từ ​​A, B, C & D',
    selectPollDurationText: 'Chọn cuộc thăm dò thời lượng.',
    insecondsText: 'trong vài giây',
    markCorrectAnswerText: 'Đánh dấu câu trả lời đúng',
    stopPollText: 'Dừng bỏ phiếu',
    pickedCorrectAnswerText: 'Ah! Không ai chọn câu trả lời đúng',
    leaderboardText: 'Bảng xếp hạng',
    connectedAnotherDeviceText: 'Kết nối với một thiết bị khác',
    continueCurrentSession: 'Vyou đã tham gia lớp học từ một hệ thống hoặc trình duyệt khác. Bạn có muốn tiếp tục phiên hiện tại?',
    noOnePickedText: 'Không ai chọn',
    instantPollCompletedText: 'Cuộc thăm dò ngay lập tức hoàn thành',
    pollQuestionOptionsText: 'Nhập câu hỏi và tùy chọn thăm dò ý kiến',
    createPollSettings: 'Tạo cài đặt thăm dò ý kiến',
    youSureStopText: 'Bạn có chắc chắn muốn dừng',
    instantText: 'instan.',
    pollText: 'Thăm dò ý kiến?',
    stopInstantText: 'Dừng instan.',
    pollDurationToLaunch: 'Chọn thời lượng thăm dò ý kiến ​​để khởi chạy',
    correctAnswerToLaunch: 'Đánh dấu câu trả lời đúng để khởi chạy',
    pollBeingLaunchedText: 'Cuộc thăm dò ngay lập tức đang được ra mắt',
    pollIsBeingLaunched: 'Thăm dò ý kiến ​​đang được đưa ra',
    teacherLaunchingPollText: 'Giáo viên đang phát động một cuộc thăm dò',
    pollAnswerFromHere: 'Chọn cuộc thăm dò câu trả lời từ đây',
    pollStartsText: 'Thăm dò ý kiến ​​bắt đầu vào',
    studentDisabledLivePollText: 'Học sinh bị tắt tiếng và trò chuyện bị vô hiệu hóa trong cuộc thăm dò trực tiếp',
    enterPollQuestionText: 'Nhập câu hỏi thăm dò ý kiến',
    enterPollQuestionContinueText: 'Vui lòng nhập câu hỏi thăm dò ý kiến ​​để tiếp tục',
    enterPollOptionsText: 'Nhập tùy chọn thăm dò ý kiến',
    enterLeast2OptionsContinue: 'Nhập ít nhất 2 tùy chọn để tiếp tục',
    enterAllOptionsContinue: 'Vui lòng nhập tất cả các tùy chọn để tiếp tục',
    addAnotherOptionText: '+ Thêm một tùy chọn khác',
    durationOfPollText: 'Thời lượng của cuộc thăm dò (tính bằng giây)',
    selectPollDurationContinueText: 'Chọn Thời lượng thăm dò để tiếp tục',
    markCorrectAnswerContinueText: 'Đánh dấu câu trả lời đúng để tiếp tục',
    launchPollText: 'Khởi chạy Poll.',
    youtubeText: 'YouTube.',
    streamCustomLiveText: 'Suối tùy chỉnh trực tiếp',
    enterURLText: 'Nhập URL',
    liveStreamKeyText: 'Phím trực tiếp',
    enterText: 'đi vào',
    enterLiveStreamKeyText: 'Nhập phím luồng trực tiếp',
    startYouTubeLiveStreamText: 'Bắt đầu luồng trực tiếp YouTube',
    startLiveStream: 'Bắt đầu phát trực tiếp',
    youtubeStreamKey: 'Làm cách nào để tìm phím luồng YouTube?',
    youTubeTermsConditionsText: 'Điều khoản và điều kiện của YouTube',
    signInWithGoogleText: 'Đăng nhập bằng Google',
    enterYouTubeLiveStreamKeyText: 'Nhập phím luồng trực tiếp YouTube',
    stopLiveStreaming: 'Bạn có chắc chắn muốn dừng phát trực tiếp?',
    stopStreamingText: 'Dừng phát trực tuyến.',
    stopText: 'Ngừng lại',
    disabledAudioOnlyModeText: 'Tay tăng tay tàn tật trong cuộc thăm dò',
    sharingDisableAudioOnlyModeText: 'Giáo viên đang chia sẻ màn hình. Vui lòng tắt chế độ chỉ âm thanh.',
    enabledAudioOnlyModeText: 'Bạn đã kích hoạt chế độ chỉ âm thanh',
    submittedYourResponseText: 'Tùy chọn <1> </1> được gửi dưới dạng phản hồi của bạn',
    theCorrectAnswerText: 'Đáp án đúng là',
    youDidnotAnswerText: 'Bạn không trả lời!',
    youAnsweredText: 'Bạn trả lời',
    fileNameUpdatedText: 'Đã cập nhật tên tệp!',
    handRaiseDuringPollText: 'Tay tăng tay tàn tật trong cuộc thăm dò',
    pollCompletedText: 'Thăm dò ý kiến',
    mutedTeacherText: 'Bạn đã bị tắt tiếng bởi giáo viên',
    audioHasBeenDisabledText: 'Âm thanh đã bị vô hiệu hóa',
    videoHasBeenDisabledText: 'Video đã bị vô hiệu hóa',
    chatHasBeenDisabledText: 'Trò chuyện đã bị vô hiệu hóa',
    youText: 'Bạn',
    classroomInfoNotFetch: 'Không thể tìm kiếm thông tin lớp học',
    couldNotConnectServer: 'Không thể kết nối tới máy chủ',
    checkNetworkAndTry: 'Vui lòng kiểm tra kết nối mạng và thử lại',
    rejoin: 'Tham Gia Lại',
    roomId: 'Id Phòng Học',
    userId: 'Id Người Dùng',
    hotkey: 'Phím Tắt',
    submit: 'Nộp',
    gotoErr: 'Đi đến err',
    classStatus: 'Tình trạng lớp học',
    enableAudioPermissions: 'Bật Quyền Cho Phép Âm Thanh',
    switchOffMic: 'Tắt Micro',
    switchOnMic: 'Bật Micro',
    enableVideoPermissions: 'Bật Quyền Cho Phép Video',
    switchOffCamera: 'Tắt Máy Ảnh',
    switchOnCamera: 'Bật Máy Ảnh',
    HD: 'HD',
    High: 'Cao',
    Low: 'Thấp',
    hasStartedRecording: 'đã bắt đầu ghi hình',
    hasPausedRecording: 'đã dừng ghi hình',
    hasStartedStreaming: 'đã dừng việc truyền phát',
    hasStoppedStreaming: 'đã dừng việc truyền phát',
    shareWhiteboard: 'Chia sẻ bảng trắng',
    allowStudentMic: 'Cho Phép Micro của Học Sinh',
    allowStudentCamera: 'Cho Phép Máy Ảnh của Học Sinh',
    classPreference: 'TÙY CHỌN LỚP HỌC',
    feedbackSuggestions: 'PHẢN HỒI & GỢI Ý',
    systemInformation: 'THÔNG TIN HỆ THỐNG',
    networkDeviceStats: 'Trạng Thái Mạng & Thiết Bị',
    audioOptions: 'TÙY CHỌN ÂM THANH',
    audioOutput: 'Đầu Ra Âm Thanh',
    connected: 'đã kết nối',
    noAudioAvailable: 'Không Có Âm Thanh',
    videoOptions: 'TÙY CHỌN VIDEO',
    noCameraAvailable: 'Không Có Máy Ảnh',
    videoQuality: 'Chất Lượng Video',
    liveVideoStreaming: 'TRUYỀN PHÁT VIDEO TRỰC TIẾP',
    stopYoutubeStream: 'Dừng truyền phát Youtube',
    stopSharing: 'Bắt Đầu Chia Sẻ',
    stopSharingScreen: 'Dừng Chia Sẻ Màn Hình',
    stopSharingWhiteboard: 'Dừng Chia Sẻ Bảng Trắng',
    stopSharingFile: 'Dừng Chia Sẻ Tập Tin',
    takePresenterRole: 'Nhận Vai Trò Người Thuyết Trình',
    takeOverPresenterRole: '<1> Tên </1>  hiện đang nhận vai trò người thuyết trình. Bạn có muốn nhận thay vai trò không?',
    openNewWhiteboard: 'Mở một bảng trắng mới',
    resumePreviousWhiteboard: 'Quay về bảng trắng trước đó',
    selectFile: 'Chọn một Tệp',
    openNewFile: 'Mở một tệp mới',
    resumePreviousFile: 'Quay lại tệp trước đó',
    saveExit: 'Lưu & Thoát',
    micBlockedByTeacher: 'Micro bị chặn bởi giáo viên',
    videoBlockedByTeacher: 'Video bị chặn bởi giáo viên',
    raiseHand: 'Giơ Tay',
    showMoreOptions: 'Hiển Thị Nhiều Lựa Chọn Hơn',
    backToVideoScreen: 'Chạm vào đây để quay lại màn hình video',
    feedbackSubmittedSuccessfully: 'Đã nộp phản hồi thành công',
    disableMic: 'Tắt micro',
    allowToSpeak: 'Cho phép nói',
    mute: 'Tắt Tiếng',
    allowMicUsage: 'Cho Phép sử dụng micro',
    allowCameraUsage: 'Cho Phép Sử Dụng Camera',
    allowChatUsage: 'Cho Phép Sử Dụng Mục Trò Chuyện',
    switchCamera: 'Đổi Máy Ảnh',
    studentAccess: 'Quyền Truy Cập của Học Sinh',
    liveVideoStreamingLower: 'Truyền Phát Video Trực Tiếp',
    startPoll: 'Bắt Đầu Thực Hiện Khảo Sát Ý Kiến',
    livePolls: 'Các Khảo Sát Ý Kiến Trực Tiếp',
    sharedScreenBecomePoll: 'Màn hình được chia sẻ sẽ trở thành một khảo sát ý kiến',
    launchInstantPoll: 'Khởi Chạy Khảo Sát Ý Kiến Tức Thì',
    studentAccessUpper: 'QUYỀN TRUY CẬP CỦA HỌC SINH',
    streamOnYoutbe: 'Truyền Phát trên Youtube',
    streamOnOtherPlatform: 'Truyền Phát trên Nền Tảng khác',
    unpin: 'Bỏ Ghim',
    pin: 'Ghim',
    hasPresenterAccess: 'có quyền truy cập với vai trò người thuyết trình',
    doYouWanToGive: 'Bạn có muốn cấp quyền này cho',
    confirmGivePresenterAccess: 'có thể chia sẻ màn hình, bảng trắng & tập tin. Bạn chắc chứ?',
    coTeacher: 'Đồng Giáo Viên',
    presenter: 'Người Thuyết Trình',
    stopAccess: 'Dừng Quyền Truy Cập',
    makePresenter: 'Cấp Vai Trò Người Thuyết Trình',
    givePresenterAccess: 'Cấp Quyền Truy Cập với vai trò Người Thuyết Trình',
    giveAccess: 'Cấp Quyền Truy Cập',
    stopPresenterAccess: 'Dừng Quyền Truy Cập với vai trò Người Thuyết Trình',
    cannotGivePresenterAccess: 'Không Thể Cấp Quyền Truy Cập với vai trò Người Thuyết Trình cho Người Dùng này',
    gotIt: 'Đã hiểu',
    askToUpdateApp: '{{name}} vẫn chưa cập nhật ứng dụng của họ. Hãy yêu cầu họ cập nhật ứng dụng để cấp vai trò người thuyết trình',
    leaderboard: 'Bảng Thành Tích',
    answered: 'Đã Trả Lời',
    notAnswered: 'Chưa Trả Lời',
    isCorrect: 'đã trả lời Đúng',
    isWrong: 'đã trả lời Sai',
    correctAnswer: 'Câu trả lời đúng',
    answeredCorrectly: 'đã trả lời chính xác',
    everybodyAnswered: 'Tuyệt vời! Tất cả đều đã trả lời',
    wrongAnswer: 'Đáp Án Sai!',
    correctAnswerUpper: 'Đáp Án Đúng!',
    line: 'Đường Thẳng',
    ellipse: 'Hình Elip',
    rectangle: 'Hình Chữ Nhật',
    triangle: 'Hình Tam Giác',
    circle: 'Hình Tròn',
    fileCorrupted: 'Tệp đã hỏng. Không thể mở tài liệu',
    youSavedWhiteboard: 'Bạn đã lưu bảng trắng',
    youtubeLiveStreamKey: 'Phím truyền phát Youtube Live',
    liveStreamNotEnabled: 'Truyền phát trực tiếp bị tắt đối với <1> </1>. Vui lòng bật chế độ truyền phát trực tiếp hoặc đăng nhập vào một tài khoản được bật chế độ truyền phát trực tiếp',
    enterLiveStreamTitle: 'Nhập một tiêu đề cho truyền phát trực tiếp của bạn',
    connectedTitle: 'Công Khai',
    public: 'Riêng Tư',
    private: 'Không Liệt Kê',
    unlisted: 'Đã Kết Nối',
    notMe: 'Không phải tôi',
    broadcastOnYoutubeLive: 'Phát lớp học Teachmint trên Youtube Live',
    titleOfStream: 'Tiêu Đề của Truyền Phát Youtube',
    enterYoutubeLiveStreamTitle: 'Nhập tiêu đề truyền phát trực tiếp lớp học Teachmint trên Youtube tại đây',
    privacySettings: 'Cài Đặt Quyền Riêng Tư',
    goLiveOnYoutube: 'Phát Trực Tiếp trên Youtube',
    liveStreamByYoutube: 'Dịch vụ truyền phát trực tiếp được cung cấp bởi Youtube',
    googlePrivacyPolicy: 'Chính Sách Quyền Riêng Tư của Google',
    termsOfService: 'Điều Khoản Dịch Vụ',
    apply: 'đăng ký',
    canRevokeAccessToData: 'Bạn có thể hủy bỏ quyền truy cập vào dữ liệu của bạn bằng cách truy cập',
    googleSecuritySettings: 'trang cài đặt bảo mật của',
    page: 'Google',
    copyrightTeachmintTPL: 'Bản Quyền 2021, Teachmint Technologies Pvt. Ltd.',
    privacyPolicy: 'Chính Sách Quyền Riêng Tư',
    cannotUnmute: 'Bạn không thể bật tiếng',
    teacherToJoin: 'Giáo Viên chưa Tham Gia',
    streamingLiveIn: 'Truyền Phát Trực Tiếp trong...',
    youtubeStreamingFailed: 'Truyền Phát qua Youtube không thành công',
    tryAgain: 'Hãy thử lại',
    isSharingScreen: 'đang chia sẻ màn hình',
    isSharingScreenDisableAudio: 'đang chia sẻ màn hình. Vui lòng tắt chế độ chỉ bật Âm Thanh.',
    youAreMuted: 'Bạn đang được tắt tiếng',
    youAreOnline: 'Bạn đang trực tuyến',
    networkDisconnected: 'Đã ngắt kết nối mạng. Đang kết nối lại',
    audioEnabled: 'Âm thanh đã được bật',
    videoEnabled: 'Video đã được bật',
    chatEnabled: 'Trò chuyện đã được bật',
    teacherStoppedPoll: 'Giáo viên đã dừng khảo sát ý kiến',
    hasSavedWhiteboard: 'đã lưu bảng trắng',
    youArePresenterNow: 'Bạn là người thuyết trình. Giờ bạn có thể chia sẻ màn hình, bảng trắng & tệp',
    yourAreNotPresenter: 'Bạn không còn là người thuyết trình. <1> Tên </1> đang thực hiện thuyết trình',
    presentationAccessRevoked: 'Quyền truy cập thuyết trình đã bị hủy. Trạng thái tệp hiện tại của bạn đã được lưu trong phiên này',
    isPresentingNow: 'đang thực hiện thuyết trình',
    teacherIsCreatingPoll: 'Giáo viên đang tạo khảo sát ý kiến',
    youLeftClassroom: 'Bạn đã rời lớp học',
    liveStreamStopped: 'Đã dừng truyền phát trực tiếp',
    youtubeLiveStreamStarted: 'Đã bắt đầu truyền phát qua Youtube Live. Hiện bạn đang phát trực tiếp trên Youtube',
    liveStreamFailed: 'Ối! Truyền phát trực tiếp không thành công.',
    youtubeLiveStreamFailed: 'Ối! Truyền phát trực tiếp trên Youtube không thành công.',
    teacherEnabledyourMic: 'Giáo viên đã bật micro của bạn',
    handLowered: 'Biểu tượng giơ tay của bạn đã được hạ xuống',
    loadingWhiteboard: 'Đang Tải Bảng Trắng',
    loadingDocument: 'Đang tải tài liệu',
    toolbarHidden: 'Đã ẩn thanh công cụ',
    clickLockToViewToolbar: 'Nhấp vào khóa để xem lại thanh công cụ',
    clickLock: 'Nhấp vào Khóa',
    closeAnyway: 'Vẫn đóng',
    saveWhiteboardBeforeExiting: 'Bạn có muốn lưu bảng trắng này trước khi thoát không',
    searchPeopleByName: 'Tìm Kiếm Mọi Người theo Tên'
}

export default VietnamLang;