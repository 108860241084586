const initialState = {
    presenterUid: null,
    currentUserIsPresenter: false,
    presenterDetails: {},
    selectedPresenterId: null,
    provideAccess: false,
}

export function presenterReducer(state = initialState, { type, payload }) {
    let newState
    switch (type) {
        case "UPDATE_PRESENTER_UID":
            newState = { ...state }
            newState.presenterUid = payload.value
            return newState
        case "UPDATE_USER_PRESENTER_STATUS":
            newState = { ...state }
            newState.currentUserIsPresenter = payload.value
            return newState
        case "UPDATE_SELECTED_PRESENTER_ID":
            newState = { ...state, selectedPresenterId: payload.value }
            return newState
        default:
            return state
    }
}
