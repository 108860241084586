import React from "react";
import "../videoroom.scss";
import endPageImage from "../images/end-page.svg";

export default function EndClassScreen({ endClassTxt, onClickRejoin }) {
    return (
        <div className="fullscreen">
            <div className="toast-container-videoroom">
                {/* <div className="toast-message">{endClassTxt}</div> */}
                <h2 className="heading-endclass">{endClassTxt}</h2>
                <img alt="" src={endPageImage} className="image-endclass"></img>
                <div className="retry-btn" onClick={onClickRejoin}>
                    Rejoin
                </div>
            </div>
        </div>
    );
}
