import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { creatToast } from "../Toast/toastCreator";
import { addToast } from "../../../redux/actions/toasts";
import WSConnection from "../../connections/WSConnection";
import { WS_MSG_TYPE, WS_STATE, NEW_TOAST_TYPES, STATUS } from "../../../constants/Enums";
import { updateUser, bulkUserUpdate } from "../../../redux/actions/usersActions";
import { setMicStatus } from "../../../redux/actions/audioActions";

export default function HandRaiseStudent({
    control,
    sendStatus
}) {
    const wsConnection = new WSConnection()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { mic } = useSelector(state => state.audio)
    const { userId, handraise, WSState } = useSelector(state => state.videoroom)
    const users = useSelector(state => state.users)
    const allowToSpeakEnabled = users[userId] && users[userId].allowToSpeakEnabled

    useEffect(() => {
        if (WSState === WS_STATE.OPEN) {
            updateHandraiseStatus(handraise)
        }
    }, [handraise])

    useEffect(() => {
        if (!mic && allowToSpeakEnabled) {
            wsConnection.sendMessageToHosts({
                type: WS_MSG_TYPE.MUTE_HAND,
            });
            dispatch(updateUser({
                uid: userId,
                prop: "allowToSpeakEnabled",
                value: false
            }))
            dispatch(addToast(creatToast(NEW_TOAST_TYPES.GENERIC_TOAST, t("audioHasBeenDisabledText"))))
        }
    }, [mic])

    const updateHandraiseStatus = (handraiseState) => {
        if (!handraiseState) {
            if (allowToSpeakEnabled && mic) {
                dispatch(setMicStatus(false))
            }
            dispatch(addToast(creatToast(NEW_TOAST_TYPES.GENERIC_TOAST, t("handLowered"))));
        }
        dispatch(bulkUserUpdate({
            uid: userId,
            updates: {
                isHandraise: handraiseState,
                allowToSpeakEnabled: false
            }
        }))
        sendHandraiseStatus(handraiseState)
    }

    const sendHandraiseStatus = (handraiseState) => {
        let newControl = control;
        let mask = 1 << STATUS.HANDRAISE;
        handraiseState ? (newControl |= mask) : (newControl &= ~mask);
        sendStatus(newControl);
    };

    return null
}