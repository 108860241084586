import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { INTEGRATION_STATUS } from "../../../constants/Enums"
import YoutubeIcon from "../../images/youtube.svg";
import WarningIcon from "../../images/warning.svg";
import Copy from "../../images/copy-blue.svg";
import { updateShowYoutubeModal } from "../../../redux/actions/modals";

export default function YTIntegrationStatus({

}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { ytIntegrationStatus, ytBroadcastId } = useSelector(state => state.streaming)
    const { classStatus } = useSelector(state => state.videoroom)
    const [copy, setCopy] = useState(false);
    const broadcastUrl = `https://youtu.be/${ytBroadcastId}`;
    const [progressPercent, setProgressPercent] = useState(0);
    const [ytInterval, setYtInterval] = useState(null);

    useEffect(() => {
        if (ytIntegrationStatus) {
            if (ytIntegrationStatus === INTEGRATION_STATUS.PROGRESS) {
                startProgress()
            } else {
                if (ytIntegrationStatus === INTEGRATION_STATUS.SUCCESS) {
                    setProgressPercent(100)
                }
                stopProgress()
            }
        }
    }, [ytIntegrationStatus])

    useEffect(() => {
        if (!classStatus) {
            stopProgress()
        }
    }, [classStatus])

    const startProgress = () => {
        if (!ytInterval) {
            const interval = window.setInterval(() => {
                setProgressPercent(progressPercent => progressPercent + 1)
            }, 1000)
            setYtInterval(interval)
        }
    }

    const stopProgress = () => {
        setProgressPercent(0)
        window.clearInterval(ytInterval)
        setYtInterval(null)
    }

    const handleCopyMessage = () => {
        setCopy(true);
        navigator.clipboard.writeText(broadcastUrl);
        window.setTimeout(() => {
            setCopy(false);
        }, 1500);
    };

    const onClickTryAgain = () => {
        dispatch(updateShowYoutubeModal(true))
    }

    return (
        <React.Fragment>
            {ytIntegrationStatus === INTEGRATION_STATUS.PROGRESS &&
                <div className="youtube-progress">
                    <img alt="youtube" className="youtube-icon" src={YoutubeIcon} />
                    <div>{t("streamingLiveIn")}</div>
                    <div style={{ color: "#fff" }}>{progressPercent}%</div>
                    <progress value={progressPercent} min="0" max="100"></progress>
                </div>}

            {ytIntegrationStatus === INTEGRATION_STATUS.SUCCESS &&
                <div className="youtube-progress">
                    <img alt="youtube" src={YoutubeIcon} />
                    <div>{t("goLiveTeacherDashboardYoutubeStreamingLiveText")} </div>
                    <div className="share-public-link">
                        {!copy ? (
                            <div>
                                <span>{broadcastUrl}</span>
                            </div>
                        ) : (
                            <div>{t("dashboardStudentsCopyClipboard")}</div>
                        )}
                    </div>
                    <img alt="copy" src={Copy} onClick={handleCopyMessage} />
                </div>}

            {ytIntegrationStatus === INTEGRATION_STATUS.FAIL &&
                <div className="youtube-progress">
                    <img alt="warning" src={WarningIcon} />
                    <div className="fail">{t("youtubeStreamingFailed")}</div>
                    <div
                        className="link"
                        onClick={onClickTryAgain}
                    >
                        {t("tryAgain")}
                    </div>
                </div>}
        </React.Fragment>
    )
}