import React from "react";

const initalState = {};

export default function toastReducer(state = initalState, { type, payload }) {
  let toasts = {};
  switch (type) {
    case "TOAST_ADDED":
      toasts = { ...state };
      const toast = payload;
      toasts[toast.id] = toast;
      return toasts;
    case "TOAST_REMOVED":
      toasts = { ...state };
      const toastId = payload;
      toasts = Object.values(toasts).filter((toast) => toast.id !== toastId);
      return toasts;
    default:
      return state;
  }
}
