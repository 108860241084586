import React from "react";
import store from "../../../redux/store";
import './WarningModal.scss';
import Cross from '../../images/cross.svg';

export default function WarningModal({
    showModal,
    closeModal,
    icon,
    heading,
    content,
    leftButton,
    rightButton,
    onLeftButtonClick,
    onRightButtonClick,
    force
}){
    const { device } = store.getState().videoroom;

    return (
        showModal ? 
            <div className="vc-warning-container">
                <div className='vc-warning-modal'>
                    {device.laptop && !force ? 
                        <img className='vc-warning-cross'
                            alt='cross' 
                            src={Cross}
                            onClick={closeModal}
                        />
                    : null}
                    {icon ?
                        <div className='vc-warning-icon'>
                            <img alt='icon' src={icon}/>
                        </div>
                    : null}
                    
                    <div className="vc-warning-header">
                        {heading}
                    </div>
                    <div className="vc-warning-title">
                        {content}
                    </div>
                    <div className="vc-warning-cont">
                        <button className='vc-left-btn' onClick={onLeftButtonClick}>
                            {leftButton}
                        </button>
                        <button className='vc-right-btn' onClick={onRightButtonClick}>
                            {rightButton}
                        </button>
                    </div>
                </div>
            </div> 
        : null
    );
}