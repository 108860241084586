import { peerConnectionCredentials } from "../../constants/credentials";
import { SENDER } from "../../constants/Enums";
import store from "../../redux/store";
import { updateSpeakerStatus } from "../../redux/actions/audioActions";
import { updateSetting } from "../../redux/actions/videoroomActions";

export default class PCConnection {
    constructor(videoroom) {
        this.videoroom = videoroom;
        this.init = false;
        this.senders = {
            [SENDER.AUDIO]: null,
            [SENDER.VIDEO]: null,
            [SENDER.SCREEN]: null,
        };
        this.speakingInterval = null;
        this.sdpList = [];
        this.sysStats = null;
    }

    start = () => {
        this.pc = new RTCPeerConnection(peerConnectionCredentials);
        this.pc.ontrack = this.onRemoteTracks;
        this.pc.onconnectionstatechange = this.onConnectionStateChange;
    };

    onConnectionStateChange = () => {
        store.dispatch(updateSetting({ setting: "PCCState", value: this.pc.connectionState }));
        if (this.pc.connectionState === "connected") {
            this.videoroom.onPCConnected();
        }
        if (this.pc.connectionState === "failed" && window.navigator.onLine) {
            this.videoroom.reconnect();
        }
    };

    onRemoteTracks = (event) => {
        if (event.streams) {
            let stream = event.streams[0];
            if (stream.id === "teachmint") {
                this.videoroom.setAudioStream(stream);
            } else {
                this.videoroom.setVideoStream(stream);
            }
        }
    };

    addTrack = (type, track) => {
        let sender = this.senders[type];
        if (sender) {
            sender.replaceTrack(track);
        } else {
            sender = this.pc.addTrack(track);
        }
        this.senders[type] = sender;
    };

    getSpeakingSignals = () => {
        let isSpeaking = false;
        let timestamp = new Date().getTime();
        const { userId } = store.getState().videoroom;
        const datasender = (data) => {
            data.forEach((e, i) => {
                if (e.type === "media-source" && e.audioLevel !== undefined) {
                    if (e.audioLevel > 0.1) {
                        timestamp = new Date().getTime();
                        if (!isSpeaking) {
                            isSpeaking = true;
                            store.dispatch(
                                updateSpeakerStatus({
                                    uid: userId,
                                    status: isSpeaking,
                                })
                            );
                        }
                    } else if (
                        e.audioLevel < 0.01 &&
                        isSpeaking &&
                        new Date().getTime() - timestamp > 1000
                    ) {
                        isSpeaking = false;
                        store.dispatch(
                            updateSpeakerStatus({
                                uid: userId,
                                status: isSpeaking,
                            })
                        );
                    }
                }
            });
        };
        if (!this.speakingInterval) {
            this.speakingInterval = window.setInterval(() => {
                this.pc.getStats().then((data) => {
                    datasender(data);
                });
            }, 100);
        }
    };

    close = () => {
        window.clearInterval(this.speakingInterval);
        this.pc.close();
        clearInterval(this.rtcStatsInterval);
    };
}
