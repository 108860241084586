import React, { useEffect, useRef, useState } from "react";
import { updateSetting } from "../../../redux/actions/videoroomActions";
import { addToast } from "../../../redux/actions/toasts";
import { creatToast } from "../../containers/Toast/toastCreator";
import i18n from "../../../i18n/i18n";
import {
    AUDIO_PACKETS_LOST_THRESHOLD,
    VIDEO_PACKETS_LOST_THRESHOLD,
    NEW_TOAST_TYPES,
} from "../../../constants/Enums";
import { useDispatch, useSelector } from "react-redux";
import SystemStatsModal from "./SystemStatsModal";
import store from "../../../redux/store";

const SystemStats = (props) => {
    const { pcConn, eventManager } = props;
    const [systemStats, setSystemStats] = useState({
        packetLoss: 0,
        jitter: 0,
        connectionDelay: 0,
        publishBitrate: 0,
        subscribeBitrate: 0,
    });

    let audioPacketsMap = {};
    let videoPacketsMap = {};
    let audioScoreList = [];
    let videoScoreList = [];
    let averageAudioScoreList = [];
    let averageVideoScoreList = [];
    const rtcStatsInterval = useRef(null)
    let bytesSended = 0;
    let bytesRecieved = 0;
    const dispatch = useDispatch();
    const { PCCState, showSystemStats } = useSelector((state) => state.videoroom);




    const resetData = ()=>{
        audioPacketsMap = {};
        videoPacketsMap = {};
        audioScoreList = [];
        videoScoreList = [];
        averageAudioScoreList = [];
        averageVideoScoreList = [];
        rtcStatsInterval.current = null;
        bytesSended = 0;
        bytesRecieved = 0;
        setSystemStats({
            packetLoss: 0,
            jitter: 0,
            connectionDelay: 0,
            publishBitrate: 0,
            subscribeBitrate: 0,
        })

    }

    const updateInternetStatus = (data) => {
        const { key, status, level } = data;

        const { isInternetUnstable } = store.getState().videoroom;

        if (status && !isInternetUnstable) {
            dispatch(updateSetting({ setting: "isInternetUnstable", value: true }));
            // const tempToast = creatToast(
            //     level == "high"
            //         ? NEW_TOAST_TYPES.UNSTABLE_NETWORK_HIGH
            //         : NEW_TOAST_TYPES.UNSTABLE_NETWORK_LOW,
            //     i18n.t("internetUnstable"),
            //     null,
            //     "Show Details"
            // );
            // dispatch(addToast(tempToast));
        }
        if (!status && isInternetUnstable) {
            dispatch(updateSetting({ setting: "isInternetUnstable", value: false }));
        }
    };

    const checkScore = (media) => {
        if (media === "audio") {
            if (averageAudioScoreList.length > 2) {
                let isUnstable = true;
                let level = "low";
                averageAudioScoreList.forEach((avScore) => {
                    if (!isUnstable) {
                        return;
                    }
                    if (avScore < AUDIO_PACKETS_LOST_THRESHOLD.low) {
                        isUnstable = false;
                        level = "none";
                        return;
                    }
                    if (
                        AUDIO_PACKETS_LOST_THRESHOLD.low < avScore &&
                        avScore < AUDIO_PACKETS_LOST_THRESHOLD.medium
                    ) {
                        isUnstable = true;
                        level = "low";
                    } else if (
                        AUDIO_PACKETS_LOST_THRESHOLD.medium < avScore &&
                        avScore < AUDIO_PACKETS_LOST_THRESHOLD.high
                    ) {
                        isUnstable = true;
                        level = "medium";
                    } else if (avScore > AUDIO_PACKETS_LOST_THRESHOLD.high) {
                        isUnstable = true;
                        level = "high";
                    }
                });
                updateInternetStatus({ key: "isAudioUnstable", status: isUnstable, level });
            }
        }
        if (media === "video") {
            if (averageVideoScoreList.length > 2) {
                let isUnstable = true;
                let level = "low";
                averageVideoScoreList.forEach((avScore) => {
                    if (!isUnstable) {
                        return;
                    }
                    if (avScore < VIDEO_PACKETS_LOST_THRESHOLD.low) {
                        isUnstable = false;
                        level = "none";
                        return;
                    }
                    if (
                        VIDEO_PACKETS_LOST_THRESHOLD.low < avScore &&
                        avScore < VIDEO_PACKETS_LOST_THRESHOLD.medium
                    ) {
                        isUnstable = true;
                        level = "low";
                    } else if (
                        VIDEO_PACKETS_LOST_THRESHOLD.medium < avScore &&
                        avScore < VIDEO_PACKETS_LOST_THRESHOLD.high
                    ) {
                        isUnstable = true;
                        level = "medium";
                    } else if (avScore > VIDEO_PACKETS_LOST_THRESHOLD.high) {
                        isUnstable = true;
                        level = "high";
                    }
                });
                updateInternetStatus({ key: "isVideoUnstable", status: isUnstable, level });
            }
        }
    };
    const calculateAudioPacketsLost = (currAudioPacketsMap) => {
        let totalAudioPacketsReceived = 0;
        let totalAudioPacketsLost = 0;
        Object.keys(currAudioPacketsMap).forEach((key) => {
            if (audioPacketsMap[key]) {
                totalAudioPacketsReceived =
                    totalAudioPacketsReceived +
                    (currAudioPacketsMap[key].packetsReceived - audioPacketsMap[key].packetsReceived);
                totalAudioPacketsLost =
                    totalAudioPacketsLost +
                    (currAudioPacketsMap[key].packetsLost - audioPacketsMap[key].packetsLost);
            } else {
                totalAudioPacketsReceived =
                    totalAudioPacketsReceived + currAudioPacketsMap[key].packetsReceived;
                totalAudioPacketsLost = totalAudioPacketsLost + currAudioPacketsMap[key].packetsLost;
            }
        });

        if (totalAudioPacketsReceived < 1) {
            return;
        }

        audioPacketsMap = currAudioPacketsMap;
        if (totalAudioPacketsLost < 0) {
            totalAudioPacketsLost = 0;
        }
        let audioPacketsLostPercent = 0;
        if (totalAudioPacketsReceived + totalAudioPacketsLost < 0) {
            audioPacketsLostPercent = 100;
        } else {
            audioPacketsLostPercent =
                (totalAudioPacketsLost / (totalAudioPacketsReceived + totalAudioPacketsLost)) * 100;
        }
        if (audioScoreList.length > 9) {
            audioScoreList.shift();
        }
        audioScoreList.push(Math.floor(audioPacketsLostPercent));
        if (audioScoreList.length > 0) {
            const audioScoreAverage =
                audioScoreList.reduce((a, b, ind) => a + (ind + 1) * b, 0) /
                ((audioScoreList.length * (audioScoreList.length + 1)) / 2);
            if (averageAudioScoreList.length > 2) {
                averageAudioScoreList.shift();
            }
            setSystemStats((prevData) => {
                return { ...prevData, packetLoss: Math.ceil(audioScoreAverage) };
            });
            averageAudioScoreList.push(audioScoreAverage);
            checkScore("audio");
        }
    };
    const calculateVideoPacketsLost = (currVideoPacketsMap) => {
        let totalVideoPacketsReceived = 0;
        let totalVideoPacketsLost = 0;

        Object.keys(currVideoPacketsMap).forEach((key) => {
            if (videoPacketsMap[key]) {
                totalVideoPacketsReceived =
                    totalVideoPacketsReceived +
                    (currVideoPacketsMap[key].packetsReceived - videoPacketsMap[key].packetsReceived);
                totalVideoPacketsLost =
                    totalVideoPacketsLost +
                    (currVideoPacketsMap[key].packetsLost - videoPacketsMap[key].packetsLost);
            } else {
                totalVideoPacketsReceived =
                    totalVideoPacketsReceived + currVideoPacketsMap[key].packetsReceived;
                totalVideoPacketsLost = totalVideoPacketsLost + currVideoPacketsMap[key].packetsLost;
            }
        });
        if (totalVideoPacketsReceived < 1) {
            return;
        }
        videoPacketsMap = currVideoPacketsMap;
        if (totalVideoPacketsLost < 0) {
            totalVideoPacketsLost = 0;
        }
        let videoPacketsLostPercent = 0;
        if (totalVideoPacketsReceived + totalVideoPacketsLost < 0) {
            videoPacketsLostPercent = 100;
        } else {
            videoPacketsLostPercent =
                (totalVideoPacketsLost / (totalVideoPacketsReceived + totalVideoPacketsLost)) * 100;
        }
        if (videoScoreList.length > 9) {
            videoScoreList.shift();
        }
        videoScoreList.push(Math.floor(videoPacketsLostPercent));
        if (videoScoreList.length > 0) {
            const videoScoreAverage =
                videoScoreList.reduce((a, b, ind) => a + (ind + 1) * b, 0) /
                ((videoScoreList.length * (videoScoreList.length + 1)) / 2);
            setSystemStats((prevData) => {
                return { ...prevData, packetLoss: Math.ceil(videoScoreAverage) };
            });
            if (averageVideoScoreList.length > 2) {
                averageVideoScoreList.shift();
            }
            averageVideoScoreList.push(videoScoreAverage);
            checkScore("video");
        }
    };

    const getStats = () => {
        pcConn.getStats(null).then((stats) => {
            let audioPacketsMap = {};
            let videoPacketsMap = {};
            let totalBytesSended = 0;
            let totalBytesRecieved = 0;
            stats.forEach((report) => {
                if (report.mediaType === "video" && report.type === "inbound-rtp") {
                    setSystemStats((prevData) => {
                        return { ...prevData, jitter: report.jitter * 1000 };
                    });
                    totalBytesRecieved = totalBytesRecieved + report.bytesReceived;
                    videoPacketsMap[report.trackId] = {
                        packetsLost: report.packetsLost,
                        packetsReceived: report.packetsReceived,
                    };
                }
                if (report.mediaType === "audio" && report.type === "inbound-rtp") {
                    audioPacketsMap[report.trackId] = {
                        packetsLost: report.packetsLost,
                        packetsReceived: report.packetsReceived,
                    };
                    totalBytesRecieved = totalBytesRecieved + report.bytesReceived;
                    setSystemStats((prevData) => {
                        return { ...prevData, jitter: report.jitter * 1000 };
                    });
                }
                if (report.type === "outbound-rtp" && report.mediaType === "video") {
                    totalBytesSended = totalBytesSended + report.bytesSent;
                }
                if (report.type === "outbound-rtp" && report.mediaType === "audio") {
                    totalBytesSended = totalBytesSended + report.bytesSent;
                }
                if (report.type === "candidate-pair") {
                    setSystemStats((prevData) => {
                        return { ...prevData, connectionDelay: report.currentRoundTripTime * 1000 };
                    });
                }
            });
            let tempPublishBitrate = ((totalBytesSended - bytesSended) / 1000).toFixed(2);
            let tempSubscribeBitrate = ((totalBytesRecieved - bytesRecieved) / 1000).toFixed(2);
            setSystemStats((prevData) => {
                return {
                    ...prevData,
                    publishBitrate: tempPublishBitrate,
                    subscribeBitrate: tempSubscribeBitrate,
                };
            });
            bytesSended = totalBytesSended;
            bytesRecieved = totalBytesRecieved;
            if (Object.keys(videoPacketsMap).length > 0) {
                calculateVideoPacketsLost(videoPacketsMap);
            } else {
                calculateAudioPacketsLost(audioPacketsMap);
            }
        });
    };

    useEffect(() => {

        if (PCCState === "connected") {
            if(rtcStatsInterval.current===null){
                rtcStatsInterval.current = setInterval(getStats, 1000);
            }
        }else{
            clearInterval(rtcStatsInterval.current);
            rtcStatsInterval.current = null
            resetData()

        }
    }, [PCCState]);

    return <>{showSystemStats && <SystemStatsModal statsData={systemStats} />}</>;
};

export default SystemStats;
