import { getUserHSLcolor } from "../utils/Utils";
import { STATUS } from "../../constants/Enums";

export default function User(uid, name, status, isScreenShare, videoroom, utype){
    return {
        uid :uid,
        name :name,
        status :status,
        isScreenShare :isScreenShare,
        utype :utype,
        color :getUserHSLcolor(uid),
        mute :true,
        playing :false,
        isSpeaking :false,
        shouldReflect :false,
        isPinned :false,
        isHandraise :false,
        stream :null,
        isTeacher :utype === 1 || utype === 11,
        didLaunchPoll :false,
        isSharingScreen :false,
        isRecording :false,
        isStreaming :false,
        user :null, //store reference of user if this is screen share
        allowToSpeakEnabled :false,
    }
}