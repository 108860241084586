export const updateRoomSetting = (update) =>
({
    type: 'UPDATE_ROOM_SETTING',
    payload: update
});
export const setStudentMicSetting = (flag) =>
({
    type: 'SET_STUDENT_MIC_SETTING',
    payload: { value: flag }
})
export const setStudentVideoSetting = (flag) =>
({
    type: 'SET_STUDENT_VIDEO_SETTING',
    payload: { value: flag }
})
export const setStudentChatSetting = (flag) =>
({
    type: 'SET_STUDENT_CHAT_SETTING',
    payload: { value: flag }
})
export const setSendMicSetting = (flag) =>
({
    type: 'SET_SEND_MIC_SETTING',
    payload: { value: flag }
})
export const setSendVideoSetting = (flag) =>
({
    type: 'SET_SEND_VIDEO_SETTING',
    payload: { value: flag }
})
export const setSendChatSetting = (flag) =>
({
    type: 'SET_SEND_CHAT_SETTING',
    payload: { value: flag }
})