const initalState = {
    recScreenshare: false,
    sendScreenshare: false,
    screenShareId: "",
    companyId: "",
    classId: "",
    userId: "",
    hotKey: "",
    localVideoId: "",
    classInfo: {},
    roomMeta: {},
    recording: false,
    screenShareOption: "none", // "none"-> for no screen share, "whiteboard", "pdf", "image",
    shareScreenTab: false,
    documentFile: null,
    sharedDocumentUrl: null,
    pinnedSub: "",
    selectOption: null,
    roomState: 0,
    isPicInPic: false,
    handraise: false,
    showHandRaise: false,
    presenterId: null,
    device: {},
    showSaveWB: false,
    savedWhiteBoardAtleastOnce: false,
    isOnline: true,
    recordingTimer: 0,
    recordingActivity: [],
    recordedAtleastOnce: false,
    selectedParticipant: null,
    mbActionUser: null,
    gotoVideoroom: false,
    classStatus: false,
    showScreenshare: false,
    PCCState: "",
    WSState: 0,
    showExitWB: false,
    isInternetUnstable: false,
    showSystemStats: false,
    mirrorView: true,
    savePdfInProcess: false,
    showExitPdf: false,
    updateRecordingCounter: 0,
    autoStartRecording: false,
};

export function videoroomReducer(state = initalState, { type, payload }) {
    let newState;
    switch (type) {
        case "SET_VIDEO":
            newState = { ...state };
            newState.video = payload;
            return newState;
        case "UPDATE_SETTING":
            newState = { ...state };
            let { setting, value } = payload;
            newState[setting] = value;
            return newState;
        case "BULK_UPDATE_SETTINGS":
            newState = { ...state };
            let settings = payload;
            Object.keys(settings).forEach((key) => (newState[key] = settings[key]));
            return newState;
        case "UPDATE_CLASSINFO_SETTINGS":
            newState = { ...state };
            newState.settings = payload;
            return newState;
        default:
            return state;
    }
}
