import React, { Suspense } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as userActions from "../redux/actions/usersActions";
import * as videoroomActions from "../redux/actions/videoroomActions";
import * as permissionActions from "../redux/actions/permissionActions";
import * as pollActions from "../redux/actions/pollActions";
import * as roomSettingActions from "../redux/actions/roomSettingActions";
import * as toastActions from "../redux/actions/toasts";
import * as chatActions from "../redux/actions/chatActions";
import * as presenterActions from "../redux/actions/presenter";
import * as audioActions from "../redux/actions/audioActions";
import * as videoActions from "../redux/actions/videoActions";
import * as closeActions from "../redux/actions/closeActions";
import * as observerUserAction from "../redux/actions/observerUsersAction";
import { creatToast } from "./containers/Toast/toastCreator";
import "./videoroom.scss";
//*** Do not remove adapter. Used as polyfill for webrtc apis
import adapter from "webrtc-adapter";
import EventManager from "./utils/EventManager";
import EventClicks from "./utils/EventClicks";
import Loader from "./components/Loader";
import GoLivePreview from "./components/GoLivePreview";
import StudentPreview from "./components/StudentPreview";
import WarningModal from "./containers/WarningModal/WarningModal";
import User from "./components/User";
import Room from "./components/Room";
import ConfirmationModal from "./components/Modals/ConfirmationModal";
import { utilsGetRoomInfo } from "./requests/Request";
import WSConnection from "./connections/WSConnection";
import PCConnection from "./connections/PCConnection";
import {
    removeTraiAndLeadSpaces,
    getReadableTime,
    getTimestamp,
    isInViewPort,
    getChatTime,
} from "./utils/Utils";
import { withTranslation } from "react-i18next";
import { setControls, isPlaying, getName, isPresenter } from "./utils/userUtils";
import { updateCloseAllPopups, updateIsPopupOpened } from "../redux/actions/modals";
import store from "../redux/store";
import SystemStats from "./components/SystemStatsModals/SystemStats";
import MediaPermissions from "./components/MediaPermissions/MediaPermissions";

import {
    ERROR,
    BOP,
    SHARE,
    STATUS,
    STREAM,
    WS_MSG_TYPE,
    WS_MSG_TO,
    BITRATE,
    VIDEO_OPTIONS,
    POLL_TYPE,
    RS,
    QUALITY,
    INTEGRATION_STATUS,
    TOAST_TYPE,
    SENDER,
    YOUTUBE_STREAM_URL,
    COMPANY,
    ROOM_STATE,
    ROOM_META,
    RECORD_LOG,
    NEW_TOAST_TYPES,
} from "../constants/Enums";
import ChatAudio from "./components/chatAudio.mp3";
import ModalExitWhiteboard from "./images/modal-exit-wb-icon.svg";
import GreenLock from "./images/lock-green.svg";
import PermissionSnapshot from "./images/permission-snap.svg";
import PermissionSnapshotMobile from "./images/permission-snap-mobile.svg";
import {
    utilLoadGoogleScript,
    GOOGLE_CLIENTID,
    GOOGLE_APIKEY,
    DISCOVERY_DOCS,
    SCOPES,
    createBroadcast,
    getListStreams,
    createLiveStream,
    bindBroadcastAndStream,
    getLiveStreamStatus,
    transitionBroadcast,
    pollYoutubeApi,
} from "./components/googleApiUtils";
import { getPermissions } from "./components/Permissions";
import WebrtcClient from "./utils/webrtcUtils";
import EndClass from "./components/EndClass";
import { teachmintWebsite } from "../constants/envVariables";
import RoomSettings from "./containers/RoomSettings/RoomSettings";
import Presenter from "./containers/Presenter/Presenter";
import { BufferStream } from "./containers/BufferStrean/BufferStream";
import Audio from "./components/Audio/Audio";
import Video from "./components/Video/Video";
import Recording from "./containers/Recording/Recording";
import Close from "./containers/Close/close";
import Chat from "./containers/ChatWindow/Chat";
import queryString from "query-string";
import HandRaiseStudent from "./containers/HandRaise/HandRaiseStudent";
const webrtc = new WebrtcClient();

const WebVC = React.lazy(() => import("./containers/WebVC/WebVC"));
const MobileVC = React.lazy(() => import("./containers/MobileVC/MobileVC"));

class Videoroom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            utype: 0,
            update: false,
            redirect: false,
            redirectTo: null,
            errorType: null,
            toast: "", //need to be refactored into separate component
            showWarning: false, //should belong to local state
            control: 0,
            loading: true, //should belong to local state
            loaderText: "", //should belong to local state
            showEndClassPage: false, //should belong to local state
            eventManager: null,
            showExitWB: false, //should belong to local state
            showPermissionModal: false,
            alreadyShownPermissionModal: false,
            prevVideoQuality: null,
        };
        this.publisher = {};
        this.userStreams = {};
        this.prevUserStr = "";
        this.prevUsrStateStr = "";
        this.currentName = "";
        this.whiteboard = null;
        this.pdfShare = null;
        this.videoEnabled = false;
        this.participantCount = 0;
        this.notAnswered = {};
        this.pollLaunchedTime = "";
        this.pollTimer = null;
        this.winners = [];
        this.warningData = {
            icon: null,
            heading: "",
            content: "",
            leftBtnTxt: "",
            leftBtnAction: null,
            rightBtnTxt: "",
            rightBtnAction: null,
        };
        // this.handRaiseMap = {};
        this.toastList = {};
        this.toastCount = 0;
        this.setConnection = false;
        this.eventClicks = null;
        this.offlineFlag = false;
        this.showOfflineMsg = false;
        this.offlineTimeout = null;
        this.eventDuration = {
            Mic_on: 0,
            Video_on: 0,
            Share_screen: 0,
            Share_pdf: 0,
            Share_whiteboard: 0,
        };
    }

    setToastMessage = (text) => {
        this.setState({ toast: text });
        window.setTimeout(() => {
            this.setState({ toast: "" });
        }, 3000);
    };

    setSelectOption = (option) => {
        this.props.updateSetting({
            setting: "selectOption",
            value: option,
        });
    };

    setShowQualityOption = (showQuality) => {
        this.setState({ showQualityOption: showQuality });
    };

    setStatusFlag = (flag, bitVal) => {
        let control = this.state.control;
        let mask = 1 << flag;
        control = (control & ~mask) | (bitVal << flag);
        this.sendStatus(control);
    };

    setLoading = (loading, loaderText) => {
        this.setState({
            loading: loading,
            loaderText: loaderText || "",
        });
    };

    sendStatus = (control) => {
        //TODO: have a property is redux that gives ws connection state
        if (this.wsConnection && this.wsConnection.ws.readyState === 1) {
            const msg = {
                type: WS_MSG_TYPE.STATUS,
                [WS_MSG_TYPE.STATUS]: control,
            };
            this.wsConnection.sendMsg(msg);
            this.setState({ control: control });
        }
    };

    sendRoomMeta = (type, obj) => {
        //TODO: have a property is redux that gives ws connection state
        if (this.wsConnection && this.wsConnection.ws.readyState === 1) {
            let roomMeta = this.props.videoroom.roomMeta;
            roomMeta[type] = obj;
            const msg = {
                type: WS_MSG_TYPE.ROOM_META,
                [WS_MSG_TYPE.ROOM_META]: JSON.stringify(roomMeta),
            };
            this.wsConnection.sendMsg(msg);
            this.props.updateSetting({
                setting: "roomMeta",
                value: roomMeta,
            });
        }
    };

    sendRoomState = (roomState) => {
        this.sendRoomMeta(ROOM_META.ROOM_STATE, roomState);
        this.props.updateSetting({
            setting: "roomState",
            value: roomState,
        });
    };

    sendRecordingActivity = (recordingActivity) => {
        this.sendRoomMeta(ROOM_META.RECORD_LOG, recordingActivity);
        this.props.updateSetting({
            setting: "recordingActivity",
            value: recordingActivity,
        });
    };

    addToast = (toast) => {
        this.toastList[toast.id] = toast;
        this.setState({
            update: !this.state.update,
        });
        if (toast && toast.disappear) {
            window.setTimeout(() => {
                this.deleteToast(toast.id);
            }, 5000);
        }
    };

    deleteToast = (id) => {
        if (this.toastList[id]) delete this.toastList[id];
        this.setState({
            update: !this.state.update,
        });
    };

    setEventListeners = () => {
        window.addEventListener("offline", this.onOffline);
        window.addEventListener("online", this.onOnline);
    };

    countEventDuration = () => {
        if (this.props.audio.mic) {
            this.eventDuration.Mic_on = this.eventDuration.Mic_on + 1;
        }
        if (this.props.video.camera) {
            this.eventDuration.Video_on = this.eventDuration.Video_on + 1;
        }
        //count event only if user is sending screenshare stream
        if (this.props.videoroom.sendScreenshare) {
            if (this.props.videoroom.screenShareOption === SHARE.SCREEN) {
                this.eventDuration.Share_screen = this.eventDuration.Share_screen + 1;
            } else if (this.props.videoroom.screenShareOption === SHARE.WHITEBOARD) {
                this.eventDuration.Share_whiteboard = this.eventDuration.Share_whiteboard + 1;
            } else if (this.props.videoroom.screenShareOption === SHARE.PDF) {
                this.eventDuration.Share_pdf = this.eventDuration.Share_pdf + 1;
            }
        }
    };

    checkMainVideo = () => {
        let users = Object.values(this.props.users);
        let localVideo = null;
        let cstream = null;
        let displayName = null;
        let playing = false;
        let uid = this.props.videoroom.localVideoId;
        const { permissions } = this.props;
        if (permissions.isViewer) {
            //show only the presenter's video/share for utype 3
            const presenterId = this.props.presenter.presenterUid;
            const presenter = this.props.users[presenterId];
            if (presenter) {
                const screen = this.props.users[presenterId + "-screen"];
                if (screen) {
                    localVideo = screen;
                } else if (presenter) {
                    localVideo = presenter;
                }
            } else {
                const teacher = Object.values(this.props.users).find(
                    (o) => !o.isScreenShare && o.utype === 1
                );
                if (teacher) {
                    localVideo = teacher;
                } else {
                    localVideo = this.publisher;
                }
            }
        } else {
            for (const user of users) {
                //pinned user
                if (user.isPinned) {
                    localVideo = user;
                }
            }
            if (!localVideo && this.publisher && (users.length === 1 || !displayName)) {
                let currentSpeaker = this.props.users[this.props.audio.currentSpeakerUid];
                if (currentSpeaker) {
                    //current speaker
                    localVideo = currentSpeaker;
                } else if (this.props.presenter.presenterUid) {
                    const presenter = this.props.users[this.props.presenter.presenterUid];
                    if (presenter) {
                        const screen = this.props.users[presenter.uid + "-screen"];
                        if (this.props.videoroom.recScreenshare || screen) {
                            localVideo = screen; //presenter's screen
                        } else if (presenter) {
                            localVideo = presenter; //presenter's video
                        }
                    } else {
                        localVideo = this.publisher;
                    }
                } else if (this.props.users[this.props.audio.lastActiveSpeakerUid]) {
                    //last active speaker
                    localVideo = this.props.users[this.props.audio.lastActiveSpeakerUid];
                } else {
                    localVideo = this.publisher;
                }
            }
        }
        if (localVideo) {
            cstream = this.userStreams[localVideo.uid];
            playing = localVideo.playing;
            displayName = getName(localVideo);
            uid = localVideo.uid;
            this.shouldReflect = localVideo.shouldReflect;
        }
        if (
            localVideo &&
            !(
                this.props.videoroom.localVideoId === uid &&
                this.displayName === displayName &&
                this.cstream === cstream &&
                this.playing === playing
            )
        ) {
            if (localVideo.isScreenShare && this.props.videoroom.showScreenshare) {
                this.props.updateSetting({
                    setting: "showScreenshare",
                    value: false,
                });
            } else if (this.props.video.stopAllVideos) {
                this.setLoading(false);
            }
            this.cstream = cstream;
            this.playing = playing;
            this.currentName = this.displayName = displayName;

            try {
                if (playing) document.getElementById("local_video").srcObject = cstream;
                document.getElementById("center_name").innerHTML = this.currentame
                    .toUpperCase()
                    .slice(0, 2);
            } catch { }
            this.props.updateSetting({
                setting: "localVideoId",
                value: uid,
            });
            if (!this.props.video.stopAllVideos && playing) {
                try {
                    document.getElementById("center_name").style.visibility = "hidden";
                    document.getElementById("local_video").style.visibility = "visible";
                } catch { }
            } else {
                try {
                    document.getElementById("local_video").style.visibility = "hidden";
                    document.getElementById("center_name").style.visibility = "visible";
                } catch { }
            }
        }

        if (permissions.canEnableAudioOnly) {
            if (this.props.video.stopAllVideos) {
                try {
                    document.getElementById("center_name").style.visibility = "visible";
                    document.getElementById("local_video").style.visibility = "hidden";
                } catch { }
            }
        }
    };

    startWSConnection = () => {
        this.wsConnection = new WSConnection(
            this.state.eVaaSurl,
            this.props.videoroom.classId,
            this.props.videoroom.userId,
            this.props.videoroom.hotKey,
            this
        );
        this.wsConnection.start();
        this.publisher = new User(
            this.props.videoroom.userId,
            this.props.videoroom.classInfo.uname,
            this.state.control,
            false,
            this,
            this.state.utype
        );
        this.publisher.shouldReflect = true;
        this.roomInfo = new Room(0, this.state.utype, this.publisher, this);
        this.props.addUser(this.publisher);
        this.setEventListeners();
        webrtc.stopPreviewTracks();
        this.eventClicks = new EventClicks();
        this.state.eventManager.live_class_started(this.props.videoroom.classId);
    };

    toggleControlState = (ctrl) => {
        let control = this.state.control;
        control = control ^ (1 << ctrl);
        this.sendStatus(control);
    };

    onReceiveRTCMessage = (data) => {
        this.peerConnection.sdpList.push(data);
        if (this.props.permissions.isViewer) {
            this.streamerId = data.from;
        }
        if (this.wsConnection.status === "OPEN") {
            if (this.peerConnection.sdpList[0])
                this.setRemoteDescription(this.peerConnection.sdpList[0]);
        }
    };

    onConnectOtherInstance = () => {
        this.warningData = {
            icon: null,
            heading: this.props.t("connectedAnotherDeviceText"),
            content: this.props.t("continueCurrentSession"),
            leftBtnTxt: this.props.t("noButtonText"),
            leftBtnAction: () => {
                this.setState({ showWarning: false });
                this.wsConnection.tryReconnect = false;
                // this.sendLiveStatus();
                this.props.updateEndClassSettings({ endClassForSelf: true });
            },
            rightBtnTxt: this.props.t("yesButtonText"),
            rightBtnAction: () => {
                this.setState({ showWarning: false });
                this.onClickRejoin();
            },
            force: true,
        };
        this.setState({ showWarning: true });
    };

    onOnline = () => {
        this.offlineFlag = false;
        window.clearTimeout(this.offlineTimeout);
        this.offlineTimeout = null;
        if (this.showOfflineMsg) {
            this.updateOnline();
        }
    };

    onOffline = () => {
        this.offlineFlag = true;
        this.showOfflineMsg = false;
        if (!this.offlineTimeout) {
            this.offlineTimeout = window.setTimeout(() => {
                if (this.offlineFlag) {
                    this.updateOffline();
                }
            }, 5 * 1000);
        }
    };

    updateOnline = () => {
        this.props.newToastAdder(creatToast(NEW_TOAST_TYPES.ONLINE, this.props.t("youAreOnline")));

        this.props.updateSetting({
            setting: "isOnline",
            value: true,
        });
        this.onRestart();
    };

    updateOffline = () => {
        this.showOfflineMsg = true;
        this.props.newToastAdder(
            creatToast(NEW_TOAST_TYPES.OFFLINE, this.props.t("networkDisconnected"))
        );
        window.clearInterval(this.recordingInterval);
        this.recordingInterval = null;
        this.props.updateAllUsers({
            prop: "playing",
            value: false,
        });
        this.props.bulkUpdateSettings({
            pinnedSub: null,
            roomState: 0,
            isOnline: false,
        });
    };

    reconnect = async () => {
        if (
            this.props.videoroom.classStatus &&
            !this.wsConnection.ws.restart &&
            this.wsConnection.tryReconnect
        ) {
            try {
                this.peerConnection.close();
                let close = await this.wsConnection.close();
                let started = await this.startWSConnection();
                console.log("*** new connection established");
                this.participantCount = 0;
                this.prevUserStr = "";
                console.log("recordingn before setting", this.props.videoroom.recording);

                let classInfo = null;
                let data = await utilsGetRoomInfo(
                    this.props.videoroom.classId,
                    this.props.videoroom.userId,
                    this.props.videoroom.hotKey
                );
                classInfo = data.data;
                this.props.updateSetting({
                    setting: "classInfo",
                    value: classInfo,
                });
                const { settings } = classInfo;
                for (const s in settings) {
                    this.props.updateRoomSetting({
                        setting: s,
                        value: settings[s],
                    });
                }
                // window.clearInterval(this.recordingInterval);
                // this.recordingInterval = null;
                // this.props.updateAllUsers({
                //     prop: "playing",
                //     value: false,
                // });
                // this.prevUserStr = "";
                console.log("recordingn after setting", this.props.videoroom.recording);
                console.log("roomState", this.props.videoroom.roomState);
                console.log(
                    "(this.props.videoroom.roomState >> ROOM_STATE.RECORDING) & 1",
                    (this.props.videoroom.roomState >> ROOM_STATE.RECORDING) & 1
                );
                // this.props.bulkUpdateSettings({
                //     recording: (this.props.videoroom.roomState >> ROOM_STATE.RECORDING) & 1,
                //     sendScreenshare: this.publisher.isSharingScreen,
                //     pinnedSub: null,
                // });
                // this.props.bulkUpdateSettings({
                //     recording: (this.props.videoroom.roomState >> ROOM_STATE.RECORDING) & 1,
                // });
                // this.props.resetUsers();
            } catch (err) {
                console.log("Error :", err);
            }
        }
    };

    onRestart = async () => {
        window.clearInterval(this.recordingInterval);
        this.recordingInterval = null;
        this.props.updateAllUsers({
            prop: "playing",
            value: false,
        });
        this.prevUserStr = "";
        this.props.resetUsers();
        this.participantCount = 0;
        this.props.bulkUpdateSettings({
            recording: Boolean((this.props.videoroom.roomState >> ROOM_STATE.RECORDING) & 1),
            sendScreenshare: this.publisher.isSharingScreen,
            pinnedSub: null,
        });
        if (this.wsConnection.ws.readyState === 1) {
            this.wsConnection.restart = true;
            this.wsConnection.close();
            this.peerConnection.close();
        } else {
            this.peerConnection.close();
            this.startWSConnection();
        }

        let classInfo = null;
        try {
            let data = await utilsGetRoomInfo(
                this.props.videoroom.classId,
                this.props.videoroom.userId,
                this.props.videoroom.hotKey
            );
            classInfo = data.data;
            this.props.updateSetting({
                setting: "classInfo",
                value: classInfo,
            });
            this.props.updatePresenterUid("");
            const { settings } = classInfo;
            for (const s in settings) {
                this.props.updateRoomSetting({
                    setting: s,
                    value: settings[s],
                });
            }
        } catch { }
    };

    onWebsocketError = () => {
        this.setState({
            redirect: true,
            redirectTo: "error",
            errorType: ERROR.WEBSOCKET,
        });
    };

    setTypeMessage = (data) => {
        let text = JSON.parse(data.text);
        const { canLaunchPoll, canChangeStudentControls } = this.props.permissions;
        if(text.type === WS_MSG_TYPE.STREAM_SUBSCRIBERS){
            if (this.state.utype === 1 || this.state.utype === 11){
                if (text.action === "added"){
                    if(text.users){
                        text.users.forEach((user)=>{
                            this.props.addObserverUser(user)
                        })
                    } 
                }
                if(text.action === "removed"){
                    if(text.users){
                        text.users.forEach((user)=>{
                            this.props.removeObserverUser(user.uid)
                        })
                    }
                }
            }
        }
        if (text.type === WS_MSG_TYPE.CHAT_MSG) {
            this.onReceiveChatMessage(data.from, data.from_name, text.chat_message);
        }
        if (data.from !== this.props.videoroom.userId && canLaunchPoll) {
            switch (text.type) {
                case WS_MSG_TYPE.POLL_ANSWER: {
                    this.evaluatePoll(text, data.from, data.from_name);
                    break;
                }
                default:
                    break;
            }
        }
        if (data.from !== this.props.videoroom.userId) {
            switch (text.type) {
                case WS_MSG_TYPE.MUTE_ALL: {
                    //teacher and co-teacher can mute each other. only students are muted on mute all
                    if (data.to === this.props.videoroom.userId || this.props.permissions.isStudent) {
                        this.props.newToastAdder(
                            creatToast(NEW_TOAST_TYPES.GENERIC_TOAST, this.props.t("mutedTeacherText"))
                        );
                        this.props.setMicStatus(false);
                    }
                    break;
                }
                case WS_MSG_TYPE.MUTE_HAND: {
                    if (this.props.permissions.canRaiseHand && data.to === this.props.videoroom.userId) {
                        this.props.setMicStatus(false);
                        this.props.updateUser({
                            uid: this.props.videoroom.userId,
                            prop: "allowToSpeakEnabled",
                            value: false,
                        });
                    } else {
                        if (text.uid || data.from) {
                            this.props.updateUser({
                                uid: text.uid || data.from,
                                prop: "allowToSpeakEnabled",
                                value: false,
                            });
                        }
                    }
                    break;
                }
                case WS_MSG_TYPE.BLOCK_AUDIO: {
                    this.props.setStudentMicSetting(true);
                    canChangeStudentControls
                        ? this.props.newToastAdder(
                            creatToast(
                                NEW_TOAST_TYPES.GENERIC_TOAST,
                                "Students' audio has been disabled"
                            )
                        )
                        : this.props.newToastAdder(
                            creatToast(
                                NEW_TOAST_TYPES.GENERIC_TOAST,
                                this.props.t("audioHasBeenDisabledText")
                            )
                        );

                    if (this.props.permissions.isStudent) {
                        this.props.setMicStatus(false);
                    }
                    break;
                }
                case WS_MSG_TYPE.BLOCK_VIDEO: {
                    this.props.setStudentVideoSetting(true);
                    canChangeStudentControls
                        ? this.props.newToastAdder(
                            creatToast(
                                NEW_TOAST_TYPES.GENERIC_TOAST,
                                "Students' video has been disabled"
                            )
                        )
                        : this.props.newToastAdder(
                            creatToast(
                                NEW_TOAST_TYPES.GENERIC_TOAST,
                                this.props.t("videoHasBeenDisabledText")
                            )
                        );
                    if (this.props.permissions.isStudent) {
                        this.props.setCameraStatus(false);
                    }
                    break;
                }
                case WS_MSG_TYPE.BLOCK_CHAT: {
                    this.props.setStudentChatSetting(true);
                    canChangeStudentControls
                        ? this.props.newToastAdder(
                            creatToast(
                                NEW_TOAST_TYPES.GENERIC_TOAST,
                                "Students' chat has been disabled"
                            )
                        )
                        : this.props.newToastAdder(
                            creatToast(
                                NEW_TOAST_TYPES.GENERIC_TOAST,
                                this.props.t("chatHasBeenDisabledText")
                            )
                        );
                    break;
                }
                case WS_MSG_TYPE.UNBLOCK_AUDIO: {
                    this.props.setStudentMicSetting(false);
                    canChangeStudentControls
                        ? this.props.newToastAdder(
                            creatToast(
                                NEW_TOAST_TYPES.GENERIC_TOAST,
                                "Students' audio has been enabled"
                            )
                        )
                        : this.props.newToastAdder(
                            creatToast(NEW_TOAST_TYPES.GENERIC_TOAST, this.props.t("audioEnabled"))
                        );
                    break;
                }
                case WS_MSG_TYPE.UNBLOCK_VIDEO: {
                    this.props.setStudentVideoSetting(false);
                    canChangeStudentControls
                        ? this.props.newToastAdder(
                            creatToast(
                                NEW_TOAST_TYPES.GENERIC_TOAST,
                                "Students' video has been enabled"
                            )
                        )
                        : this.props.newToastAdder(
                            creatToast(NEW_TOAST_TYPES.GENERIC_TOAST, this.props.t("videoEnabled"))
                        );
                    break;
                }
                case WS_MSG_TYPE.UNBLOCK_CHAT: {
                    this.props.setStudentChatSetting(false);
                    canChangeStudentControls
                        ? this.props.newToastAdder(
                            creatToast(
                                NEW_TOAST_TYPES.GENERIC_TOAST,
                                "Students' chat has been enabled"
                            )
                        )
                        : this.props.newToastAdder(
                            creatToast(NEW_TOAST_TYPES.GENERIC_TOAST, this.props.t("chatEnabled"))
                        );
                    break;
                }
                case WS_MSG_TYPE.POLL_NEW: {
                    this.attemptPoll(text, data.from);
                    break;
                }
                case WS_MSG_TYPE.STOP_POLL: {
                    this.props.newToastAdder(
                        creatToast(NEW_TOAST_TYPES.GENERIC_TOAST, this.props.t("teacherStoppedPoll"))
                    );
                    this.stopPoll(data.from);
                    break;
                }
                case WS_MSG_TYPE.POLL_WINNERS: {
                    this.getWinners(text);
                    break;
                }
                case WS_MSG_TYPE.HAND_DOWN: {
                    if (
                        this.props.permissions.canRaiseHand &&
                        (!data.to || data.to === this.props.videoroom.userId)
                    ) {
                        this.props.updateSetting({
                            setting: "handraise",
                            value: false,
                        });
                    }
                    break;
                }
                case WS_MSG_TYPE.ALLOW_HAND: {
                    if (
                        data.to === this.props.videoroom.userId &&
                        !this.props.permissions.isTeacher &&
                        !this.props.permissions.isCoTeacher
                    ) {
                        this.props.newToastAdder(
                            creatToast(
                                NEW_TOAST_TYPES.GENERIC_TOAST,
                                this.props.t("teacherEnabledyourMic")
                            )
                        );
                    }
                    if (
                        this.props.permissions.canDropRaisedHand ||
                        data.to === this.props.videoroom.userId
                    ) {
                        this.props.updateUser({
                            uid: text.uid || data.to,
                            prop: "allowToSpeakEnabled",
                            value: true,
                        });
                    }
                    break;
                }
                case WS_MSG_TYPE.WHITEBOARD_SAVED: {
                    const user = this.props.users[data.from];
                    if (user) {
                        this.props.newToastAdder(
                            creatToast(
                                NEW_TOAST_TYPES.GENERIC_TOAST,
                                `${getName(user)} ${this.props.t("hasSavedWhiteboard")}`
                            )
                        );
                    }
                    break;
                }
                case WS_MSG_TYPE.PDF_SAVED: {
                    const user = this.props.users[data.from];
                    if (user) {
                        this.props.newToastAdder(
                            creatToast(
                                NEW_TOAST_TYPES.GENERIC_TOAST,
                                `${getName(user)} ${this.props.t("hasSavedPDF")}`
                            )
                        );
                    }
                    break;
                }
                case WS_MSG_TYPE.LAUNCH_TIME: {
                    this.pollLaunchedTime = text[WS_MSG_TYPE.LAUNCH_TIME];
                    this.props.updatePollSettings({
                        correctAnswer: text.answer,
                    });
                    break;
                }
                default: {
                    return null;
                }
            }
            this.setState({ update: !this.state.update });
        }
    };

    stopSharingIfPresenter = () => {
        switch (this.props.videoroom.screenShareOption) {
            case SHARE.SCREEN: {
                this.stopScreenShare();
                break;
            }
            case SHARE.WHITEBOARD: {
                this.props.permissions.canSaveWhiteboard
                    ? this.props.updateSetting({
                        setting: "showExitWB",
                        value: true,
                    })
                    : this.stopCanvasShare();
                break;
            }
            case SHARE.PDF: {
                this.props.permissions.canSaveWhiteboard
                    ? this.props.updateSetting({
                        setting: "showExitPdf",
                        value: true,
                    })
                    : this.stopCanvasShare();
                break;
            }
            default: {
                break;
            }
        }
    };

    overridePresenterAndShare = () => {
        this.makeMePresenter();
        this.props.updateSetting({
            setting: "selectedParticipant",
            value: null,
        });
    };

    makeMePresenter = () => {
        this.props.updatedSelectedPresenterID(this.props.videoroom.userId);
    };

    addUsers = (user) => {
        const { permissions } = this.props;
        let userObj = new User(user.uid, user.name, 0, false, this, user.utype);
        user.utype = parseInt(user.utype, 10);
        this.props.addUser(userObj);
        if (isPresenter(userObj)) {
            const screen = this.props.users[userObj.uid + "-screen"];
            this.setSelectedPin(true, screen ? screen.uid : userObj.uid);
        }
        userObj = setControls(user.status, userObj, this);
        this.props.addUser(userObj);
        this.participantCount = this.participantCount + 1;

        if (permissions.canDropRaisedHand) {
            if (this.participantCount === 1) {
                this.state.eventManager.live_class_started_with_student(this.props.videoroom.classId);
            }
            // if (userObj.isHandraise) {
            //     this.handRaiseMap[userObj.uid] = userObj;
            // }
        }
    };

    removeUsers = (rusers) => {
        for (const ruser of rusers) {
            let user = this.props.users[ruser.uid];
            if (user) {
                let index = this.props.audio.frequentSpeakers.indexOf(user.uid);
                if (index !== -1) {
                    const newFrequentSpeakers = this.props.audio.frequentSpeakers;
                    newFrequentSpeakers.splice(index, 1);
                    this.props.setFrequentSpeakers(newFrequentSpeakers);
                }

                if (this.prevUserStr && this.prevUserStr.includes(user.uid + ",")) {
                    this.prevUserStr = this.prevUserStr.replace(user.uid + ",", "");
                }

                if (user.uid === this.props.audio.lastActiveSpeakerUid) {
                    this.props.setLastActiveSpeakerUid(null);
                }
                this.props.updateSpeakerStatus({
                    uid: user.uid,
                    status: false,
                });

                if (this.props.presenter.presenterUid === user.uid) {
                    if (this.props.permissions.isTeacher) {
                        this.makeMePresenter();
                    } else if (user.utype === 1) {
                        if (this.props.videoroom.recording) {
                            window.clearInterval(this.recordingInterval);
                            this.recordingInterval = null;
                            this.props.updateSetting({
                                setting: "recording",
                                value: false,
                            });
                        }
                    }
                    //remove screenshare tile
                    if (this.props.users[user.uid + "-screen"]) {
                        this.setLoading(false);
                        this.props.updateSetting({
                            setting: "recScreenshare",
                            value: false,
                        });
                        // this.showScreenshare = false
                        this.props.updateSetting({
                            setting: "showScreenshare",
                            value: false,
                        });
                    }
                    this.setSelectedPin(false);
                }

                if (this.props.polls.pollLaunched && user.didLaunchPoll) {
                    this.stopPoll(); //stop poll if user who launched the poll exits
                    this.cancelPoll();
                }

                if (user.isPinned) {
                    this.setSelectedPin(false); //dont pin anyone
                }

                this.props.removeUser(ruser.uid);
                this.participantCount = this.participantCount - 1;

                //remove screenshare tile
                if (this.props.users[user.uid + "-screen"]) {
                    this.props.removeUser(user.uid + "-screen");
                }
                this.setState({ update: true });
            }
        }
    };

    updateStatus = (data) => {
        let user = this.props.users[data["from"]];
        if (user) {
            //todo refactor
            let userCopy = { ...user };
            userCopy = setControls(data.status, userCopy, this);
            this.props.addUser(userCopy);
            this.setState({ update: !this.state.update });
        }
    };

    updateRoomStatus = (data, from) => {
        this.roomInfo.setRoomState(data, from);
        this.props.updateSetting({
            setting: "roomState",
            value: data,
        });
    };

    setAudioStream = (stream) => {
        const audioElem = document.createElement("audio");
        audioElem.setAttribute("autoplay", "autoplay");
        document.body.appendChild(audioElem);
        audioElem.srcObject = stream;
        audioElem.setSinkId("default");
    };

    setVideoStream = (stream) => {
        let user = this.props.users[stream.id];

        if (user) {
            if (user.uid.includes("-screen")) {
                const teacher = this.props.users[user.uid.split("-")[0]];
                if ((teacher.status >> STATUS.POLL) & 1) {
                    this.setLoading(true, this.props.t("teacherIsCreatingPoll"));
                } else {
                    this.setLoading(false);
                }
            }
            this.userStreams[stream.id] = stream;
            window.setTimeout(() => {
                let vtrack = stream.getVideoTracks()[0];
                if (user) {
                    this.props.updateUser({
                        uid: user.uid,
                        prop: "playing",
                        value: vtrack && 1,
                    });
                    try {
                        document.getElementById("video_" + stream.id).srcObject = stream;
                    } catch { }
                    this.setState({ update: true });
                }
            }, 500);
        }
    };

    startPeerConnection = () => {
        const { permissions } = this.props;
        this.peerConnection = new PCConnection(this);
        this.peerConnection.start();

        //**** this stream must be set before we send the subscribe message
        /* dummy stream should be set before subscribing to send the canvas stream  */
        if (permissions.shouldSendDummyStream) {
            let canvas = document.getElementById("vc_dummy_canvas");
            if (canvas) {
                // eslint-disable-next-line
                var ctx = canvas.getContext("2d");
                let stream = canvas.captureStream(0);
                if (stream && stream.getVideoTracks() && stream.getVideoTracks().length > 0) {
                    this.peerConnection.addTrack(SENDER.VIDEO, stream.getVideoTracks()[0]);
                }
            }
        }

        let msg = {
            type: WS_MSG_TYPE.SUBSCRIBE,
            uids: "",
            bitrate: !permissions.isHost ? BITRATE.LOW : this.props.video.currVideoQuality.bitrate,
            video: true,
            audio: true,
        };
        const wsMsg = {
            type: permissions.isViewer ? WS_MSG_TYPE.STREAM_RTC : WS_MSG_TYPE.RTC,
            msg: JSON.stringify(msg),
        };
        this.wsConnection.sendMsg(wsMsg);
    };

    checkSubscribe = () => {
        let subscribers = [];
        const { permissions } = this.props;
        //subscribe to presenter and presenter's screen
        if (this.props.videoroom.recScreenshare && !this.publisher.isSharingScreen) {
            const screen = this.props.users[this.props.videoroom.screenShareId];
            const user = screen && screen.user;
            if (user) {
                let userStateStr = user.uid + "-screen,";
                const user_data = this.props.users[user.uid];
                if (user_data && isPlaying(user_data)) {
                    userStateStr += user.uid + "-video,";
                }
                const userSubstates = userStateStr.split(",").filter((str) => {
                    return user.uid === str.split("-")[0];
                });
                const videoState = userSubstates.find((str) => str.split("-")[1] === "video");
                if (videoState && userStateStr !== this.prevUsrStateStr) {
                    this.prevUsrStateStr = userStateStr;
                    this.subscribe(); // subscribe to everyone if user turns on camera after sharing screen
                }
                subscribers.push(user.uid);
                const screen = this.props.users[user.uid + "-screen"];
                if (screen) {
                    if (subscribers.indexOf(screen.uid) === -1) subscribers.push(screen.uid);
                }
            }
        } else {
            //clear str if screenshare is stopped by presenter
            this.prevUsrStateStr = "";
        }

        if (permissions.isViewer) {
            //if utype is 3 subscribe to only presenter or presenter's screen
            const presenterId = this.props.presenter.presenterUid;
            const presenter = this.props.users[presenterId];
            if (presenter && isPlaying(presenter) && isPresenter(presenter)) {
                let index = subscribers.indexOf(presenterId);
                if (index === -1) {
                    subscribers.push(presenterId);
                }
            } else {
                const teacher = Object.values(this.props.users).find(
                    (o) => o.utype === 1 && !o.isScreenShare
                );
                if (teacher && isPlaying(teacher)) {
                    let index = subscribers.indexOf(teacher.uid);
                    if (index === -1) {
                        subscribers.push(teacher.uid);
                    }
                }
            }
        } else {
            //active speakers
            for (const uid of this.props.audio.frequentSpeakers) {
                const user = this.props.users[uid];
                if (user && isPlaying(user)) {
                    let index = subscribers.indexOf(uid);
                    if (index === -1) {
                        subscribers.push(uid);
                    }
                }
            }

            //visible users in video view
            let users = Object.values(this.props.users);
            for (const user of users) {
                let index = subscribers.indexOf(user.uid);
                if (index === -1 && user.uid !== this.props.videoroom.userId) {
                    let userElem = document.getElementById(`video_${user.uid}`);
                    if (userElem && isInViewPort(userElem) && isPlaying(user)) {
                        subscribers.push(user.uid);
                    }
                }
            }

            //pinned user
            let index = subscribers.indexOf(this.props.videoroom.pinnedSub);
            if (this.props.videoroom.pinnedSub) {
                const user = this.props.users[this.props.videoroom.pinnedSub];
                if (index === -1 && user && isPlaying(user)) {
                    subscribers.push(this.props.videoroom.pinnedSub);
                }
            }
        }
        //check if subscription need to be changed
        if (this.wsConnection.status === "OPEN") {
            let userStr = "";

            subscribers.sort();
            for (const uid of subscribers) {
                userStr += `${uid},`;
            }
            if (userStr !== "") {
                userStr = userStr.substr(0, userStr.length);
            }
            if (!this.publisher.isTeacher && this.props.video.stopAllVideos) {
                userStr = "-";
            }
            if (this.prevUserStr !== userStr) {
                this.subscribe(userStr);
                console.log("subscriber str", userStr);
            }
        }
    };

    subscribe = (userStr) => {
        const { permissions } = this.props;
        let msg = {
            type: WS_MSG_TYPE.SUBSCRIBE,
            uids: userStr,
            bitrate: !this.publisher.isTeacher
                ? BITRATE.LOW
                : this.props.videoroom.sendScreenshare
                    ? BITRATE.SHARE
                    : this.props.video.currVideoQuality.bitrate,
            video: this.props.video.camera,
            audio: this.props.audio.mic,
        };
        if (this.props.videoroom.sendScreenshare) {
            msg.sbitrate = BITRATE.SCREEN;
        }
        if (this.prevUserStr !== userStr) {
            this.prevUserStr = userStr;
            let rtcMsg = {
                type: permissions.isViewer ? WS_MSG_TYPE.STREAM_RTC : WS_MSG_TYPE.RTC,
                msg: JSON.stringify(msg),
            };
            if (permissions.isViewer) {
                rtcMsg.to = this.streamerId;
            }
            console.log("rtc subscribe msg", rtcMsg);
            this.wsConnection.sendMsg(rtcMsg);
        }
    };

    onPCConnected = () => {
        const { permissions } = this.props;

        let control = this.state.control;

        this.publisher.isPinned = this.props.videoroom.pinnedSub === this.props.videoroom.userId;

        this.publisher.isStreaming = (control >> STATUS.STREAMING) & 1;

        this.publisher.isHandraise = (control >> STATUS.HANDRAISE) & 1;

        this.publisher.isSharingScreen = (control >> STATUS.SCREENSHARE) & 1;

        let isUserPresenter;
        if (
            (permissions.isTeacher || this.publisher.isSharingScreen) &&
            (!this.props.presenter.presenterUid ||
                this.props.presenter.presenterUid === this.publisher.uid)
        ) {
            //if user is a teacher and there is no presenter in the class or user is the presenter
            isUserPresenter = true;
            this.makeMePresenter();
        }
        // const isRecording = (this.props.videoroom.roomState >> ROOM_STATE.RECORDING) & 1;
        // if (permissions.canSetRecordingStatus && !isRecording && this.props.videoroom.recording) {
        //     //if nobody is recording but recording state is ON
        //     this.startRecording();
        // }

        if (this.props.polls.pollLaunched) {
            this.stopPoll();
            this.cancelPoll();
        }

        if (this.publisher.isSharingScreen) {
            if (isUserPresenter || this.props.presenter.presenterUid === this.publisher.uid) {
                //if user is presenter even after reconnection, continue sending the streams
                let user = new User(
                    this.props.videoroom.userId + "-screen",
                    this.publisher.name + "-screen",
                    0,
                    true,
                    this,
                    this.state.utype
                );
                user.playing = true;
                user.user = this.publisher;
                this.setState({ update: !this.state.update });
                this.props.addUser(user);
                switch (this.props.videoroom.screenShareOption) {
                    case SHARE.WHITEBOARD: {
                        if (this.whiteboard) this.whiteboard.getWBCanvasStream();
                        break;
                    }
                    case SHARE.PDF: {
                        if (this.pdfShare) this.pdfShare.getPDFCanvasStream();
                        break;
                    }
                    case SHARE.SCREEN: {
                        const { screenStream } = webrtc;
                        if (
                            screenStream &&
                            screenStream.getVideoTracks() &&
                            screenStream.getVideoTracks().length > 0
                        ) {
                            this.userStreams[user.uid] = screenStream;
                            window.setTimeout(() => {
                                try {
                                    document.getElementById(
                                        "video_" + this.publisher.uid + "-screen"
                                    ).srcObject = screenStream;

                                    if (this.participantCount === 0)
                                        document.getElementById(
                                            "video_" + this.publisher.uid
                                        ).srcObject = this.userStreams[this.publisher.uid];
                                } catch { }
                            }, 200);
                            const track = screenStream.getVideoTracks()[0];
                            this.peerConnection.addTrack(SENDER.SCREEN, track);
                            this.subscribe();
                        }
                        break;
                    }
                    default:
                        break;
                }
            } else {
                //if you are not presenter after reconnection, stop sharing
                this.stopSharingIfPresenter();
            }
        }

        if (!this.setConnection) {
            //runs only once to initiate loops
            this.setConnection = true;

            this.subscribeInterval = window.setInterval(() => {
                this.checkSubscribe();
            }, 2000);

            this.checkMainVideoInterval = window.setInterval(() => {
                this.checkMainVideo();
                this.countEventDuration();
            }, 1000);

            this.state.eventManager.send_live_class_ongoing(this.props.videoroom.classId);

            this.eventInterval = window.setInterval(() => {
                this.state.eventManager.live_class_aggregated_data_duration(
                    Object.assign(
                        {
                            class_id: this.props.videoroom.classId,
                            session_id: this.props.videoroom.sessionId,
                        },
                        this.eventDuration
                    )
                );
                this.state.eventManager.live_class_aggregated_data_clicks(
                    Object.assign(
                        {
                            class_id: this.props.videoroom.classId,
                            session_id: this.props.videoroom.sessionId,
                        },
                        this.eventClicks.getClickData()
                    )
                );
                this.eventClicks.refresh();
            }, 5 * 60 * 1000);
        }
    };

    setRemoteDescription = (data) => {
        const { device } = this.props.videoroom;
        if (data.type === WS_MSG_TYPE.RTC) {
            let message = JSON.parse(data.msg);
            if (message.offer !== null) {
                this.peerConnection.pc
                    .setRemoteDescription({
                        type: WS_MSG_TYPE.OFFER,
                        sdp: message.offer,
                    })
                    .then(() => {
                        return this.peerConnection.pc.createAnswer();
                    })
                    .then((answer) => {
                        return this.peerConnection.pc.setLocalDescription(answer);
                    })
                    .then(() => {
                        this.peerConnection.sdpList.shift();
                        if (!this.peerConnection.init) {
                            this.peerConnection.init = true;
                            let control = this.state.control;
                            control = (1 << STATUS.RTC) | control;
                            control = device.mobile
                                ? control & ~(1 << STATUS.LANDSCAPE)
                                : (1 << STATUS.LANDSCAPE) | control;
                            control = (1 << STATUS.PRESENTER_ACCESS_SUPPORTED) | control;
                            this.sendStatus(control);
                        }
                    })
                    .catch((error) => {
                        // console.log(error);
                        this.onRestart();
                    });
            }
        }
    };

    switchCanvasShare = (type, file) => {
        if (!this.props.videoroom.sendScreenshare) {
            let fileURL = null;
            if (type === SHARE.PDF) {
                var mimeTypes = ["application/pdf"];
                // check for valid pdf
                if (mimeTypes.indexOf(file.type) === -1) {
                    return;
                }
                fileURL = URL.createObjectURL(file);
            }
            this.props.bulkUpdateSettings({
                sendScreenshare: true,
                screenShareOption: type,
                documentFile: file,
                sharedDocumentURL: fileURL,
            });
        } else {
            this.stopCanvasShare();
        }
    };

    startCanvasStream = (stream) => {
        if (!this.props.presenter.presenterUid) {
            this.makeMePresenter();
        }
        this.setState({
            prevVideoQuality: this.props.video.currVideoQuality,
        });
        this.props.setCurrentVideoQuality(VIDEO_OPTIONS[QUALITY.LOW]);
        let screen = new User(
            this.props.videoroom.userId + "-screen",
            `${this.publisher.name}'s screen`,
            0,
            true,
            this,
            this.state.utype
        );
        screen.playing = true;
        screen.user = this.publisher;
        this.publisher.isSharingScreen = true;
        this.userStreams[screen.uid] = stream;
        this.props.addUser(screen);
        this.setSelectedPin(false);
        this.setCanvasStream(stream);
        let control = this.state.control;
        control = (1 << STATUS.SCREENSHARE) | control;
        this.sendStatus(control);
        this.props.updateSetting({
            setting: "screenShareId",
            value: screen.uid,
        });

        if (this.props.videoroom.screenShareOption === SHARE.WHITEBOARD) {
            this.eventClicks.share_whiteboard_start.push(new Date().getTime());
        } else if (this.props.videoroom.screenShareOption === SHARE.PDF) {
            this.eventClicks.share_pdf_start.push(new Date().getTime());
        }
    };

    setCanvasStream = (stream) => {
        if (stream && stream.getVideoTracks() && stream.getVideoTracks().length > 0) {
            this.userStreams[this.props.videoroom.userId + "-screen"] = stream;
            window.setTimeout(() => {
                try {
                    document.getElementById("video_" + this.publisher.uid + "-screen").srcObject =
                        stream;

                    if (this.participantCount === 0)
                        document.getElementById("video_" + this.publisher.uid).srcObject =
                            this.userStreams[this.publisher.uid];
                } catch { }
            }, 200);
            let track = stream.getVideoTracks()[0];
            this.peerConnection.addTrack(SENDER.SCREEN, track);
            this.subscribe();
        }
    };

    stopCanvasShare = () => {
        if (
            this.state.prevVideoQuality &&
            this.state.prevVideoQuality.quality !== this.props.video.currVideoQuality.quality
        ) {
            this.props.setCurrentVideoQuality(this.state.prevVideoQuality);
            this.setState({
                prevVideoQuality: null,
            });
        }
        webrtc.stopScreenStream();
        let control = this.state.control;
        control = control & ~(1 << STATUS.SCREENSHARE);
        this.props.removeUser(this.props.videoroom.userId + "-screen");

        if (isPresenter(this.publisher)) {
            this.setSelectedPin(true, this.publisher.uid);
        } else {
            const presenter = this.props.users[this.props.presenter.presenterUid];
            if (presenter) {
                const screen = this.props.users[presenter.uid + "-screen"];
                this.setSelectedPin(true, screen ? screen.uid : presenter.uid);
            }
        }

        this.publisher.isSharingScreen = false;
        this.props.bulkUpdateSettings({
            shareScreenTab: false,
            screenShareOption: "none",
            sendScreenshare: false,
        });

        this.sendStatus(control);
        if (this.props.videoroom.screenShareOption === SHARE.WHITEBOARD) {
            this.eventClicks.share_whiteboard_end.push(new Date().getTime());
        } else if (this.props.videoroom.screenShareOption === SHARE.PDF) {
            this.eventClicks.share_pdf_end.push(new Date().getTime());
        }
    };

    startScreenshare = async () => {
        try {
            const { screenStream } = await webrtc.getScreenStream();
            if (!this.props.presenter.presenterUid) {
                this.makeMePresenter();
            }
            this.setState({
                prevVideoQuality: this.props.video.currVideoQuality,
            });
            this.props.setCurrentVideoQuality(VIDEO_OPTIONS[QUALITY.LOW]);
            webrtc.screenStream.oninactive = () => {
                if (this.props.videoroom.sendScreenshare) {
                    this.stopScreenShare();
                }
            };
            let screen = new User(
                this.props.videoroom.userId + "-screen",
                `${this.publisher.name}'s screen`,
                0,
                true,
                this,
                this.state.utype
            );
            screen.playing = true;
            screen.user = this.publisher;
            this.userStreams[screen.uid] = screenStream;
            this.props.addUser(screen);
            this.publisher.isSharingScreen = true;
            this.setSelectedPin(false);

            //handle share tab
            let track = screenStream.getVideoTracks()[0];
            let settings = track.getSettings();
            if (this.props.videoroom.screenShareOption && settings.displaySurface === "browser") {
                this.props.updateSetting({
                    setting: "showScreenshare",
                    value: true,
                });
                this.props.updateSetting({
                    setting: "shareScreenTab",
                    value: true,
                });
            }
            const finalTrack = webrtc.mergeAudioTracks();
            if (finalTrack) this.peerConnection.addTrack(SENDER.AUDIO, finalTrack);
            this.peerConnection.addTrack(SENDER.SCREEN, track);
            let control = this.state.control;
            control = (1 << STATUS.SCREENSHARE) | control;
            if (this.props.polls.pollType && !this.props.polls.pollLaunched) {
                //if poll is being created, show loading to students
                control = (1 << STATUS.POLL) | control;
            }
            this.props.bulkUpdateSettings({
                sendScreenshare: true,
                screenShareId: screen.uid,
            });
            this.sendStatus(control);
            window.setTimeout(() => {
                try {
                    document.getElementById("video_" + this.publisher.uid + "-screen").srcObject =
                        screenStream;

                    if (this.participantCount === 0)
                        document.getElementById("video_" + this.publisher.uid).srcObject =
                            this.userStreams[this.publisher.uid];
                } catch (e) {
                    console.log(e);
                }
            }, 200);
            this.eventClicks.share_screen_start.push(new Date().getTime());
        } catch (err) {
            console.log(err);
        }
    };

    stopScreenShare = () => {
        if (
            this.state.prevVideoQuality &&
            this.state.prevVideoQuality.quality !== this.props.video.currVideoQuality.quality
        ) {
            this.props.setCurrentVideoQuality(this.state.prevVideoQuality);
            this.setState({
                prevVideoQuality: null,
            });
        }
        this.props.updateSetting({
            setting: "showScreenshare",
            value: false,
        });
        this.publisher.isSharingScreen = false;
        this.props.removeUser(this.props.videoroom.userId + "-screen");
        //stop video tracks
        webrtc.stopScreenStream();
        const finalTrack = webrtc.mergeAudioTracks();
        if (finalTrack) this.peerConnection.addTrack(SENDER.AUDIO, finalTrack);

        let control = this.state.control;
        control = control & ~(1 << STATUS.SCREENSHARE);

        if (
            this.props.videoroom.sendScreenshare &&
            this.props.videoroom.screenShareOption === SHARE.SCREEN &&
            this.props.polls.pollType
        ) {
            control = control & ~(1 << STATUS.POLL);
        }

        this.eventClicks.share_screen_end.push(new Date().getTime());
        if (isPresenter(this.publisher)) {
            this.setSelectedPin(true, this.publisher.uid);
        } else {
            const presenter = this.props.users[this.props.presenter.presenterUid];
            if (presenter) {
                const screen = this.props.users[presenter.uid + "-screen"];
                this.setSelectedPin(true, screen ? screen.uid : presenter.uid);
            }
        }

        this.props.bulkUpdateSettings({
            shareScreenTab: false,
            screenShareOption: "none",
            sendScreenshare: false,
        });
        this.sendStatus(control);
    };

    switchScreenShare = (type) => {
        if (!this.props.videoroom.sendScreenshare) {
            this.startScreenshare();
            this.props.updateSetting({
                setting: "screenShareOption",
                value: type,
            });
        } else {
            this.stopScreenShare();
        }
    };

    receiveScreenshare = (screenId, name, from) => {
        let screen = new User(screenId, name, 0, true, this, this.props.users[from].utype);
        screen.user = this.props.users[from]; //keep a reference to the user who is sharing screen
        this.props.addUser(screen);
        this.props.updateSetting({
            setting: "showScreenshare",
            value: true,
        });
        this.setSelectedPin(true, screen.uid);
        this.props.video.stopAllVideos
            ? this.props.newToastAdder(
                creatToast(
                    NEW_TOAST_TYPES.GENERIC_TOAST,
                    `${getName(screen.user)} ${this.props.t("isSharingScreenDisableAudio")}`
                )
            )
            : this.setLoading(true, `${getName(screen.user)} ${this.props.t("isSharingScreen")}`);
        this.props.updateSetting({
            setting: "screenShareId",
            value: screenId,
        });
    };

    removeScreenshare = (screenId) => {
        this.setLoading(false);
        this.props.removeUser(screenId);
        this.props.updateSetting({
            setting: "showScreenshare",
            value: false,
        });
        const presenter = this.props.users[this.props.presenter.presenterUid];
        if (presenter) this.setSelectedPin(true, presenter.uid);
        else this.setSelectedPin(false);
    };

    handleScreenshare = (isSharing, screenId, name, from) => {
        //show/hide screenshare only if it is coming from a presenter
        if (!this.props.presenter.presenterUid || from === this.props.presenter.presenterUid) {
            isSharing ? this.receiveScreenshare(screenId, name, from) : this.removeScreenshare(screenId);
            this.props.updateSetting({
                setting: "recScreenshare",
                value: isSharing,
            });
        } else {
            if (!isSharing) {
                this.setLoading(false);
                this.props.removeUser(screenId);
                const presenter = this.props.users[this.props.presenter.presenterUid];
                if (presenter) {
                    const screen = this.props.users[presenter.uid + "-screen"];
                    this.setSelectedPin(true, screen ? screen.uid : presenter.uid);
                } else {
                    this.setSelectedPin(false);
                }
            }
        }
    };

    displayEndPage() {
        this?.setState({
            toast: this.props.closeClassRoom.endClassForSelf
                ? this.props.t("youLeftClassroom")
                : this.props.t("goLiveClassroomEndedButtonText"),
            showEndClassPage: true,
        });
    }

    sendMessageToParent = (isLive) => {
        const msg = {
            room_id: this.props.videoroom.classId,
            is_live: isLive,
        };
        console.log("send to parent", msg);
        window.parent.postMessage(msg, "*");
    };

    onPinAndUnpin = (user) => {
        if (user.isPinned) {
            //send event for unpin
            this.eventClicks.unpin.push({
                timestamp: new Date().getTime(),
                selectedUid: user.uid,
                selectedUserType: user.utype,
            });
        } else {
            //send event for pin
            this.eventClicks.pin.push({
                timestamp: new Date().getTime(),
                selectedUid: user.uid,
                selectedUserType: user.utype,
            });
        }
        this.setSelectedPin(!user.isPinned, user.uid);
    };

    onPinAndUnpinMainscreen = () => {
        if (this.props.videoroom.pinnedSub) {
            this.setSelectedPin(false);
        } else {
            this.setSelectedPin(true, this.props.videoroom.localVideoId);
        }
    };

    setSelectedPin = (status, uid) => {
        this.props.updateAllUsers({
            prop: "isPinned",
            value: false,
        }); //unpin everyone
        if (status) {
            this.props.updateUser({
                uid,
                prop: "isPinned",
                value: true,
            });
        }
        this.props.updateSetting({
            setting: "pinnedSub",
            value: status ? uid : "",
        });
    };

    selectUserAction = (user) => {
        //only mweb: select user to do some action
        if (this.props.videoroom.mbActionUser) {
            this.props.updateSetting({
                setting: "mbActionUser",
                value: this.props.videoroom.mbActionUser.uid === user.uid ? null : user,
            });
        } else {
            this.props.updateSetting({
                setting: "mbActionUser",
                value: user,
            });
        }
    };

    muteAll = () => {
        this.sendMessage({ type: WS_MSG_TYPE.MUTE_ALL });
        this.props.newToastAdder(
            creatToast(NEW_TOAST_TYPES.GENERIC_TOAST, "You have muted the students")
        );
        this.eventClicks.mute_all_enabled.push(new Date().getTime());
    };

    muteUser = (user) => {
        if (!user.mute) {
            this.sendMessage({ type: WS_MSG_TYPE.MUTE_ALL }, user.uid);
            this.props.newToastAdder(
                creatToast(NEW_TOAST_TYPES.GENERIC_TOAST, `You have muted ${getName(user)}`)
            );
        }
    };

    onClickRejoin = () => {
        let frames = window.parent.frames;
        const str = `videoroom/${this.props.videoroom.classId}/${this.props.videoroom.userId}/${this.props.videoroom.hotKey}`;
        let finalFrame = null;
        for (let i = 0; i < frames.length; i++) {
            try {
                let frameElem = frames[i].frameElement;
                const src = frameElem.src;
                if (src.includes(str)) {
                    finalFrame = frameElem;
                }
            } catch { }
        }
        try {
            if (finalFrame) {
                finalFrame.contentDocument.location.reload(true);
            } else {
                window.location.reload();
            }
        } catch { }
    };

    sendMessage = (msg, to = null) => {
        let message = {
            type: WS_MSG_TYPE.MESSAGE,
            text: JSON.stringify(msg),
        };
        if (to != null) {
            message.to = to;
        }
        if (this.wsConnection) {
            this.wsConnection.sendMsg(message);
        }
    };

    sendMessageToHosts = (msg) => {
        const hosts = Object.values(this.props.users).filter((user) => {
            return user.utype === 1 || user.utype === 11;
        });
        let message = {
            type: WS_MSG_TYPE.MESSAGE,
            text: JSON.stringify(msg),
        };
        hosts.forEach((host) => {
            message.to = host.uid;
            this.wsConnection.sendMsg(message);
        });
    };

    onReceiveChatMessage = (senderId, senderName, chatMessage) => {
        if (chatMessage) {
            let time = getChatTime();
            this.props.addChatMessage({
                senderId,
                senderName,
                chatMessage,
                time,
            });
        }
    };

    attemptPoll = (text, uid) => {
        if (this.props.users[uid]) {
            //set flag for teacher who launched the poll
            this.props.updateUser({
                uid,
                prop: "didLaunchPoll",
                value: true,
            });
        }
        if (this.props.permissions.canLaunchPoll) {
            this.cancelPoll();
            const { settings } = this.props.roomSettings;
            this.prevAudioSetting = settings[RS.IS_MIC_BLOCKED];
            this.prevChatSetting = settings[RS.IS_CHAT_BLOCKED];
            let users = Object.values(this.props.users);
            users.forEach((user) => {
                if (!user.isTeacher && !user.isScreenShare) {
                    this.notAnswered[user.uid] = {
                        from: user.uid,
                        name: user.name,
                    };
                }
            });
        }

        this.pollTimer = text.duration + 5; //give a timer of 3 over 5 seconds
        this.pollCountDown = 3;

        if (text.poll_type === POLL_TYPE.NEW) {
            this.props.updatePollSettings({
                showPollContainer: true,
                pollQuestion: text.question,
                optionsNum: text.options.length,
                pollOptions: text.options,
            });
        }

        let countDownTimer = window.setInterval(() => {
            if (this.pollCountDown > 0) {
                this.pollCountDown = this.pollCountDown - 1;
            }
            if (this.pollCountDown <= 0) {
                this.props.updatePollSettings({
                    showLeaderboard: false,
                    stuSelectedPoll: null,
                    pollType: text.poll_type,
                });
                window.clearInterval(countDownTimer);
            }
        }, 1670);

        let timer = window.setInterval(() => {
            if (this.pollTimer > 0) {
                this.pollTimer = this.pollTimer - 1;
                if (this.pollTimer === text.duration) {
                    const timerElem = document.getElementById("poll-timer");
                    timerElem && (timerElem.style.visibility = "visible");
                }
            } else {
                window.clearInterval(timer);
                const timerElem = document.getElementById("poll-timer");
                timerElem && (timerElem.style.visibility = "hidden");
            }
            this.setState({ update: !this.state.update });
        }, 1000);
        this.props.updatePollSettings({
            pollDuration: text.duration,
            pollLaunched: true,
        });
    };

    launchPoll = (pollData, question = null) => {
        this.publisher.didLaunchPoll = true;
        if (question !== null) {
            pollData.question = question;
        }
        this.sendMessage(pollData);
        let users = Object.values(this.props.users);
        users.forEach((user) => {
            if (!user.isTeacher && !user.isScreenShare) {
                this.notAnswered[user.uid] = {
                    from: user.uid,
                    name: user.name,
                };
            }
        });

        this.props.updatePollSettings({
            pollLaunched: true,
            pollResponses: [],
        });

        this.pollCountDown = 3;
        this.pollTimer = this.props.polls.pollDuration + 5; //give a timer of 3 over 5 seconds

        let countDownTimer = window.setInterval(() => {
            if (this.pollCountDown > 0) {
                this.pollCountDown = this.pollCountDown - 1;
            }
            if (this.pollCountDown <= 0) {
                this.pollLaunchedTime = new Date().getTime() + this.wsConnection.timestampMargin * 1000;
                const msg = {
                    type: WS_MSG_TYPE.LAUNCH_TIME,
                    [WS_MSG_TYPE.LAUNCH_TIME]: this.pollLaunchedTime,
                    answer: this.props.polls.correctAnswer,
                };
                this.sendMessage(msg);
                window.clearInterval(countDownTimer);
            }
        }, 1670);

        let timer = window.setInterval(() => {
            if (this.pollTimer > 0) {
                this.pollTimer = this.pollTimer - 1;
                if (this.pollTimer === this.props.polls.pollDuration) {
                    const timerElem = document.getElementById("poll-timer");
                    timerElem && (timerElem.style.visibility = "visible");
                }
                this.setState({ update: !this.state.update });
            } else {
                if (this.props.polls.pollLaunched && this.publisher.didLaunchPoll) {
                    window.setTimeout(() => {
                        this.publisher.didLaunchPoll = false;
                        if (!this.props.polls.pollFinished) this.sendAnswerAndWinners();
                    }, 1500);
                }
                window.clearInterval(timer);
                const timerElem = document.getElementById("poll-timer");
                timer && (timerElem.style.visibility = "hidden");
            }
        }, 1000);

        //TODO: handle settings while refactoring POLLS
        const { settings } = this.props.roomSettings;
        this.prevAudioSetting = settings[RS.IS_MIC_BLOCKED];
        this.prevChatSetting = settings[RS.IS_CHAT_BLOCKED];
        this.props.setStudentMicSetting(true);
        this.props.setStudentChatSetting(true);
        this.props.setSendMicSetting(!this.props.roomSettings.sendMicSetting);
        this.props.setSendChatSetting(!this.props.roomSettings.sendChatSetting);
    };

    cancelPoll = () => {
        this.notAnswered = {};
        this.pollLaunchedTime = "";
        this.pollTimer = 0;
        const timerElem = document.getElementById("poll-timer");
        timerElem && (timerElem.style.visibility = "hidden");
        const { canLaunchPoll } = this.props.permissions;

        this.props.updatePollSettings({
            pollResponses: [],
            pollOptions: ["", ""],
            optionsNum: 2,
            pollType: null,
            pollLaunched: false,
            pollFinished: false,
            pollDuration: null,
            showPollContainer: false,
            showStopPoll: false,
            pollQuestion: "",
            correctAnswer: canLaunchPoll ? null : this.props.polls.correctAnswer,
            stuSelectedPoll: canLaunchPoll ? null : this.props.polls.stuSelectedPoll,
        });

        this.setState();
    };

    sendPollLoading = (isLoad) => {
        let control = this.state.control;
        let pollbit = STATUS.POLL;
        control = isLoad ? (1 << pollbit) | control : control & ~(1 << pollbit);
        this.sendStatus(control);
    };

    restoreSettingsAfterPoll = () => {
        //TODO: handle settings while refactoring POLLS
        this.props.setStudentMicSetting(this.prevAudioSetting);
        this.props.setStudentChatSetting(this.prevChatSetting);
        this.props.setSendMicSetting(!this.props.roomSettings.sendMicSetting);
        this.props.setSendChatSetting(!this.props.roomSettings.sendChatSetting);

        // for (const user of Object.values(this.handRaiseMap)) {
        //     user.allowToSpeakEnabled = false;
        // }
        //TODO: handle this while refactoring POLLS
        this.props.updateAllUsers({
            prop: "allowToSpeakEnabled",
            value: false,
        });
    };

    onTeacherStopPoll = () => {
        this.publisher.didLaunchPoll = false;
        this.sendMessage({ type: WS_MSG_TYPE.STOP_POLL });
        window.setTimeout(() => {
            this.sendAnswerAndWinners();
        }, 1500);
        this.pollCountDown = 0;
        this.pollTimer = null;
        this.props.updatePollSettings({
            showStopPoll: false,
        });
    };

    stopPoll = (uid) => {
        console.log("stop poll", uid);
        if (this.props.users[uid]) {
            this.props.updateUser({
                uid,
                prop: "didLaunchPoll",
                value: false,
            });
        }
        this.pollCountDown = 0;
        this.pollTimer = null;
        this.props.updatePollSettings({
            showStopPoll: false,
            pollFinished: true,
        });
    };

    sendAnswerAndWinners = () => {
        this.restoreSettingsAfterPoll();
        this.winners = this.props.polls.pollResponses.filter(
            (o) => o.answer === this.props.polls.correctAnswer
        );
        let winners = this.props.polls.pollResponses.reduce((w, o) => {
            if (o.answer === this.props.polls.correctAnswer) {
                let user = {
                    uid: o.from,
                    time: o.time,
                    name: o.name,
                };
                w.push(user);
            }
            return w;
        }, []);

        let msg = {
            type: WS_MSG_TYPE.POLL_WINNERS,
            winners: winners,
            answer: this.props.polls.correctAnswer,
        };
        this.sendMessage(msg);
        this.props.updatePollSettings({
            pollFinished: true,
            showPollContainer: false,
            showLeaderboard: true,
        });
    };

    getWinners = (text) => {
        let winners = text.winners;
        this.winners = [];
        for (const w of winners) {
            if (w.uid === this.props.videoroom.userId) {
                this.props.updatePollSettings({ stuSelectedPoll: text.answer });
            }
            this.winners.push({
                name: w.name,
                from: w.uid,
                time: w.time,
            });
        }
        this.props.updatePollSettings({
            pollFinished: true,
            correctAnswer: text.answer,
            showLeaderboard: true,
        });

        window.setTimeout(() => {
            if (this.props.permissions.canParticipateInPoll) {
                this.cancelPoll();
            } else {
                this.props.updatePollSettings({
                    showPollContainer: false,
                });
            }
        }, 5000);
    };

    hideLeaderboard = () => {
        if (this.props.videoroom.userId.includes("hidden_")) {
            window.setTimeout(() => {
                this.props.updatePollSettings({
                    showLeaderboard: false,
                });
            }, 5000);
        }
    };

    evaluatePoll = (data, from, name) => {
        let timestamp = new Date().getTime() + this.wsConnection.timestampMargin * 1000;
        let time = Math.floor((timestamp - this.pollLaunchedTime) / 100) / 10;
        let { pollResponses } = this.props.polls;
        delete this.notAnswered[from];
        let obj = {
            from: from,
            time: time,
            name: name,
            answer: data.answer,
        };
        pollResponses.push(obj);
        this.props.updatePollSettings({
            pollResponses: [...pollResponses],
        });
    };

    sendOrientation = () => {
        let control = this.state.control;
        control = this.props.videoroom.device.mobile
            ? control & ~(1 << STATUS.LANDSCAPE)
            : (1 << STATUS.LANDSCAPE) | control;
        if (control !== this.state.control) {
            this.sendStatus(control);
        }
    };

    updateDevice = (flag) => {
        let width = window.innerWidth;
        const device = {
            mobile: width <= 600,
            tablet: width > 600 && width <= 900,
            laptop: width > 990,
        };
        this.props.updateSetting({
            setting: "device",
            value: device,
        });
        if (this.peerConnection && this.peerConnection.init) {
            this.sendOrientation();
        }

        webrtc.setVideoDimensions();

        const localVideo = document.getElementById("local_video");
        if (localVideo) {
            localVideo.disablePictureInPicture = !device.laptop;
        }
        this.setState({ update: !this.state.update });
        if (flag && this.props.permissions.canViewUserTiles) {
            window.setTimeout(() => {
                let users = Object.values(this.props.users);
                for (const user of users) {
                    let video = document.getElementById("video_" + user.uid);
                    if (video) video.srcObject = this.userStreams[user.uid];
                }
                this.setState({ update: !this.state.update });
            }, 500);
        }
    };

    clearIntervals = () => {
        //end all loops
        if (this.checkMainVideoInterval) {
            window.clearInterval(this.checkMainVideoInterval);
        }
        if (this.subscribeInterval) {
            window.clearInterval(this.subscribeInterval);
        }
        if (this.eventInterval) {
            window.clearInterval(this.eventInterval);
        }
        if (this.roomInfoInterval) {
            window.clearInterval(this.roomInfoInterval);
        }
        //stop all tracks
        webrtc.stopAllTracks();
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.videoroom.gotoVideoroom) {
            //check if user turned on the mic atleast one to ask for feedback on end of the class
            if (this.props.audio.mic && !this.state.micOnAtleastOnce) {
                this.setState({ micOnAtleastOnce: true });
            }

            if (this.props.video.currVideoQuality.quality !== prevProps.video.currVideoQuality.quality) {
                this.setState({
                    prevVideoQuality: prevProps.video.currVideoQuality,
                });
            }

            //check if class is recorded atleast once to redirect to content sharing page
            if (this.props.videoroom.recording && !this.props.videoroom.recordedAtleastOnce) {
                this.props.updateSetting({
                    setting: "recordedAtleastOnce",
                    value: true,
                });
            }

            //check if whiteboard is saved atleast once to redirect to content sharing page
            if (this.props.videoroom.showSaveWB && !this.props.videoroom.savedWhiteBoardAtleastOnce) {
                this.props.updateSetting({
                    setting: "savedWhiteBoardAtleastOnce",
                    value: true,
                });
            }

            //should send subscribe after setting sendScreenshare to send sbitrate in subscribe msg
            if (
                this.props.videoroom.sendScreenshare &&
                this.props.videoroom.sendScreenshare !== prevProps.videoroom.sendScreenshare
            ) {
                this.subscribe();
            }

            // if (this.props.videoroom.recording !== prevProps.videoroom.recording) {
            //     if (this.props.videoroom.recording) {
            //         //if user started recording and nobody is presenter, user becomes presenter
            //         if (!this.props.presenter.presenterUid && this.publisher.isRecording) {
            //             this.makeMePresenter();
            //         } else if (isPresenter(this.publisher)) {
            //             //if user is presenter, set recording bit to true
            //             let control = this.state.control;
            //             control = (1 << STATUS.RECORDING) | control;
            //             this.sendStatus(control);
            //         }
            //     }
            // }

            if (
                this.props.polls.pollLaunched &&
                this.props.polls.pollLaunched !== prevProps.polls.pollLaunched &&
                this.props.videoroom.sendScreenshare
            ) {
                this.sendPollLoading(false);
            }

            if (
                this.props.polls.showLeaderboard &&
                this.props.polls.showLeaderboard !== prevProps.polls.showLeaderboard
            ) {
                this.hideLeaderboard();
            }
        }
        //fetch room info for first time

        if (
            this.props.videoroom.userId &&
            this.props.videoroom.classId &&
            (this.props.videoroom.userId !== prevProps.videoroom.userId ||
                this.props.videoroom.classId !== prevProps.videoroom.classId)
        ) {
            this.getRoomInfo();
            if (
                this.props.videoroom.device.laptop &&
                !(this.props.videoroom.companyId === COMPANY.PLANETSPARK)
            ) {
                this.props.updateSetting({
                    setting: "selectOption",
                    value: BOP.CHAT,
                });
            }
        }
        if(this.props.videoroom.isInternetUnstable !== prevProps.videoroom.isInternetUnstable){
            if(this.props.videoroom.isInternetUnstable){
                const data_to_send = {
                    user_id:this.props.videoroom.userId,
                    class_id:this.props.videoroom.classId,
                    session_id:this.props.videoroom.sessionId,
                }
                this.state.eventManager.network_unstable(data_to_send)
            }
        }
    }

    componentDidMount = () => {
        this.getUserInfo();
        this.updateDevice();
        window.addEventListener("resize", () => {
            this.updateDevice(true);
        });
        window.addEventListener("click", () => {
            const { isPopOpened, closeAllPopups } = store.getState().modals;
            if (!closeAllPopups && isPopOpened) {
                this.props.updateCloseAllPopups(true);
                window.setTimeout(() => {
                    this.props.updateCloseAllPopups(false);
                    this.props.updateIsPopupOpened(false);
                }, 20);
            }
        });
    };

    getUserInfo = () => {
        const { ...params } = this.props.match.params;
        console.log(params);
        this.props.bulkUpdateSettings({
            userId: params.userId,
            localVideoId: params.userId,
            classId: params.roomId,
            companyId: params.roomId.split(":")[0],
            hotKey: params.hotKey,
        });
        this.setState({
            eventManager: new EventManager(params.roomId, params.userId, params.hotKey),
        });
    };

    checkClassIsLive = () => {
        this.getRoomInfo();
        this.roomInfoInterval = window.setInterval(() => {
            this.getRoomInfo();
        }, 10 * 1000);
    };

    getRoomInfo = () => {
        this.setLoading(true);
        utilsGetRoomInfo(
            this.props.videoroom.classId,
            this.props.videoroom.userId,
            this.props.videoroom.hotKey
        )
            .then((data) => {
                const classInfo = data.data;
                this.props.updateSetting({
                    setting: "classInfo",
                    value: classInfo,
                });
                const { utype, settings, lang } = classInfo;
                const permissions = getPermissions(utype);
                this.props.updatePermissions(permissions);
                for (const s in settings) {
                    this.props.updateRoomSetting({
                        setting: s,
                        value: settings[s],
                    });
                }
                if (permissions.shouldCheckClassIsLive) {
                    this.setLoading(false);
                }
                // set application language, query param `lng` should be given priority over client level config
                const { lng } = queryString.parse(window.location.search);
                const app_lang = lng ? lng : lang;
                if (app_lang) {
                    this.props.i18n.changeLanguage(app_lang);
                }
                this.currentName = classInfo.uname;
                this.setState({
                    eVaaSurl: classInfo.url,
                    utype: parseInt(utype, 10),
                });
                if (permissions.isHost) {
                    let control = this.state.control;
                    if (permissions.isTeacher) {
                        control = (1 << STATUS.TEACHER) | control;
                    }
                    const { settings } = this.props.roomSettings;
                    this.props.setMicStatus(settings[RS.IS_MY_MIC_ON]);
                    this.props.setCameraStatus(settings[RS.IS_MY_CAMERA_ON]);
                    this.props.setIsUserFacingCamera(settings[RS.IS_FRONT_CAMERA]);
                    this.props.bulkUpdateSettings({
                        recording: settings[RS.IS_PREVIEW_RECORDING_ON],
                        classStatus: true,
                    });
                    this.setState({
                        control: control,
                    });
                } else if (
                    !this.props.videoroom.classStatus &&
                    classInfo.is_live &&
                    this.props.videoroom.gotoVideoroom
                ) {
                    window.clearInterval(this.roomInfoInterval);
                    this.startWSConnection();
                    this.props.setCameraStatus(false);
                    this.props.bulkUpdateSettings({
                        classStatus: true,
                    });
                    this.props.setMicStatus(false);
                }
                console.log("user data", data);
            })
            .catch((err) => {
                if (err && err.errorMsg === ERROR.ROOMINFO) {
                    this.setState({
                        redirect: true,
                        redirectTo: "error",
                        errorType: ERROR.ROOMINFO,
                    });
                }
                window.clearInterval(this.roomInfoInterval);
            });
    };

    showLoadingDuringPoll = () => {
        const users = Object.values(this.props.users);
        return users.find((user) => {
            return (
                user.uid !== this.props.videoroom.userId &&
                (user.status >> STATUS.SCREENSHARE) & 1 &&
                (user.status >> STATUS.POLL) & 1
            );
        });
    };

    getPresenter = () => {
        let presenter = null;
        if (this.props.presenter.presenterUid) {
            presenter = this.props.users[this.props.presenter.presenterUid];
        }
        return presenter;
    };

    render() {
        let users = Object.values(this.props.users);
        let localVideo = this.props.users[this.props.videoroom.localVideoId];
        let teacherCount = users.filter(
            (o) => !o.isScreenShare && (o.utype === 1 || o.utype === 11)
        ).length;
        let studentCount = this.participantCount - teacherCount + 1;
        let noStudentView = this.participantCount === 0 && !this.props.videoroom.sendScreenshare;
        let { permissions } = this.props;
        let { device } = this.props.videoroom;
        let showPresentingScreen =
            this.publisher.isSharingScreen &&
            !this.props.videoroom.shareScreenTab &&
            this.props.videoroom.screenShareOption === SHARE.SCREEN &&
            this.props.videoroom.sendScreenshare;

        let showLoadingDuringPoll = this.showLoadingDuringPoll();

        let isPresenterUser = this.props.presenter.presenterUid === this.props.videoroom.userId;
        let presenter = this.getPresenter();

        if (this.state.redirect) {
            return (
                <Redirect
                    to={{
                        pathname: `/${this.state.redirectTo}`,
                        state: {
                            errorType: this.state.errorType,
                            videoroomPath: window.location.pathname,
                        },
                    }}
                />
            );
        }

        return (
            <>
                <MediaPermissions webrtc={webrtc} setLoading={this.setLoading} />
                <RoomSettings
                    sendMessage={this.sendMessage}
                    startWSConnection={this.startWSConnection}
                />
                <Close
                    location={this.props.location}
                    publisher={this.publisher}
                    clearIntervals={this.clearIntervals}
                    displayEndPage={this.displayEndPage.bind(this)}
                />
                <Presenter
                    control={this.state.control}
                    sendStatus={this.sendStatus}
                    sendRoomMeta={this.sendRoomMeta}
                    setSelectedPin={this.setSelectedPin}
                    publisher={this.publisher}
                    stopSharingIfPresenter={this.stopSharingIfPresenter}
                />
                <BufferStream webrtc={webrtc} peerConnection={this.peerConnection} />
                <Audio
                    webrtc={webrtc}
                    control={this.state.control}
                    sendStatus={this.sendStatus}
                    sendMessageToHosts={this.sendMessageToHosts}
                    peerConnection={this.peerConnection}
                    wsConnection={this.wsConnection}
                    subscribe={this.subscribe}
                />

                <Video
                    webrtc={webrtc}
                    control={this.state.control}
                    userStreams={this.userStreams}
                    peerConnection={this.peerConnection}
                    wsConnection={this.wsConnection}
                    sendStatus={this.sendStatus}
                    subscribe={this.subscribe}
                />

                <Chat />

                {permissions.isStudent ? (
                    <HandRaiseStudent control={this.state.control} sendStatus={this.sendStatus} />
                ) : null}

                {permissions.canSetRecordingStatus ? (
                    <Recording
                        sendStatus={this.sendStatus}
                        eventClicks={this.eventClicks}
                        control={this.state.control}
                    />
                ) : null}

                {!this.state.showEndClassPage ? (
                    this.props.videoroom.gotoVideoroom ? (
                        <>
                            {device.laptop ? (
                                <Suspense fallback={<Loader show={true} text={"Loading Videoroom"} />}>
                                    <WebVC
                                        ref={(c) => (this.webVC = c)}
                                        loading={this.state.loading}
                                        loaderText={this.state.loaderText}
                                        showLoadingDuringPoll={showLoadingDuringPoll}
                                        onTeacherStopPoll={this.onTeacherStopPoll}
                                        sendMessage={this.sendMessage}
                                        eventClicks={this.eventClicks}
                                        t={this.props.t}
                                        sendLiveStatus={this.sendLiveStatus}
                                        bulkUpdateSettings={this.props.bulkUpdateSettings}
                                        toast={this.state.toast}
                                        participantCount={this.participantCount}
                                        muteUser={this.muteUser}
                                        onPinAndUnpin={this.onPinAndUnpin}
                                        selectUserAction={this.selectUserAction}
                                        presenter={presenter}
                                        notAnswered={Object.values(this.notAnswered)}
                                        launchPoll={this.launchPoll}
                                        cancelPoll={this.cancelPoll}
                                        updatePollSettings={this.props.updatePollSettings}
                                        sendPollLoading={this.sendPollLoading}
                                        noStudentView={noStudentView}
                                        expanded={this.state.expanded}
                                        playing={this.playing}
                                        shouldReflect={this.shouldReflect}
                                        userStreams={this.userStreams}
                                        pollTimer={this.pollTimer}
                                        setToastMessage={this.setToastMessage}
                                        localVideo={localVideo}
                                        currentName={this.currentName}
                                        showPresentingScreen={showPresentingScreen}
                                        switchScreenShare={this.switchScreenShare}
                                        onPinAndUnpinMainscreen={this.onPinAndUnpinMainscreen}
                                        deleteToast={this.deleteToast}
                                        toastList={Object.values(this.toastList)}
                                        pollCountDown={this.pollCountDown}
                                        winners={this.winners}
                                        setLoading={this.setLoading}
                                        startRecording={this.startRecording}
                                        stopRecording={this.stopRecording}
                                        muteAll={this.muteAll}
                                        studentCount={studentCount}
                                        switchCanvasShare={this.switchCanvasShare}
                                        videoroomRef={this}
                                        overridePresenterAndShare={this.overridePresenterAndShare}
                                        replaceVideoTracks={this.replaceVideoTracks}
                                        startCanvasStream={this.startCanvasStream}
                                        setCanvasStream={this.setCanvasStream}
                                        whiteboard={this.whiteboard}
                                        pdfShare={this.pdfShare}
                                        stopCanvasShare={this.stopCanvasShare}
                                        sendStatus={this.sendStatus}
                                        sendRoomMeta={this.sendRoomMeta}
                                        publisher={this.publisher}
                                        control={this.state.control}
                                        peerConnection={this.peerConnection}
                                    />
                                </Suspense>
                            ) : (
                                <Suspense fallback={<Loader show={true} text={"Loading Videoroom"} />}>
                                    <MobileVC
                                        ref={(c) => (this.mobileVC = c)}
                                        toast={this.state.toast}
                                        pollTimer={this.pollTimer}
                                        studentCount={studentCount}
                                        onPinAndUnpinMainscreen={this.onPinAndUnpinMainscreen}
                                        setToastMessage={this.setToastMessage}
                                        playing={this.playing}
                                        shouldReflect={this.shouldReflect}
                                        noStudentView={noStudentView}
                                        localVideo={localVideo}
                                        currentName={this.currentName}
                                        showLoadingDuringPoll={showLoadingDuringPoll}
                                        loading={this.state.loading}
                                        loaderText={this.state.loaderText}
                                        onTeacherStopPoll={this.onTeacherStopPoll}
                                        sendMessage={this.sendMessage}
                                        eventClicks={this.eventClicks}
                                        sendLiveStatus={this.sendLiveStatus}
                                        expanded={this.state.expanded}
                                        toastList={Object.values(this.toastList)}
                                        deleteToast={this.deleteToast}
                                        launchPoll={this.launchPoll}
                                        cancelPoll={this.cancelPoll}
                                        pollCountDown={this.pollCountDown}
                                        winners={Object.values(this.winners)}
                                        notAnswered={Object.values(this.notAnswered)}
                                        muteUser={this.muteUser}
                                        onPinAndUnpin={this.onPinAndUnpin}
                                        selectUserAction={this.selectUserAction}
                                        startRecording={this.startRecording}
                                        stopRecording={this.stopRecording}
                                        videoroomRef={this}
                                        muteAll={this.muteAll}
                                        setLoading={this.setLoading}
                                        t={this.props.t}
                                        sendStatus={this.sendStatus}
                                        sendRoomMeta={this.sendRoomMeta}
                                        publisher={this.publisher}
                                        control={this.state.control}
                                        peerConnection={this.peerConnection}
                                        userStreams={this.userStreams}
                                    />
                                </Suspense>
                            )}
                            <WarningModal
                                showModal={this.state.showWarning}
                                closeModal={() => {
                                    this.setState({ showWarning: false });
                                }}
                                icon={this.warningData.icon}
                                heading={this.warningData.heading}
                                content={this.warningData.content}
                                leftButton={this.warningData.leftBtnTxt}
                                rightButton={this.warningData.rightBtnTxt}
                                onLeftButtonClick={this.warningData.leftBtnAction}
                                onRightButtonClick={this.warningData.rightBtnAction}
                                force={this.warningData.force}
                            />
                            <SystemStats pcConn={this.peerConnection?.pc} eventManager={this.state.eventManager} />
                            {this.props.videoroom.showExitWB && presenter && (
                                <ConfirmationModal
                                    icon={ModalExitWhiteboard}
                                    title={`${isPresenterUser
                                        ? this.props.t("goLiveTeacherDashboardExitWhiteboardText")
                                        : getName(presenter) + " is the presenter now"
                                        }`}
                                    confirmText={this.props.t("saveExit")}
                                    cancelText={this.props.t("closeAnyway")}
                                    onCancel={() => {
                                        // this.setState({ showExitWB: false });
                                        this.props.updateSetting({
                                            setting: "showExitWB",
                                            value: false,
                                        });
                                        this.switchCanvasShare();
                                        if (!this.props.videoroom.classStatus)
                                            this.props.updateEndClassSettings({ saveAndClose: true });
                                        // this.closeClassroom();
                                    }}
                                    onConfirm={() => {
                                        this.props.updateSetting({
                                            setting: "showExitWB",
                                            value: false,
                                        });
                                        if (this.whiteboard) {
                                            this.whiteboard.saveWhiteboard();
                                            window.setTimeout(() => {
                                                this.switchCanvasShare();
                                            }, 1500);

                                            if (!this.props.videoroom.classStatus)
                                                window.setTimeout(() => {
                                                    this.props.updateEndClassSettings({
                                                        saveAndClose: true,
                                                    });
                                                    // this.closeClassroom();
                                                }, 3000);
                                        }
                                    }}
                                >
                                    <span> {this.props.t("saveWhiteboardBeforeExiting")} </span>
                                </ConfirmationModal>
                            )}
                            {this.props.videoroom.showExitPdf && presenter && (
                                <ConfirmationModal
                                    icon={ModalExitWhiteboard}
                                    title={`${isPresenterUser
                                        ? this.props.t("exitPdfText")
                                        : getName(presenter) + " is the presenter now"
                                        }`}
                                    confirmText={this.props.t("saveExit")}
                                    cancelText={this.props.t("closeAnyway")}
                                    onCancel={() => {
                                        // this.setState({ showExitWB: false });
                                        this.props.updateSetting({
                                            setting: "showExitPdf",
                                            value: false,
                                        });
                                        this.switchCanvasShare();
                                        if (!this.props.videoroom.classStatus)
                                            this.props.updateEndClassSettings({ saveAndClose: true });
                                        // this.closeClassroom();
                                    }}
                                    onConfirm={() => {
                                        this.props.updateSetting({
                                            setting: "showExitPdf",
                                            value: false,
                                        });
                                        this.props.updateSetting({
                                            setting: "savePdfInProcess",
                                            value: true,
                                        });

                                        window.setTimeout(() => {
                                            this.switchCanvasShare();
                                        }, 1500);

                                        if (!this.props.videoroom.classStatus)
                                            window.setTimeout(() => {
                                                this.props.updateEndClassSettings({
                                                    saveAndClose: true,
                                                });
                                                // this.closeClassroom();
                                            }, 3000);
                                    }}
                                >
                                    <span> {this.props.t("savePdfBeforeExit")} </span>
                                </ConfirmationModal>
                            )}
                            {this.state.showPermissionModal ? (
                                <ConfirmationModal
                                    icon={GreenLock}
                                    title={"Camera and Microphone Permission"}
                                    confirmText="Okay"
                                    contentClass={device.mobile ? "" : "wide"}
                                    onConfirm={() => {
                                        this.setState({ showPermissionModal: false });
                                    }}
                                >
                                    <span>
                                        To access your camera and microphone, click the lock icon on your
                                        browser’s address bar and give access to camera and mic
                                    </span>
                                    <img
                                        alt="icon"
                                        style={{ marginTop: "20px" }}
                                        src={
                                            device.mobile ? PermissionSnapshotMobile : PermissionSnapshot
                                        }
                                    />
                                </ConfirmationModal>
                            ) : null}
                        </>
                    ) : (
                        <>
                            {permissions.showTeacherPreview ? (
                                <GoLivePreview webrtc={webrtc} loading={this.state.loading} />
                            ) : null}

                            {permissions.showStudentPreview ? (
                                <StudentPreview
                                    loading={this.state.loading}
                                    checkClassIsLive={this.checkClassIsLive}
                                    sendLiveStatus={this.sendLiveStatus}
                                />
                            ) : null}
                        </>
                    )
                ) : (
                    <EndClass
                        endClassTxt={
                            this.props.closeClassRoom.endClassForSelf
                                ? "You left the classroom"
                                : "Classroom ended"
                        }
                        onClickRejoin={this.onClickRejoin}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        users: state.users,
        videoroom: state.videoroom,
        permissions: state.permissions,
        polls: state.polls,
        roomSettings: state.roomSettings,
        chat: state.chat,
        presenter: state.presenter,
        audio: state.audio,
        video: state.video,
        modals: state.modals,
        closeClassRoom: state.closeClassRoom,
    };
};

const mapDispatchToProps = {
    addUser: userActions.addUser,
    removeUser: userActions.removeUser,
    updateAllUsers: userActions.updateAllUsers,
    resetUsers: userActions.resetUsers,
    updateUser: userActions.updateUser,
    setMicStatus: audioActions.setMicStatus,
    setLastActiveSpeakerUid: audioActions.setLastActiveSpeakerUid,
    updateSpeakerStatus: audioActions.updateSpeakerStatus,
    setFrequentSpeakers: audioActions.setFrequentSpeakers,
    setAudioDevices: audioActions.setAudioDevices,
    setCurrentAudioDevice: audioActions.setCurrentAudioDevice,
    setCameraStatus: videoActions.setCameraStatus,
    setCurrentVideoQuality: videoActions.setCurrentVideoQuality,
    setCurrentVideoDevice: videoActions.setCurrentVideoDevice,
    setIsUserFacingCamera: videoActions.setIsUserFacingCamera,
    setVideoDevices: videoActions.setVideoDevices,
    updateSetting: videoroomActions.updateSetting,
    bulkUpdateSettings: videoroomActions.bulkUpdateSettings,
    updatePermissions: permissionActions.updatePermissions,
    updatePollSettings: pollActions.bulkUpdateSettings,
    updateRoomSetting: roomSettingActions.updateRoomSetting,
    setStudentMicSetting: roomSettingActions.setStudentMicSetting,
    setStudentVideoSetting: roomSettingActions.setStudentVideoSetting,
    setStudentChatSetting: roomSettingActions.setStudentChatSetting,
    setSendMicSetting: roomSettingActions.setSendMicSetting,
    setSendChatSetting: roomSettingActions.setSendChatSetting,
    newToastAdder: toastActions.addToast,
    addChatMessage: chatActions.addChatMessage,
    updatePresenterUid: presenterActions.updatePresenterUid,
    updatedSelectedPresenterID: presenterActions.updatedSelectedPresenterID,
    updateCloseAllPopups: updateCloseAllPopups,
    updateIsPopupOpened: updateIsPopupOpened,
    updateEndClassSettings: closeActions.updateEndClassSettings,
    addObserverUser: observerUserAction.addUser,
    removeObserverUser: observerUserAction.removeUser
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Videoroom));
