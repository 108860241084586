const initalState = {};

export function permissionsReducer(state = initalState, { type, payload }) {
    let permissions;
    switch (type) {
        case "UPDATE_PERMISSIONS":
            permissions = payload
            return permissions;
        default:
            return state;
    }
}