export const peerConnectionCredentials = {
    iceServers: [
        {
            urls: 'turns:turn1.teachmint.com:443?transport=tcp',
            username: 'teachmint',
            credential: 'teachmint123'
        }
    ],
    sdpSemantics: 'unified-plan'
}
