import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { STATUS, SENDER, WS_STATE } from "../../../constants/Enums";
import { useTranslation } from "react-i18next";
import { addUser, updateUser } from "../../../redux/actions/usersActions";
import { addToast } from "../../../redux/actions/toasts";
import { creatToast } from "../../containers/Toast/toastCreator";
import { NEW_TOAST_TYPES } from "../../../constants/Enums";
import { setCameraStatus } from "../../../redux/actions/videoActions";
import { updateSetting } from "../../../redux/actions/videoroomActions";

export default function Video({
    webrtc,
    control,
    userStreams,
    peerConnection,
    sendStatus,
    subscribe
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { camera, isUserFacingCam, stopAllVideos, currVideoQuality, currVideoDevice } = useSelector(
        (state) => state.video
    );
    const { userId, recScreenshare, screenShareId, PCCState, WSState } = useSelector((state) => state.videoroom);
    const [videoEnabled, setVideoEnabled] = useState(false);
    const users = useSelector((state) => state.users);

    useEffect(() => {
        if (WSState === WS_STATE.OPEN && PCCState === "connected") {
            camera ? sendVideoStream() : stopVideoStream();
            handleVideoStatus(camera);
        }
    }, [camera, isUserFacingCam, PCCState]);

    useEffect(() => {
        if (WSState === WS_STATE.OPEN) {
            handleStopAllVideos();
        }
    }, [stopAllVideos]);

    useEffect(() => {
        if (WSState === WS_STATE.OPEN) {
            webrtc.updateVideoQuality(currVideoQuality);
            subscribe();
        }
    }, [currVideoQuality.quality]);

    useEffect(() => {
        if (PCCState === "connected" && WSState === WS_STATE.OPEN) {
            // replace tracks only after connections are formed
            replaceVideoTracks(currVideoDevice);
        }
    }, [currVideoDevice.label]);

    const sendVideoStream = async () => {
        try {
            const { videoStream } = await webrtc.getVideoStream();
            webrtc.setMainVideo(videoStream);
            userStreams[userId] = videoStream;
            peerConnection.addTrack(SENDER.VIDEO, videoStream.getVideoTracks()[0]);
            if (!videoEnabled) {
                setVideoEnabled(true);
                subscribe();
            }
        } catch { }
    };

    const stopVideoStream = () => {
        webrtc.stopMainVideoTracks();
    };

    const replaceVideoTracks = async (device) => {
        try {
            if (camera) {
                const { videoStream } = await webrtc.getVideoStream();
                webrtc.replaceVideoTracks(videoStream);
                webrtc.setMainVideo(videoStream);
                userStreams[userId] = videoStream;
                peerConnection.addTrack(SENDER.VIDEO, videoStream.getVideoTracks()[0]);
            }
        } catch { }
    };

    const handleVideoStatus = (cameraState) => {
        dispatch(
            updateUser({
                uid: userId,
                prop: "playing",
                value: cameraState,
            })
        );
        sendVideoStatus(cameraState);
    };

    const sendVideoStatus = (cameraState) => {
        let newControl = control;
        let mask = 1 << STATUS.VIDEO;
        cameraState ? (newControl |= mask) : (newControl &= ~mask);
        sendStatus(newControl);
    };

    const handleStopAllVideos = () => {
        if (stopAllVideos) {
            if (camera) {
                dispatch(setCameraStatus(false));
            }
            if (recScreenshare) {
                const user = users[screenShareId];
                if (user) {
                    let name = user["user"]["name"];
                    name = `${name[0].toUpperCase()}${name.slice(1)}`;
                    showToastMessage(`${name} ${t("isSharingScreenDisableAudio")}`);
                }
            } else {
                showToastMessage(t("enabledAudioOnlyModeText"));
            }
        } else {
            showToastMessage(t("disabledAudioOnlyModeText"));
            if (recScreenshare) {
                //view someone else's sharing
                dispatch(
                    updateSetting({
                        setting: "showScreenshare",
                        value: false,
                    })
                );
            }
        }
    };

    const showToastMessage = (msg) => {
        dispatch(addToast(creatToast(NEW_TOAST_TYPES.GENERIC_TOAST, msg)));
    };

    return null;
}
