import React from "react";
import "./modal.scss";

const ConfirmationModal = (props) => {
    return (
        <div className="vc-modal-container center">
            <div className={`${props.contentClass} vc-modal-content`}>
                <div className="header">
                    {props.icon && <div className="center">
                        <img alt="icon" style={{ marginBottom: "10px" }} src={props.icon} />
                    </div>}
                    <div className="center title">
                        <span>{props.title}</span>
                    </div>
                </div>
                <div className="main-content">{props.children}</div>
                <div className="vc-modal-btn-area">
                    {props.cancelText && <button className="btn cancel-btn" onClick={props.onCancel}>
                        {props.cancelText}
                    </button>}
                    {props.confirmText && <button className="btn confirm-btn" onClick={props.onConfirm}>
                        {props.confirmText}
                    </button>}
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;