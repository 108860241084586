import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import Loader from "../components/Loader";
import TopbarVideoroom from '../containers/TopbarVideoroom/TopbarVideoroom';
import StudentIllust from "../images/student-preview.svg";
import {useTranslation} from "react-i18next";
import { updateSetting } from '../../redux/actions/videoroomActions';

export default function StudentPreview({
    loading,
    checkClassIsLive,
    sendLiveStatus
}){
    const dispatch = useDispatch()
    const { gotoVideoroom, device } = useSelector(state => state.videoroom)
    const isLaptop = device.laptop
    const {t} = useTranslation();

    return(
        <>
            <Loader show={loading && gotoVideoroom} />
            <div className='fullscreen' style={{backgroundColor: '#303a4c'}}>
                        <TopbarVideoroom
                            sendLiveStatus={sendLiveStatus}
                        />
                        <div className='center' style={{height: '70%'}}>
                            <div>
                                <div className='center'>
                                    <img alt='illustration' src={StudentIllust}
                                        style={{
                                            height: !isLaptop ? '150px' : 'initial',
                                            margin: !isLaptop ? '15px 0px' : '30px 0px'
                                        }}
                                    />
                                </div>
                                <div data-qa="txt-enter-classroom" style={{
                                    color: '#b6bdcc',
                                    fontSize: isLaptop ? '14px' : '12px', 
                                    padding: '10px', 
                                    textAlign: 'center'
                                }}>
                                    {t("goLiveButtonStudentDashboardText")}
                                </div>
                                <div className='center'>
                                    <button data-qa="btn-enter-classroom" className='enter-classroom' 
                                        onClick={()=>{  
                                            dispatch(updateSetting({
                                                setting: "gotoVideoroom",
                                                value: true
                                            }))
                                            checkClassIsLive()
                                        }}>
                                        {t("goLiveStudentDashboardEnterTheClassroomButtonText")}
                                    </button>
                                </div>
                            </div>
                        </div>
            </div>
        </>
    )
}