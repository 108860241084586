const initalState = {};


export function observerUserReducer(state = initalState, { type, payload }) {
    let user, users, prop, value, userId;
    switch (type) {
        case "OBSERVER_USER_ADDED":
            users = { ...state };
            user = payload;
            users[user.uid] = user;
            return users;
        case "OBSERVER_USER_REMOVED":
            users = { ...state };
            userId = payload.value;
            delete users[userId];
            return users;
        default:
            return state;

    }
}