export const YOUTUBE_STREAM_URL = "rtmp://a.rtmp.youtube.com/live2/";

export const ERROR = {
    ROOMINFO: "roominfo",
    WEBSOCKET: "websocket",
    YT_INTEGRATION: "youtube_integration",
    AUDIO_PERM: "audio_permission",
    VIDEO_PERM: "video_permission",
    SCREEN_PERM: "screen_permission",
};
Object.freeze(ERROR);

export const COMPANY = {
    TEACHMINT: "teachmint",
    EVAAS: "evaas",
    PLANETSPARK: "planetspark",
};
Object.freeze(COMPANY);

export const WS_STATE = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3,
};
Object.freeze(WS_STATE);

export const SENDER = {
    AUDIO: "audio",
    VIDEO: "video",
    SCREEN: "screen",
};
Object.freeze(SENDER);

export const STATUS = {
    RTC: 0,
    AUDIO: 1,
    VIDEO: 2,
    SPEAKING: 3,
    LANDSCAPE: 4,
    SCREENSHARE: 5,
    TEACHER: 6,
    RECORDING: 7,
    POLL: 8,
    HANDRAISE: 9,
    STREAMING: 10,
    PRESENTER: 11,
    PRESENTER_ACCESS_SUPPORTED: 12,
};
Object.freeze(STATUS);

export const ROOM_STATE = {
    RECORDING: 0,
    YOUTUBE: 1,
    STREAMING: 2,
};
Object.freeze(ROOM_STATE);

export const RS = {
    IS_MY_MIC_ON: "is_mic_on",
    IS_MY_CAMERA_ON: "is_video_on",
    IS_MIC_BLOCKED: "is_mute_blocked",
    IS_VIDEO_BLOCKED: "is_video_blocked",
    IS_CHAT_BLOCKED: "is_chat_blocked",
    IS_FRONT_CAMERA: "is_front_camera",
    IS_PORTRAIT_VC: "is_portrait_vc",
    IS_PREVIEW_RECORDING_ON: "is_recording_on",
    VIDEO_QUALITY: "video_quality",
};
Object.freeze(RS);

export const WS_MSG_TO = {
    RTC: "rtc",
    PLUGIN_RTMP: "plugin:rtmp",
};
Object.freeze(WS_MSG_TO);

export const ROOM_META = {
    ROOM_STATE: "room_state",
    PRESENTER_UID: "presenter_uid",
    RECORD_LOG: "recording_activity_log",
};
Object.freeze(ROOM_META);

export const RECORD_LOG = {
    START_TIME: "start_time",
    STOP_TIME: "stop_time",
    DURATION: "duration",
};
Object.freeze(RECORD_LOG);

export const WS_MSG_TYPE = {
    STATUS: "status",
    ROOM_META: "room_meta",
    SUBSCRIBE: "subscribe",
    HEARTBEAT: "heartbeat",
    OFFER: "offer",
    USERS: "users",
    RUSERS: "rusers",
    STREAM_RTC: "stream_rtc",
    RTC: "rtc",
    CLOSE_ALL: "close_all",
    START_RTMP: "start_rtmp",
    STOP_RTMP: "stop_rtmp",
    RTMP_FAILED: "rtmp_failed",
    MUTE_ALL: "mute_all",
    CHAT_MSG: "chat_msg",
    MESSAGE: "message",
    POLL_NEW: "poll_new",
    STOP_POLL: "stop_poll",
    POLL_WINNERS: "poll_winners",
    POLL_ANSWER: "poll_answer",
    HAND_DOWN: "hand_down",
    ALLOW_HAND: "allow_hand",
    MUTE_HAND: "mute_hand",
    BLOCK_AUDIO: "block_audio",
    BLOCK_VIDEO: "block_video",
    BLOCK_CHAT: "block_chat",
    UNBLOCK_AUDIO: "unblock_audio",
    UNBLOCK_VIDEO: "unblock_video",
    UNBLOCK_CHAT: "unblock_chat",
    WHITEBOARD_SAVED: "whiteboard_saved",
    PDF_SAVED:"pdf_saved",
    SESSION_INFO: "session_info",
    LAUNCH_TIME: "launch_time",
    STREAM_SUBSCRIBERS: "stream_subscribers"

};
Object.freeze(WS_MSG_TYPE);

export const QUALITY = {
    HD: "HD",
    HIGH: "High",
    LOW: "Low"
};
Object.freeze(QUALITY);

export const BITRATE = {
    LOW: 192000,
    HIGH: 640000,
    HD: 960000,
    SHARE: 480000,
    SCREEN: 960000,
};
Object.freeze(BITRATE);

export const VIDEO_OPTIONS = {
    [QUALITY.HD]: {
        quality: QUALITY.HD,
        width: 1280,
        height: 720,
        mbWidth: 720,
        mbHeight: 1280,
        bitrate: BITRATE.HD,
    },
    [QUALITY.HIGH]: {
        quality: QUALITY.HIGH,
        width: 640,
        height: 480,
        mbWidth: 480,
        mbHeight: 640,
        bitrate: BITRATE.HIGH,
    },
    [QUALITY.LOW]: {
        quality: QUALITY.LOW,
        width: 320,
        height: 180,
        mbWidth: 180,
        mbHeight: 320,
        bitrate: BITRATE.LOW,
    },
};
Object.freeze(VIDEO_OPTIONS);

export const SHARE = {
    SCREEN: "none",
    WHITEBOARD: "whiteboard",
    PDF: "pdf",
};
Object.freeze(SHARE);

export const ALLOW_STUDENT = {
    AUDIO: {
        TEXT: "Allow students to speak",
        SUBTEXT: "Your students can turn their mic on and speak",
    },
    VIDEO: {
        TEXT: "Allow student's video",
        SUBTEXT: "Your students can chat with each other during live class",
    },
    CHAT: {
        TEXT: "Allow students to chat",
        SUBTEXT: "Your students can turn on their videos",
    },
};
Object.freeze(ALLOW_STUDENT);

export const STREAM = {
    YOUTUBE: "youtube",
    CUSTOM: "custom",
};
Object.freeze(STREAM);

export const SETTINGS = {
    AUDIO: "audio",
    VIDEO: "video",
    CHAT: "chat",
};
Object.freeze(SETTINGS);

export const BOP = {
    CHAT: "chat",
    POLL: "poll",
    PARTICIPANT: "participant",
};
Object.freeze(BOP);

export const POLL_TYPE = {
    INSTANT: "instant",
    NEW: "new",
};
Object.freeze(POLL_TYPE);

export const TOOL = {
    NONE: "none",
    COPY: "copy",
    ALL: "all",
    PEN: "pen",
    SELECT: "select",
    ERASER: "eraser",
    HIGHLIGHT: "highlight",
    TEXT: "text",
    UNDO: "undo",
    REDO: "redo",
    LEFT: "left",
    RIGHT: "right",
    ADD: "add",
    LINE: "line",
    ELLIPSE: "ellipse",
    RECTANGLE: "rectangle",
    TRIANGLE: "triangle",
    CIRCLE: "circle",
};
Object.freeze(TOOL);

export const CANVAS_ACTION = {
    DRAW: "draw",
    SHAPE: "shape",
    DELETE: "delete",
};
Object.freeze(CANVAS_ACTION);

export const INTEGRATION_STATUS = {
    PROGRESS: "progress",
    SUCCESS: "success",
    FAIL: "fail",
};
Object.freeze(INTEGRATION_STATUS);

export const TOAST_TYPE = {
    INFO: "info",
    FAIL: "fail",
    WARN: "warn",
    ALERT: "alert",
};
Object.freeze(TOAST_TYPE);

export const LB_OPTION = {
    LEADERBOARD: "leaderboard",
    ANSWERED: "answered",
    UNANSWERED: "unanswered",
};
Object.freeze(LB_OPTION);

export const CANVAS_STATE = {
    NEW: "new",
    RESUME: "resume",
};
Object.freeze(CANVAS_STATE);

export const NEW_TOAST_TYPES = {
    GENERIC_TOAST: "GENERIC-TOAST",
    OFFLINE: "OFFLINE",
    ONLINE: "ONLINE",
    PRESENTING: "PRESENTING",
    STOP_PRESENTING: "STOP-PRESENTING",
    CUSTOM_STREAM_INFO: "CUSTOM-STREAM-INFO",
    CUSTOM_STREAM_FAIL: "CUSTOM-STREAM-FAIL",
    YOUTUBE_FAIL: "YOUTUBE-FAIL",
    YOUTUBE_INFO: "YOUTUBE-INFO",
    UNSTABLE_NETWORK_LOW: "UNSTABLE-NETWORK-LOW",
    UNSTABLE_NETWORK_HIGH: "UNSTABLE-NETWORK-HIGH",
};
Object.freeze(NEW_TOAST_TYPES);

// actions on which we will log events
export const LOG_ACTIONS = {
    ENTERED_ROOM: "entered_room",
    AUDIO_ENABLE: "audio_enable",
    AUDIO_DISABLE: "audio_disbale",
    VIDEO_ENABLE: "video_enable",
    VIDEO_DISABLE: "video_disable",
    SCREEN_SHARE_ENABLE: "screen_share_enable",
    SCREEN_SHARE_DISABLE: "screen_share_disable",
    WHITEBOARD_SHARE_ENABLE: "whiteboard_share_enable",
    WHITEBOARD_SHARE_DISABLE: "whiteboard_share_disable",
    LEFT_ROOM: "left_room",
};

export const AUDIO_PACKETS_LOST_THRESHOLD = {
    low: 10,
    medium: 20,
    high: 30,
};

export const VIDEO_PACKETS_LOST_THRESHOLD = {
    low: 2.5,
    medium: 4,
    high: 10,
};

export const ASPECT_RATIO_WHITEBOARD = 1.77;