import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as roomSettingActions from "../../../redux/actions/roomSettingActions";
import * as videoroomActions from "../../../redux/actions/videoroomActions";
import { RS, WS_MSG_TYPE } from "../../../constants/Enums";
import { utilsUpdateRoomInfo } from "../../requests/Request";

export default function RoomSettings({ sendMessage, startWSConnection }) {
    const { settings, sendMicSetting, sendVideoSetting, sendChatSetting } = useSelector(
        (state) => state.roomSettings
    );
    const { canChangeStudentControls, canSavePreviewSettings } = useSelector(
        (state) => state.permissions
    );
    const { mic } = useSelector((state) => state.audio);
    const { classInfo, classId, userId, hotKey, gotoVideoroom, recording } = useSelector(
        (state) => state.videoroom
    );
    const { camera, currVideoQuality, isUserFacingCam } = useSelector((state) => state.video);
    const dispatch = useDispatch();

    useEffect(() => {
        if (canChangeStudentControls) {
            sendMessage({
                type: settings[RS.IS_MIC_BLOCKED] ? WS_MSG_TYPE.BLOCK_AUDIO : WS_MSG_TYPE.UNBLOCK_AUDIO,
            });
            setRoomSettings(settings);
        }
    }, [sendMicSetting]);

    useEffect(() => {
        if (canChangeStudentControls) {
            sendMessage({
                type: settings[RS.IS_VIDEO_BLOCKED]
                    ? WS_MSG_TYPE.BLOCK_VIDEO
                    : WS_MSG_TYPE.UNBLOCK_VIDEO,
            });
            setRoomSettings(settings);
        }
    }, [sendVideoSetting]);

    useEffect(() => {
        if (canChangeStudentControls) {
            sendMessage({
                type: settings[RS.IS_CHAT_BLOCKED] ? WS_MSG_TYPE.BLOCK_CHAT : WS_MSG_TYPE.UNBLOCK_CHAT,
            });
            setRoomSettings(settings);
        }
    }, [sendChatSetting]);

    useEffect(() => {
        if (gotoVideoroom && canSavePreviewSettings) {
            let newSettings = settings;
            newSettings[RS.IS_MY_MIC_ON] = mic;
            newSettings[RS.IS_MY_CAMERA_ON] = camera;
            newSettings[RS.IS_PREVIEW_RECORDING_ON] = recording;
            newSettings[RS.VIDEO_QUALITY] = currVideoQuality.quality;
            newSettings[RS.IS_FRONT_CAMERA] = isUserFacingCam;

            for (const s in newSettings) {
                dispatch(
                    roomSettingActions.updateRoomSetting({
                        setting: s,
                        value: newSettings[s],
                    })
                );
            }
            setRoomSettings(newSettings);
            startWSConnection();
        }
    }, [gotoVideoroom]);

    const setRoomSettings = async (settings) => {
        let newClassInfo = classInfo;
        newClassInfo.settings = settings;
        dispatch(videoroomActions.updateClassInfoSettings(settings));
        try {
            await utilsUpdateRoomInfo(newClassInfo, classId, userId, hotKey);
        } catch {}
    };

    return null;
}
