export default class EventClicks{
    constructor(){
        this.mic_on = []
        this.mic_off = []
        this.video_on = []
        this.video_off = []
        this.quality_low = []
        this.quality_high = []
        this.quality_hd = []
        this.share_screen_start = []
        this.share_screen_end = []
        this.share_pdf_start = []
        this.share_pdf_end = []
        this.share_whiteboard_start = []
        this.share_whiteboard_end = []
        this.recording_on = []
        this.recording_off = []
        this.pin = []
        this.unpin = []
        this.chat_message_sent = []
        this.mute_all_enabled = []
        this.mute_all_disabled = []
        this.allow_student_to_chat_enable = []
        this.allow_student_to_chat_disable = []
        this.allow_student_to_speak_enable = []
        this.allow_student_to_speak_disable = []
        this.allow_student_video_enable = []
        this.allow_student_video_disable = []
        this.audio_only_enable = []
        this.audio_only_disable = []
        this.more_menu = []
        this.get_help = []
        this.report_abuse = []
        this.submit_feedback = []
        this.left_scroll = []
        this.right_scroll = []
        this.pin = []
        this.unpin = []
        this.youtube_streaming_on = []
        this.youtube_streaming_off = []
        this.custom_streaming_on = []
        this.custom_streaming_off = []
        this.make_presenter = []
    }

    refresh = () => {
        for(const param in this){
            if(typeof this[param] !== 'function')
            this[param] = []
        }
    }

    getClickData = () => {
        let result = {};
        for(const param in this){
            if(typeof this[param] !== 'function' && 
            this[param].length > 0){
                result[param] = this[param]
            }
        }
        return result;
    }
}