import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GreenLock from "../../images/lock-green.svg";
import PermissionSnapshotMobile from "../../images/permission-snap-mobile.svg";
import PermissionSnapshot from "../../images/permission-snap.svg";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import { ERROR } from "../../../constants/Enums";
import { setCurrentAudioDevice, setAudioDevices, setAudioPermissionDenied } from "../../../redux/actions/audioActions";
import { setCurrentVideoDevice, setVideoDevices, setVideoPermissionDenied } from "../../../redux/actions/videoActions";
// import { setLoading } from "../../utils/Utils";

export default function MediaPermissions({
    webrtc,
    setLoading
}) {
    const dispatch = useDispatch()
    const { device, userId, classStatus, gotoVideoroom } = useSelector(state => state.videoroom)
    const [showPermissionModal, setShowPermissionModal] = useState(false)
    const { isHost, isStudent } = useSelector(state => state.permissions)
    const { camera } = useSelector(state => state.video)

    useEffect(() => {
        if ((isStudent && classStatus && gotoVideoroom) ||
            (isHost && classStatus)) {
            checkUserMedia()
        }
    }, [classStatus])

    const checkUserMedia = () => {
        checkUserAudio();
        checkUserVideo();
    };

    const checkUserVideo = async () => {
        try {
            let video;
            const { videoStream } = await webrtc.getVideoStream() //get video stream from any device
            const { videoDevices } = await webrtc.getVideoDevices(); //get video devices
            if (videoDevices) {
                dispatch(setVideoDevices(videoDevices))
            }
            if (videoStream) { //if there is a stream, set it to preview video
                setPreviewVideo(videoStream)
            } else {
                if (!videoDevices) { //if there are no devices, permission is denied
                    denyVideoPermission()
                } else {
                    for (let i = 0; i < videoDevices.length; i++) {
                        const { videoStream } = await webrtc.getVideoStream(videoDevices[i].deviceId)
                        if (videoStream) { //set the first resolved stream to preview
                            video = videoStream
                            setPreviewVideo(videoStream)
                            break;
                        }
                    }
                    if (!video) { //if there is no video after checking all devices
                        denyVideoPermission()
                    }
                }
            }
        } catch (e) {
            console.log(e)
        }
        finally {
            setLoading(false)
        }
    }

    const setPreviewVideo = (stream) => {
        const track = stream.getVideoTracks()[0];
        const settings = track.getSettings();
        dispatch(setCurrentVideoDevice({
            deviceId: settings.deviceId,
            groupId: settings.groupId,
            label: track.label,
        }));
        isStudent
            ? webrtc.stopPreviewTracks()
            : webrtc.setPreviewVideo(stream);
    }

    const denyVideoPermission = () => {
        dispatch(setVideoPermissionDenied(true))
        if (!showPermissionModal && !userId.includes("hidden_")) {
            setShowPermissionModal(true)
        }
    }

    const checkUserAudio = async () => {
        try {
            let audio
            const { audioStream } = await webrtc.getAudioStream(); //get audio stream from any device
            const audioDevices = await webrtc.getAudioDevices(); //get audio devices
            if (audioDevices) {
                dispatch(setAudioDevices(audioDevices))
            }
            if (audioStream) {
                setPreviewAudio(audioStream) //if stream exists, set it to preview
            } else {
                if (!audioDevices) { //if there are no audio devices found, permission is denied
                    denyAudioPermission()
                } else {
                    for (let i = 0; i < audioDevices.length; i++) {
                        const { audioStream } = await webrtc.getAudioStream(audioDevices[i].deviceId, audioDevices[i].groupId)
                        if (audioStream) { //set the first resolved stream to preview
                            audio = audioStream
                            setPreviewAudio(audioStream)
                            break;
                        }
                    }
                    if (!audio) { //if there is no audio after checking all devices
                        denyAudioPermission()
                    }
                }
            }
        }
        catch (e) {
            console.log(e)
        }
        finally {
            setLoading(false)
        }
    }

    const setPreviewAudio = (stream) => {
        const track = stream.getAudioTracks()[0];
        const settings = track.getSettings();
        dispatch(setCurrentAudioDevice({
            deviceId: settings.deviceId,
            groupId: settings.groupId,
            label: track.label,
        }));
        webrtc.setPreviewAudio(stream);
    }

    const denyAudioPermission = () => {
        dispatch(setAudioPermissionDenied(true))
        if (!showPermissionModal && !userId.includes("hidden_")) {
            setShowPermissionModal(true)
        }
    }

    return (
        showPermissionModal && gotoVideoroom ?
            <ConfirmationModal
                icon={GreenLock}
                title={"Camera and Microphone Permission"}
                confirmText="Okay"
                contentClass={device.mobile ? "" : "wide"}
                onConfirm={() => {
                    setShowPermissionModal(false)
                }}
            >
                <span>
                    To access your camera and microphone, click the lock icon on your
                    browser’s address bar and give access to camera and mic
                </span>
                <img
                    alt="icon"
                    style={{ marginTop: "20px" }}
                    src={
                        device.mobile ? PermissionSnapshotMobile : PermissionSnapshot
                    }
                />
            </ConfirmationModal>
            : null
    )
} 