export const englishLang = {
  teacher: 'Teacher',
  student: 'Student',
  somethingWentWrongText: 'Something Went Wrong',
  saveButtonText: 'Save',
  createClassroomCancelButtonText: 'Cancel',
  createHomeworkContinueButtonText: 'Continue',
  goLiveText: 'Go Live',
  dashboardSubheaderTextChat: 'Chat',
  feedbackText: 'Feedback',
  required: 'Required',
  backButtonText: 'Back',
  closeButtonText: 'Close',
  goLiveClassRecordingOnText: 'Class Recording on',
  goLiveClassRecordingOffText: 'Class Recording off',
  goLiveMicrophoneOnText: 'Microphone on',
  goLiveMicrophoneOffText: 'Microphone off',
  microphoneText: 'Microphone',
  cameraText: 'Camera',
  goLiveRecordingOnText: 'Recording on',
  goLiveRecordingOffText: 'Recording off',
  goLiveVideoButtonText: 'Video',
  goLiveMoreButtonText: 'More',
  goLiveVideoOnText: 'Video on',
  goLiveVideoOffText: 'Video off',
  goLiveMicButtonText: 'Mic',
  goLiveMicOnText: 'Mic on',
  goLiveMicOffText: 'Mic off',
  goLiveAllowStudentToChatText: 'Allow students to chat',
  goLiveEmptyChatText: 'No messages here yet...',
  goLiveEmptyChatSubText: 'Get started with your Live Class chat',
  goLiveInteractWithStudentsText: 'Interact with your students...',
  goLiveInteractWithClassmatesText: 'Interact with your classmates...',
  goLiveDisabledStudentToChatText: 'Teacher has disabled Chat',
  goLiveOpenWhiteboardText: 'Open Whiteboard',
  goLiveShareMyScreenText: 'Share Screen',
  goLiveShareFilesButtonText: 'Share Files',
  goLiveStudentControlsButtonText: 'STUDENT\'S CONTROL',
  goLiveStartRecordingButtonText: 'Start recording',
  goLiveStopRecordingButtonText: 'Stop recording',
  goLivePauseRecordingButtonText: 'Pause recording',
  goLivePeopleButtonText: 'People',
  goLivePollsButtonText: 'Polls',
  goLiveButtonStudentDashboardText:
    'Your audio and video will be off when you join the classroom',
  goLiveStudentDashboardEnterTheClassroomButtonText: 'Enter the Classroom',
  goLiveStudentDashboardWaitingForTeacherText: 'Waiting for Teacher',
  goLiveStudentDashboardNotEnrolledText:
    'You are not enrolled in the classroom. Please contact the teacher for classroom ID',
  goLiveStudentDashboardExitButtonText: 'Exit',
  goLiveStudentDashboardHandRaiseText: 'Hand Raise',
  goLiveStudentDashboardHandLowerText: 'Lower Hand',
  goLiveStudentDashboardAudioOnlyText: 'Audio only',
  goLiveStudentDashboardReportAbuseText: 'Report Abuse',
  goLiveStudentDashboardSubmitFeedbackText: 'Submit Feedback',
  goLiveClassroomEndedButtonText: 'Classroom ended',
  linkButton: 'Copy Link',
  createNoticeBoardCreateButtonText: 'Create',
  dashboardStudentsCopyClipboard: 'Successfully copied to clipboard',
  goLiveTeacherDashboardStreamLiveOnYoutubeText: 'Stream on YouTube',
  goLiveTeacherDashboardStreamLiveCustomText:
    'Stream on other platforms',
  goLiveTeacherDashboardExitWhiteboardText: 'Exit Whiteboard',
  goLiveTeacherDashboardExitWhiteBoardExitContentText:
    'Would you like to save this whiteboard before exiting?',
  goLiveTeacherDashboardExitWhiteBoardCloseText: 'Close Anyway',
  goLiveTeacherDashboardWhiteBoardNoHandRaisedText: 'No hand raises yet',
  goLiveTeacherDashboardWhiteBoardLowerAllHandsText: 'Lower all hands',
  goLiveTeacherDashboardWaitingRoomText: 'Waiting Room',
  goLiveInstantPollText: 'Start an instant poll',
  goLiveCreateNewPollText: 'Create new poll',
  goLivePollsLiveButtonText: 'Launch',
  goLivePollsEmptyText: 'Increase interactions in your class by creating polls',
  goLiveTeacherDashboardMuteAllText: 'Mute all',
  goLiveTeacherDashboardYoutubeStreamingLiveText: 'YouTube Streaming Live',
  goLiveTeacherDashboardScreenSharingText:
    'You are presenting your screen to everyone',
  goLiveTeacherDashboardStopPresentingText: 'Stop Presenting',
  goLiveTeacherDashboardLeaveClassText: 'Leave Class',
  goLiveEndClassButtonSubText: 'End the class for all participants?',
  goLiveEndClassButtonText: 'End Class for all',
  goLiveTeacherDashboardLeaveTheClassLetOthersContinueText:
    'Only you want to leave the class and let others continue?',
  feedbackLabel:
    'Let us know if you are facing any issues. We will get back to you.',
  sendFeedbackButton: 'Send Feedback',
  yesButtonText: 'Yes',
  noButtonText: 'No',
  secondText: 'sec',
  allChangesLostText: 'Are you sure? All changes will be lost.',
  offlineText: 'Offline',
  scoreText: 'Score',
  rankText: 'Rank',
  playingPictureAndPictureModeText: 'Playing in picture-in-picture mode',
  stoppingPictureAndPictureModeText: 'Stop picture-in-picture',
  clearAllText: 'Clear All',
  clearAllAnnotationToolsText: 'Are you sure you want to clear all?',
  answeredSmallText: 'answered',
  stopInstantPollText: 'Stop instant poll',
  viewPollAfterpollEnds: ' View poll analysis here after the poll ends',
  screenPollStudentsText:
    'The shared screen will become a poll and the students can choose a single option from A, B, C & D',
  selectPollDurationText: 'Select poll duration',
  insecondsText: 'in seconds',
  markCorrectAnswerText: 'Mark correct answer',
  stopPollText: 'Stop poll',
  pickedCorrectAnswerText: 'Ah! No one picked the correct answer',
  leaderboardText: 'Leaderboard',
  connectedAnotherDeviceText: 'Connected from another device',
  continueCurrentSession:
    'You have joined the classroom from another system or browser. Do you want to continue current session?',
  noOnePickedText: 'No one picked',
  instantPollCompletedText: 'Instant poll completed',
  createPollSettings: 'Create poll settings',
  youSureStopText: 'Are you sure you want to stop the',
  instantText: 'instant',
  pollText: 'poll',
  stopInstantText: 'Stop Instant',
  pollDurationToLaunch: 'Select poll duration to launch',
  correctAnswerToLaunch: 'Mark correct answer to launch',
  pollBeingLaunchedText: 'Instant poll is being launched',
  pollIsBeingLaunched: 'Poll is being launched',
  teacherLaunchingPollText: 'Teacher is launching a poll',
  pollAnswerFromHere: 'Select the poll answer from here',
  pollStartsText: 'Poll starts in',
  studentDisabledLivePollText:
    'Students are muted and chat is disabled during live poll',
  pollQuestionOptionsText: 'Enter poll question and options',
  enterPollQuestionText: 'Enter Poll Question',
  enterPollQuestionContinueText: 'Please enter poll question to continue',
  enterPollOptionsText: 'Enter Poll Options',
  enterLeast2OptionsContinue: 'Enter at least 2 options to continue',
  enterAllOptionsContinue: 'Please enter all the options to continue',
  addAnotherOptionText: '+ Add another Option',
  durationOfPollText: 'Duration of poll (in seconds)',
  selectPollDurationContinueText: 'Select poll duration to continue',
  markCorrectAnswerContinueText: 'Mark the correct answer to continue',
  launchPollText: 'Launch Poll',
  youtubeText: 'Youtube',
  streamCustomLiveText: 'Stream custom live',
  enterURLText: 'Enter URL',
  liveStreamKeyText: 'live stream key',
  enterText: 'Enter',
  enterLiveStreamKeyText: 'Enter live stream key',
  startYouTubeLiveStreamText: 'Start YouTube live stream',
  startLiveStream: 'Start live stream',
  youtubeStreamKey: 'How to find youtube stream key?',
  youTubeTermsConditionsText: 'YouTube terms and conditions',
  signInWithGoogleText: 'Sign in with Google',
  enterYouTubeLiveStreamKeyText: 'Enter YouTube live stream key',
  stopLiveStreaming: 'Are you sure you want to stop live streaming?',
  stopStreamingText: 'Stop streaming',
  stopText: 'Stop',
  disabledAudioOnlyModeText: 'You have disabled the Audio only mode',
  enabledAudioOnlyModeText: 'You have enabled the Audio only mode',
  submittedYourResponseText: 'Option <1> </1> is submitted as your response',
  handRaiseDuringPollText: 'Hand Raise disabled during poll',
  pollCompletedText: 'Poll completed',
  mutedTeacherText: 'You have been muted by teacher',
  audioHasBeenDisabledText: 'Audio has been disabled',
  videoHasBeenDisabledText: 'Video has been disabled',
  chatHasBeenDisabledText: 'Chat has been disabled',
  youText: 'You',
  classroomInfoNotFetch: 'Could not fetch classroom info',
  couldNotConnectServer: 'Couldn’t connect to servers',
  checkNetworkAndTry: 'Please check your network and try again',
  rejoin: 'Rejoin',
  roomId: 'Room Id',
  userId: 'User Id',
  hotkey: 'HotKey',
  submit: 'Submit',
  gotoErr: 'Go to err',
  classStatus: 'Class status',
  enableAudioPermissions: 'Enable Audio Permissions',
  switchOffMic: 'Switch Off Mic',
  switchOnMic: 'Switch On Mic',
  enableVideoPermissions: 'Enable Video Permissions',
  switchOffCamera: 'Switch Off Camera',
  switchOnCamera: 'Switch On Camera',
  HD: 'HD',
  High: 'High',
  Low: 'Low',
  hasStartedRecording: 'has started the recording',
  hasPausedRecording: 'has paused the recording',
  hasStartedStreaming: 'has started the streaming',
  hasStoppedStreaming: 'has stopped the streaming',
  shareWhiteboard: 'Share whiteboard',
  allowStudentMic: 'Allow Student\'s Mic',
  allowStudentCamera: 'Allow Student\'s Camera',
  classPreference: 'CLASS PREFERENCE',
  feedbackSuggestions: 'FEEDBACK & SUGGESTIONS',
  systemInformation: 'SYSTEM INFORMATION',
  networkDeviceStats: 'Network & Device Stats',
  audioOptions: 'AUDIO OPTIONS',
  audioOutput: 'Audio Output',
  connected: 'connected',
  noAudioAvailable: 'No Audio Available',
  videoOptions: 'VIDEO OPTIONS',
  noCameraAvailable: 'No Camera Available',
  videoQuality: 'Video Quality',
  liveVideoStreaming: 'LIVE VIDEO STREAMING',
  stopYoutubeStream: 'Stop Youtube streaming',
  stopSharing: 'Stop Sharing',
  stopSharingScreen: 'Stop Sharing Screen',
  stopSharingWhiteboard: 'Stop Sharing Whiteboard',
  stopSharingFile: 'Stop Sharing File',
  takePresenterRole: 'Take Presenter Role',
  takeOverPresenterRole: '{{name}}  currently has the presenter role. Do you want to take over the role?',
  openNewWhiteboard: 'Open a new whiteboard',
  resumePreviousWhiteboard: 'Resume Previous Whiteboard',
  selectFile: 'Select a File',
  openNewFile: 'Open a new file',
  resumePreviousFile: 'Resume previous file',
  saveExit: 'Save & Exit',
  micBlockedByTeacher: 'Mic is blocked by the teacher',
  videoBlockedByTeacher: 'Video is blocked by the teacher',
  raiseHand: 'Raise Hand',
  showMoreOptions: 'Show More Options',
  backToVideoScreen: 'Tap here to go back to video screen',
  feedbackSubmittedSuccessfully: 'Feedback submitted successfully',
  disableMic: 'Disable mic',
  allowToSpeak: 'Allow to speak',
  mute: 'Mute',
  allowMicUsage: 'Allow Mic usage',
  allowCameraUsage: 'Allow Camera Usage',
  allowChatUsage: 'Allow Chat Usage',
  switchCamera: 'Switch Camera',
  studentAccess: 'Student’s Access',
  liveVideoStreamingLower: 'Live Video Streaming',
  startPoll: 'Start Poll',
  livePolls: 'Live Polls',
  sharedScreenBecomePoll: 'The shared screen will become a poll',
  launchInstantPoll: 'Launch Instant Poll',
  studentAccessUpper: 'STUDENT\'S ACCESS',
  streamOnYoutbe: 'Stream on Youtube',
  streamOnOtherPlatform: 'Stream on other Platform',
  unpin: 'Unpin',
  pin: 'Pin',
  hasPresenterAccess: 'has the presenter access',
  doYouWanToGive: 'Do you want to give it to',
  confirmGivePresenterAccess: 'would be able to share screen, whiteboard & files. Are you sure?',
  coTeacher: 'Co-Teacher',
  presenter: 'Presenter',
  stopAccess: 'Stop Access',
  makePresenter: 'Make Presenter',
  givePresenterAccess: 'Give Presenter Access',
  giveAccess: 'Give Access',
  stopPresenterAccess: 'Stop Presenter Access',
  cannotGivePresenterAccess: 'Cannot Give Presenter Access to this User',
  gotIt: 'Got it',
  askToUpdateApp: '{{name}}  hasn’t updated their app. Ask them to update their application to make them presenter',
  leaderboard: 'Leaderboard',
  answered: 'Answered',
  notAnswered: 'Not Answered',
  isCorrect: 'is Correct',
  isWrong: ' is Wrong',
  correctAnswer: 'Correct answer',
  answeredCorrectly: 'answered correctly',
  everybodyAnswered: 'Yay! Everybody answered',
  wrongAnswer: 'Wrong Answer!',
  correctAnswerUpper: 'Correct Answer!',
  line: 'Line',
  ellipse: 'Ellipse',
  rectangle: 'Rectangle',
  triangle: 'Triangle',
  circle: 'Circle',
  fileCorrupted: 'The file is corrupted. Unable to open the document',
  youSavedWhiteboard: 'You have saved the whiteboard',
  youtubeLiveStreamKey: 'YouTube Live stream key',
  liveStreamNotEnabled: 'Live streaming is not enabled on <1> </1> . Please enable live streaming or log into an account with live streaming enabled',
  enterLiveStreamTitle: 'Enter a title for your live streaming',
  connectedTitle: 'Connected',
  public: 'Public',
  private: 'Private',
  unlisted: 'Unlisted',
  notMe: 'Not me',
  broadcastOnYoutubeLive: 'Broadcast Teachmint classroom on YouTube Live',
  titleOfStream: 'Title of the YouTube Stream',
  enterYoutubeLiveStreamTitle: 'Enter Teachmint classroom YouTube live stream title here',
  privacySettings: 'Privacy Settings',
  goLiveOnYoutube: 'Go Live on YouTube',
  liveStreamByYoutube: 'The live stream service is provided by YouTube',
  googlePrivacyPolicy: 'Google Privacy Policy',
  termsOfService: 'Terms of Service',
  apply: 'apply',
  canRevokeAccessToData: 'You can revoke the access to your data by accessing the',
  googleSecuritySettings: 'Google security settings',
  page: 'page',
  copyrightTeachmintTPL: 'Copyright 2021, Teachmint Technologies Pvt. Ltd.',
  privacyPolicy: 'Privacy Policy',
  cannotUnmute: 'You can\'t unmute',
  teacherToJoin: 'Teacher yet to Join',
  streamingLiveIn: 'Streaming Live in...',
  youtubeStreamingFailed: 'YouTube Streaming failed',
  tryAgain: 'Try Again',
  isSharingScreen: 'is sharing the screen',
  isSharingScreenDisableAudio: 'is sharing the screen. Please disable the Audio only mode.',
  youAreMuted: 'You are muted',
  youAreOnline: 'You are online now',
  networkDisconnected: 'Network disconnected. Reconnecting',
  audioEnabled: 'Audio has been enabled',
  videoEnabled: 'Video has been enabled',
  chatEnabled: 'Chat has been enabled',
  teacherStoppedPoll: 'Teacher stopped the poll',
  hasSavedWhiteboard: 'has saved the whiteboard',
  youArePresenterNow: 'You are the presenter. Now you can share screen, whiteboard & files',
  yourAreNotPresenter: 'You are no longer the presenter. <1>  </1>  is presenting now',
  presentationAccessRevoked: 'Presentation access revoked. Your current file state is saved for this session',
  isPresentingNow: 'is presenting now',
  teacherIsCreatingPoll: 'Teacher is creating a poll',
  youLeftClassroom: 'You left the classroom',
  liveStreamStopped: 'Live stream stopped',
  youtubeLiveStreamStarted: 'YouTube Live stream started. You are now live on YouTube',
  liveStreamFailed: 'Ooops! Live stream failed.',
  youtubeLiveStreamFailed: 'Ooops! Youtube live stream failed.',
  teacherEnabledyourMic: 'Teacher enabled your mic',
  handLowered: 'Your hand has been lowered',
  loadingWhiteboard: 'Loading Whiteboard',
  loadingDocument: 'Loading the document',
  toolbarHidden: 'Tool bar hidden',
  clickLockToViewToolbar: 'Click on the lock to view the toolbar again',
  clickLock: 'Click on Lock',
  closeAnyway: 'Close anyway',
  saveWhiteboardBeforeExiting: 'Would you like to save this whiteboard before exiting',
  searchPeopleByName: 'Search People by Name',
  internetUnstable: 'Your internet connection is unstable.',
  showDetails: 'Show Details',
  systemStats: "System Stats",
  networkStability: "Network Stability",
  networkStabilityTxt: "Unstable or weak network can lead to audio break, laggy video, etc.",
  packetLoss: "Packet Loss",
  jitter: "Jitter",
  connectionDelay: "Connection Delay",
  publishBitrate: "Publish bitrate",
  subscribeBitrate: "Subscribe bitrate",
  quickFixTip: "Quick Fix Tip",
  quickFixTipOne: 'Avoid high internet consuming apps',
  quickFixTipTwo: "Close browser tabs or any running background application",
  quickFixTipThree: "Check your internet speed and move to high internet strength area",
  systemInformationTxt: "SYSTEM INFORMATION",
  networkAndDeviceStatsTxt: "Network & Device Stats",
  showMirrorView: 'Show Mirror View',
  yes: 'Yes',
  no: "No",
  videoOptionSmallCase: "Video Options",
  exitPdfText: "Exit PDF", // need translation
  savePdfBeforeExit : 'Would you like to save this PDF before exiting?',
  hasSavedPDF: 'has saved the PDF',

};

export default englishLang;
