import React from "react";
import { Redirect } from 'react-router-dom';
import { ERROR } from "../constants/Enums";
import RoomInfoErr from "../videoroom/images/errors/err-roominfo.svg";
import WebsocketErr from "../videoroom/images/errors/err-websocket.svg";
import { withTranslation } from 'react-i18next';

class ErrorPage extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            videoroomPath: null,
            redirect: false,
            errorType: null,
            errImage: null,
            errPrimaryText: null,
            errSecondaryText: null
        }
    }

    componentDidMount = () => {
        console.log(this.props);
        const {t} = this.props;
        const { state } = this.props.location;
        const { errorType, videoroomPath } = state;
        this.setState({ videoroomPath: videoroomPath })
        switch(errorType){
            case ERROR.ROOMINFO: {
                this.setState({
                    errImage: RoomInfoErr,
                    errPrimaryText: t("classroomInfoNotFetch")
                })
                break;
            }
            case ERROR.WEBSOCKET: {
                this.setState({
                    errImage: WebsocketErr,
                    errPrimaryText: t("couldNotConnectServer"),
                    errSecondaryText: t("checkNetworkAndTry")
                })
                break;
            }
            default: {
                this.setState({
                    errPrimaryText: t('somethingWentWrongText')
                })
                break;
            }
        }

        this.setState({
            errorType: errorType
        })
    }

    goToVideoroom = () => {
        this.setState({
            redirect: true
        })
    }

    render(){
        const {t} = this.props;
        if(this.state.redirect){
            return <Redirect to={{
                pathname: `${this.state.videoroomPath}`,
            }}/>
        }

        return(
            <div className="fullscreen tm-bgcr-wh-1 center">
                <div className="center flex-col">
                    {this.state.errImage ? 
                        <img alt="error" src={this.state.errImage}/>
                    : null}
                    {this.state.errPrimaryText ? 
                        <div className="tm-hdg-24" style={{padding: "2rem"}}>
                            {this.state.errPrimaryText}
                        </div>
                    : null}
                    {this.state.errSecondaryText ? 
                        <div className="tm-para-20" style={{padding: "2rem"}}>
                            {this.state.errSecondaryText}
                        </div>
                    : null}

                    <div className="tm-bdr-bl-2 tm-hdg-18 tm-cr-bl-2 center" 
                        style={{height: "48px", width: "196px", borderRadius: "8px", cursor: "pointer"}}
                        onClick={this.goToVideoroom}
                    >
                        {t("Rejoin")}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(ErrorPage);