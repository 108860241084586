import { TOAST_TYPE, NEW_TOAST_TYPES } from "../../../constants/Enums";
import YoutubeIcon from "../../images/youtube.svg";
import OnlineIcon from "../../images/online.svg";
import OfflineGifIcon from "../../images/offline.gif";
import StreamGreyIcon from "../../images/stream-grey.svg";
import Presenter from "../../images/toast-presenter.svg";
import StopPresenter from "../../images/toast-stop-presenter.svg";
import GenericInfo from "../../images/bell-blue.svg";
import infoIconRed from '../../images/info-icon-red.svg';
import infoIconLight from '../../images/info-icon-light.svg';

let id = "";

const getId = () => {
  return Math.floor(new Date().getTime());
};

const getToastColor = (type) => {
  switch (type) {
    case TOAST_TYPE.INFO: {
      return "#fff";
    }
    case TOAST_TYPE.FAIL: {
      return "#fadedf";
    }
    default: {
      return "#fff";
    }
  }
};

const getToastAttributes = (type, currentStreamOption, ytBroadcastId) => {
  switch (type) {
    case NEW_TOAST_TYPES.GENERIC_TOAST:
      return {
        icon: GenericInfo,
        color: getToastColor("default"),
        isPersistent: false,
        cross: false
      };
    case NEW_TOAST_TYPES.OFFLINE:
      return {
        icon: OfflineGifIcon,
        color: getToastColor("fail"),
        isPersistent: true,
        cross: false
      };
    case NEW_TOAST_TYPES.ONLINE:
      return {
        icon: OnlineIcon,
        color: getToastColor("info"),
        isPersistent: false,
        cross: false
      };
    case NEW_TOAST_TYPES.PRESENTING:
      return {
        icon: Presenter,
        color: getToastColor("info"),
        isPersistent: false,
        cross: false
      };
    case NEW_TOAST_TYPES.STOP_PRESENTING:
      return {
        icon: StopPresenter,
        color: getToastColor("info"),
        isPersistent: false,
        cross: false
      };
    case NEW_TOAST_TYPES.CUSTOM_STREAM_FAIL:
      return {
        icon: StreamGreyIcon,
        color: getToastColor("info"),
        isPersistent: false,
        cross: false,
        extraDetails: {
          // payload: { "streamingOption": currentStreamOption, "showStreamingModal": true }
          payload: { showCustomStreamModal: true }
        }
      };
    case NEW_TOAST_TYPES.CUSTOM_STREAM_INFO:
      return {
        icon: StreamGreyIcon,
        color: getToastColor("info"),
        isPersistent: false,
        cross: false,
        // extraDetails: {
        //   payload: { setting: "streamingOption", value: currentStreamOption }
        // }
      };
    case NEW_TOAST_TYPES.YOUTUBE_FAIL:
      return {
        icon: YoutubeIcon,
        color: getToastColor("fail"),
        isPersistent: false,
        cross: true,
        extraDetails: {
          // payload: { "streamingOption": currentStreamOption, "showStreamingModal": true }
          payload: { showYoutubeModal: true }
        }
      };
    case NEW_TOAST_TYPES.YOUTUBE_INFO:
      return {
        icon: YoutubeIcon,
        color: getToastColor("info"),
        isPersistent: false,
        cross: true,
        extraDetails: {
          ytBroadcastId: ytBroadcastId,
          // payload: { setting: "streamingOption", value: currentStreamOption }
        }
      };
    case NEW_TOAST_TYPES.UNSTABLE_NETWORK_HIGH:
      return {
        icon: infoIconRed,
        color: getToastColor("info"),
        isPersistent: true,
        cross: true,
        extraDetails:{}
      };

    case NEW_TOAST_TYPES.UNSTABLE_NETWORK_LOW:
      return {
        icon: infoIconLight,
        color: getToastColor("info"),
        isPersistent: true,
        cross: true,
        extraDetails:{}
      };

    default:
      return {
        icon: null,
        color: getToastColor("default"),
        isPersistent: false,
        cross: true
      };
  }
};

export const creatToast = (
  toastType,
  toastMessage,
  currentStreamOption = null,
  extraText = "",
  ytBroadcastId = ""
) => {
  const toastAttr = getToastAttributes(toastType, currentStreamOption, ytBroadcastId);
  id = `${getId()}-${toastType}`;
  return { id, toastType, toastMessage, extraText, ...toastAttr };
};
