import React from "react";
import CryptoJS from "crypto-js";
import { isPresenter } from "./userUtils";

/* Get color from userId */
export const getUserColor = (userId) => {
    try {
        userId = userId.slice(userId.length - 6);
        const curColor = `rgb(${(parseInt(userId, 16) * 7) % 150}, ${
            (parseInt(userId, 16) * 107) % 150
        }, ${(parseInt(userId, 16) * 91) % 150})`;
        return curColor === "#000000" || curColor === "#ffffff" ? "#1d2d50" : curColor;
    } catch {}
    return "#1d2d50";
};

export const getUserHSLcolor = (userId) => {
    const ha = CryptoJS.SHA256(userId);
    var hashInBase64 = CryptoJS.enc.Hex.stringify(ha);
    let id = parseInt(hashInBase64, 16);
    const h = ((id * 7) % 360) * 1.0;
    const s = 60;
    const l = 70;
    return `hsl(${h}, ${s}%, ${l}%)`;
};

export const removeTraiAndLeadSpaces = (str) => {
    return str.replace(/^\s+|\s+$/g, "");
};

export const urlify = (text, idClass) => {
    //eslint-disable-next-line
    var urlRegex =
        /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
    var urlRegexShort = /(https?:\/\/[^\s]+)/g;
    let match = null;
    let index = 0;
    let componentArr = [];
    while ((match = urlRegex.exec(text))) {
        let starting = match.index;
        let ending = urlRegex.lastIndex;
        let url = text.substring(starting, ending);
        let urlLink = url;
        if (!url.match(urlRegexShort)) urlLink = `https://${url}`;
        let compont = React.createElement(
            "span",
            {
                key: `${idClass}${index++}`,
                className: "chat-anchor-effect",
                onClick: () => window.open(urlLink),
            },
            url
        );
        let textComponent = React.createElement(
            "span",
            { key: `${idClass}${index++}` },
            text.substring(0, starting)
        );
        componentArr.push(textComponent);
        componentArr.push(compont);
        text = text.substring(ending);
    }
    let textComponent = React.createElement("span", { key: `${idClass}${index++}` }, text);
    componentArr.push(textComponent);

    let parentdiv = React.createElement("span", { key: `${idClass}${index++}` }, [...componentArr]);
    return parentdiv;
};

export const createSetIntervalPolyfill = () => {
    // closure
    var intervalID = 1;
    var intervalMap = {};

    function setIntervalPolyfill(callbackFn, delay = 0, ...args) {
        if (typeof callbackFn !== "function") {
            throw new TypeError("'callback' should be a function");
        }

        // Unique
        var id = intervalID++;

        function repeat() {
            intervalMap[id] = setTimeout(() => {
                callbackFn(...args);
                // Terminating
                if (intervalMap[id]) {
                    repeat();
                }
            }, delay);
        }
        repeat();
        return id;
    }

    function clearIntervalPolyfill(intervalID) {
        clearTimeout(intervalMap[intervalID]);
        delete intervalMap[intervalID];
    }

    return {
        setIntervalPolyfill,
        clearIntervalPolyfill,
    };
};

export const getToday = () => {
    let date = new Date().toDateString();
    date = date.split(" ");
    let week = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    let w = week.indexOf(date[0]);
    return {
        week: w,
        date: `${date[2]} ${date[1]} ${date[3]}`,
    };
};

export const getTodayDateISO = () => {
    return new Date().toISOString();
};

export const getTimestamp = () => Math.round(new Date().getTime() / 1000);

export const getReadableTime = (time) => {
    let hours = Math.floor(time / 3600);
    hours = ("0" + hours).slice(-2);
    time %= 3600;
    let minutes = Math.floor(time / 60);
    minutes = ("0" + minutes).slice(-2);
    let seconds = time % 60;
    seconds = ("0" + seconds).slice(-2);
    return (hours > 0 ? hours + ":" : "") + minutes + ":" + seconds;
};

export const getChatTime = () => {
    let date = new Date();
    let min = date.getMinutes();
    return `${date.getHours()}:${min < 10 ? "0" + min : min}`;
};

export const sortByName = (arr) => {
    arr.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });
    return arr;
};

export const sortUserList = (users) => {
    //order: presenter-screen, teacher, coteachers, presenter(student), students
    let teacher;
    let screen = [];
    let presenter;
    let coTeacherList = [];
    let studentList = [];
    let res = [];
    for (const t of users) {
        if (t.isScreenShare) {
            screen.push(t);
        } else if (!t.isScreenShare && t.utype === 1) {
            teacher = t;
        } else if (t.utype === 11) {
            coTeacherList.push(t);
        } else if (t.utype === 2 && isPresenter(t)) {
            presenter = t;
        } else {
            studentList.push(t);
        }
    }
    screen = sortByName(screen);
    coTeacherList = sortByName(coTeacherList);
    studentList = sortByName(studentList);
    if (screen.length > 0) {
        res = res.concat(screen);
    }
    teacher && res.push(teacher);
    if (coTeacherList.length > 0) {
        res = res.concat(coTeacherList);
    }
    presenter && res.push(presenter);
    if (studentList.length > 0) {
        res = res.concat(studentList);
    }
    return res;
};

export const throttleFunction = (func, delay) => {
    let prev = 0;
    return (...args) => {
        let curr = new Date().getTime();
        if (curr - prev > delay) {
            prev = curr;
            return func(...args);
        }
    };
};

export const isInViewPort = (el) => {
    if (!el) return false;
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

export const getOS = () => {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
        windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
        iosPlatforms = ["iPhone", "iPad", "iPod"],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = "Windows";
    } else if (/Android/.test(userAgent)) {
        os = "Android";
    } else if (!os && /Linux/.test(platform)) {
        os = "Linux";
    }

    return os;
};
