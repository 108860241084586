import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateUserPresenterStatus } from "../../../redux/actions/presenter";
import { STATUS, ROOM_META, NEW_TOAST_TYPES } from "../../../constants/Enums";
import { getName } from "../../utils/userUtils";
import { addToast } from "../../../redux/actions/toasts";
import { updateSetting } from "../../../redux/actions/videoroomActions";
import { updatedSelectedPresenterID } from "../../../redux/actions/presenter";
import { creatToast } from "../Toast/toastCreator";
import { useTranslation, Trans } from "react-i18next";
export default function Presenter({
    control,
    sendStatus,
    sendRoomMeta,
    setSelectedPin,
    publisher,
    stopSharingIfPresenter,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { presenterUid, currentUserIsPresenter, selectedPresenterId, provideAccess } = useSelector(
        (state) => state.presenter
    );

    const { recording, sendScreenshare, userId } = useSelector(
        (state) => state.videoroom
    );
    const { currStreamOption } = useSelector(state => state.streaming)

    const users = useSelector((state) => state.users);

    const getUnsetFlagsValue = () => {
        let newControl = control;
        newControl = newControl & ~(1 << STATUS.PRESENTER);
        if (recording) {
            newControl = newControl & ~(1 << STATUS.RECORDING);
        }
        if (currStreamOption) {
            newControl = newControl & ~(1 << STATUS.STREAMING);
        }
        if (sendScreenshare && publisher.isSharingScreen) {
            newControl = newControl & ~(1 << STATUS.SCREENSHARE);
        }
        return newControl;
    };

    const getSetFlagsValue = () => {
        let newControl = control;
        newControl = (1 << STATUS.PRESENTER) | newControl;
        if (recording) {
            newControl = (1 << STATUS.RECORDING) | newControl; //if recording is ON, set your recording bit
        }
        if (currStreamOption) {
            newControl = (1 << STATUS.STREAMING) | newControl;
        }
        return newControl;
    };

    const removePresenterAccess = (user) => {
        const newControl = getUnsetFlagsValue();
        sendStatus(newControl);

        //If I am sharing whiteboard/pdf/screen stop it
        if (sendScreenshare && publisher.isSharingScreen) {
            //give popup to ask to save state
            stopSharingIfPresenter();
        }

        //Show a toast that you are no longer a presenter
        dispatch(
            addToast(
                creatToast(
                    NEW_TOAST_TYPES.STOP_PRESENTING,
                    `You are no longer the presenter. ${user ? `${getName(user)} is the presenter now` : ""
                    }`
                )
            )
        );

        //Acknowledge that you lost presenter access
        dispatch(updateUserPresenterStatus(false));
    };

    const addPresenterAccess = (user) => {
        //Set control flags
        const newControl = getSetFlagsValue();
        sendStatus(newControl);

        //Acknowledge that you are presenter
        dispatch(updateUserPresenterStatus(true));
    };

    useEffect(() => {
        if (selectedPresenterId !== presenterUid) {
            const user = users[selectedPresenterId];
            if (user) {
                sendRoomMeta(ROOM_META.PRESENTER_UID, selectedPresenterId);
                dispatch(
                    updateSetting({
                        setting: "presenterId",
                        value: selectedPresenterId,
                    })
                );
            } else {
                console.log(
                    `Presenter ID passed is not in USER List. PresenterID: ${selectedPresenterId}`
                );
            }
        }
    }, [selectedPresenterId, provideAccess]);

    useEffect(() => {
        const user = users[presenterUid];
        if (user) {
            if (presenterUid === userId) {
                addPresenterAccess(user);
                dispatch(addToast(creatToast(NEW_TOAST_TYPES.PRESENTING, t("youArePresenterNow"))));
            } else {
                if (currentUserIsPresenter) {
                    removePresenterAccess(user);
                } else {
                    dispatch(
                        addToast(
                            creatToast(
                                NEW_TOAST_TYPES.PRESENTING,
                                `${getName(user)} is the presenter now`
                            )
                        )
                    );
                }
            }
        }
        const screen = users[user?.uid + "-screen"];
        setSelectedPin(true, screen ? screen?.uid : user?.uid);
        dispatch(updatedSelectedPresenterID(presenterUid));
    }, [presenterUid]);

    return null;
}
