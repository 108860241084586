export const setMicStatus = (flag) =>
({
    type: "SET_MIC_STATUS",
    payload: { value: flag }
})

export const setLastActiveSpeakerUid = (flag) =>
({
    type: "SET_LAST_ACTIVE_SPEAKER_UID",
    payload: { value: flag }
})

export const setCurrentSpeakerUid = (flag) =>
({
    type: "SET_CURRENT_SPEAKER_UID",
    payload: { value: flag }
})

export const setFrequentSpeakers = (update) =>
({
    type: "SET_FREQUENT_SPEAKERS",
    payload: update
})

export const setAudioDevices = (update) =>
({
    type: "SET_AUDIO_DEVICES",
    payload: update
})

export const setCurrentAudioDevice = (update) =>
({
    type: "SET_CURRENT_AUDIO_DEVICE",
    payload: update
})

export const setAudioPermissionDenied = (flag) =>
({
    type: "SET_AUDIO_PERMISSION_DENIED",
    payload: { value: flag }
})

export const updateSpeakerStatus = (update) =>
({
    type: "UPDATE_SPEAKER_STATUS",
    payload: update
})