import store from "../../redux/store";
import { getToday, getTodayDateISO } from "../utils/Utils";
//Teachmint client ID
export const googleClient = {
  apiKey: 'AIzaSyCORqll-5JSfzTcXGjIZ2m7dvUdQlFQ6sE',
  clientId: '554736302166-9drkm25jg6uhl6nenl35u1ttgsmasg17.apps.googleusercontent.com',
  discoveryDocs: [
    'https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest'
  ],
  scope: 'https://www.googleapis.com/auth/youtube.force-ssl'
}

export const utilLoadGoogleScript = () => {
  //loads the Google JavaScript Library
  (function () {
    const id = 'google-js';
    const src = 'https://apis.google.com/js/platform.js';

    //we have at least one script (React)
    const firstJs = document.getElementsByTagName('script')[0];

    //prevent script from loading twice
    if (document.getElementById(id)) { return; }
    const js = document.createElement('script');
    js.id = id;
    js.src = src;
    js.onload = window.onGoogleScriptLoad;
    firstJs.parentNode.insertBefore(js, firstJs);
  }());
}

export const onSignIn = () => {
  console.log("attempt sign in");
  window.gapi.auth2.getAuthInstance().signOut();
  window.gapi.auth2.getAuthInstance().disconnect();
  window.gapi.auth2.getAuthInstance().signIn();
};

export const createBroadcast = (title, status) => {
  return new Promise((resolve, reject) => {
    window.gapi.client.youtube.liveBroadcasts.insert({
      "part": [
        "snippet,contentDetails,status"
      ],
      "resource": {
        "snippet": {
          "title": title,
          "scheduledStartTime": getTodayDateISO()
        },
        "status": {
          "privacyStatus": status.toLowerCase()
        },
        "contentDetails": {
          "enableMonitorStream": false,
          "enableAutoStart": true,
          "enableAutoStop": true
        }
      }
    })
      .then((res) => {
        const { id } = res.result;
        res.status === 200 ? resolve({ broadcastId: id })
          : reject({ errorOccured: true })
      })
      .catch((err) => {
        reject({ errorOccured: true })
      })
  })
}

export const getLiveStream = async () => {
  try {
    const { classInfo } = store.getState().videoroom
    const { streams } = await getListStreams()
    const { items } = streams.result;
    let tStream = items.find((item) => {
      let title = item.snippet.title;
      title = title.toLowerCase();
      return (
        title &&
        title.includes("teachmint") &&
        title.includes(classInfo.name)
      );
    });
    if (!tStream || items.length <= 0) {
      tStream = await createLiveStream(classInfo.name);
    }
    return { stream: tStream }
  }
  catch { }
}

export const getListStreams = () => {
  return new Promise((resolve, reject) => {
    window.gapi.client.youtube.liveStreams.list({
      "part": [
        "snippet,cdn,contentDetails,status"
      ],
      "mine": true
    })
      .then((res) => {
        res.status === 200 ? resolve({ streams: res })
          : reject({ errorOccured: true })
      })
      .catch(() => {
        reject({ errorOccured: true })
      })
  })
}

const createLiveStream = (class_name) => {
  return new Promise((resolve, reject) => {
    window.gapi.client.youtube.liveStreams.insert({
      "part": [
        "snippet,cdn"
      ],
      "resource": {
        "snippet": {
          "title": `Teachmint ${class_name} ${getToday().date}`,
        },
        "cdn": {
          "ingestionType": "rtmp",
          "frameRate": "variable",
          "resolution": "variable"
        }
      }
    })
      .then((res) => {
        res.status === 200 ? resolve(res.result)
          : reject({ errorOccured: true })
      })
      .catch((err) => {
        reject({ errorOccured: true })
      })
  })
}

export const bindBroadcastAndStream = (broadcastId, streamId) => {
  return new Promise((resolve, reject) => {
    window.gapi.client.youtube.liveBroadcasts.bind({
      "id": broadcastId,
      "part": [
        "id"
      ],
      "streamId": streamId
    })
      .then((res) => {
        res.status === 200 ? resolve({ broadcast: res.result })
          : reject({ errorOccured: true })
      })
      .catch((err) => {
        reject({ errorOccured: true })
      })
  })
}

export const getLiveStreamStatus = (streamId) => {
  return window.gapi.client.youtube.liveStreams.list({
    "part": [
      "snippet,cdn,contentDetails,status"
    ],
    "id": [
      streamId
    ]
  })
}

export const transitionBroadcast = (broadcastId, status) => {
  return new Promise((resolve, reject) => {
    window.gapi.client.youtube.liveBroadcasts.transition({
      part: ['id,snippet,contentDetails,status'],
      broadcastStatus: status,
      id: broadcastId,
    })
      .then((res) => {
        console.log(res);
        res.status === 200 ? resolve({ [status]: true })
          : reject({ [status]: false })
      })
      .catch((err) => {
        reject({ [status]: false })
      })
  })
}

export const pollYoutubeApi = ({ apiFunction, id, validate, interval, maxAttempts }) => {
  let attempts = 0;
  const executePoll = async (resolve, reject) => {
    try {
      const result = await apiFunction(id);
      attempts++;
      if (validate(result)) {
        return resolve(result);
      } else if (maxAttempts && attempts === maxAttempts) {
        return reject(new Error('Exceeded max attempts'));
      } else {
        setTimeout(executePoll, interval, resolve, reject);
      }
    }
    catch { }
  };

  return new Promise(executePoll);
}

export const getBasicProfile = () => {
  const currentInstance = window.gapi.auth2.getAuthInstance();
  const profile = currentInstance.currentUser.get().getBasicProfile();
  const basicProfile = {
    name: profile.getName(),
    imgUrl: profile.getImageUrl(),
    email: profile.getEmail(),
  };
  return ({ basicProfile: basicProfile })
}