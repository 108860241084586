const initalState = {
    endClassForAll: false,
    endClassForSelf: false,
    saveAndClose: false,
    showEndClassPage: false,
    classEnded: false,
};

export function closeReducer(state = initalState, { type, payload = {} }) {
    let newState = { ...state };
    const { settings } = payload;
    switch (type) {
        case "UPDATE_END_CLASS_SETTINGS":
            for (let setting in settings) {
                newState[setting] = settings[setting];
            }
            return newState;
        default:
            return state;
    }
}
