import { createStore, combineReducers } from "redux";
import { usersReducer } from "./reducers/users";
import { videoroomReducer } from "./reducers/videoroom";
import { permissionsReducer } from "./reducers/permissions";
import { pollsReducer } from "./reducers/polls";
import { roomSettingsReducer } from "./reducers/roomSettings";
import toastReducer from "./reducers/toasts";
import { chatReducer } from "./reducers/chat";
import { presenterReducer } from "./reducers/presenter";
import modalReducer from "./reducers/modals";
import { audioReducer } from "./reducers/audio";
import { streamingReducer } from "./reducers/streaming"
import { videoReducer } from "./reducers/video";
import { closeReducer } from "./reducers/close";
import { observerUserReducer } from "./reducers/observerUsers"

const store = createStore(
        combineReducers({
                users: usersReducer,
                videoroom: videoroomReducer,
                permissions: permissionsReducer,
                polls: pollsReducer,
                roomSettings: roomSettingsReducer,
                chat: chatReducer,
                toasts: toastReducer,
                presenter: presenterReducer,
                modals: modalReducer,
                audio: audioReducer,
                video: videoReducer,
                streaming: streamingReducer,
                closeClassRoom: closeReducer,
                observerUsers: observerUserReducer
        })
);

export default store;
