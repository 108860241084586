import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RECORD_LOG, ROOM_STATE, STATUS, ROOM_META } from "../../../constants/Enums";
import { getReadableTime, getTimestamp } from "../../utils/Utils";
import { RecordingOff, RecordingOn } from "../../images";
import styles from "./recording.module.css";
import WSConnection from "../../connections/WSConnection";

export default function Timer({}) {
    const wsConnection = new WSConnection();

    const [time, setTime] = useState(0);
    const { recordingActivity, recording, recordingTimer, device } = useSelector(
        (state) => state.videoroom
    );

    const getStartTime = () => {
        let count = 0;
        if (recordingActivity) {
            recordingActivity.forEach((recording) => {
                if (recording) {
                    if (recording[RECORD_LOG.STOP_TIME]) {
                        count = count + (recording[RECORD_LOG.DURATION] || 0);
                    } else {
                        const timestamp = getTimestamp() + wsConnection.timestampMargin;
                        count = count + (timestamp - recording[RECORD_LOG.START_TIME]);
                    }
                }
            });
        }
        return count;
    };

    useEffect(() => {
        let recordingInterval = null;
        if (recording === true) {
            let count = getStartTime();
            setTime(count);

            recordingInterval = setInterval(() => {
                setTime((time) => time + 1);
            }, 1000);
        }
        return () => {
            clearInterval(recordingInterval);
        };
    }, [recording, recordingActivity]);

    return (
        <div className={`recording-display ${device.mobile ? styles.timer_mobile : ""}`}>
            {/* <span className="recording-animation">
                <span className="recording-anim" />
            </span> */}
            {device.mobile ? null : (
                <span>
                    <img alt="recording" src={RecordingOn} />
                </span>
            )}
            <span className={`recording-timer`}>{getReadableTime(time)}</span>
        </div>
    );
}
