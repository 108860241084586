import { VIDEO_OPTIONS, QUALITY, RS } from "../../constants/Enums";
const initalState = {
    settings: {
        [RS.IS_MY_MIC_ON]: true,
        [RS.IS_MY_CAMERA_ON]: true,
        [RS.IS_PREVIEW_RECORDING_ON]: false,
        [RS.IS_FRONT_CAMERA]: false,
        [RS.IS_PORTRAIT_VC]: false,
        [RS.IS_MIC_BLOCKED]: false,
        [RS.IS_VIDEO_BLOCKED]: false,
        [RS.IS_CHAT_BLOCKED]: false,
        [RS.VIDEO_QUALITY]: VIDEO_OPTIONS[QUALITY.HIGH],
    },
    sendMicSetting: false,
    sendVideoSetting: false,
    sendChatSetting: false
};

export function roomSettingsReducer(state = initalState, { type, payload = {} }) {
    let newState;
    let newSettings;
    let { setting, value } = payload
    switch (type) {
        case "UPDATE_ROOM_SETTING":
            newSettings = { ...state.settings }
            newSettings[setting] = value;
            newState = { ...state, settings: newSettings };
            return newState;
        case "SET_STUDENT_MIC_SETTING":
            newSettings = { ...state.settings }
            newSettings[RS.IS_MIC_BLOCKED] = value;
            newState = { ...state, settings: newSettings };
            return newState;
        case "SET_STUDENT_VIDEO_SETTING":
            newSettings = { ...state.settings }
            newSettings[RS.IS_VIDEO_BLOCKED] = value;
            newState = { ...state, settings: newSettings };
            return newState;
        case "SET_STUDENT_CHAT_SETTING":
            newSettings = { ...state.settings }
            newSettings[RS.IS_CHAT_BLOCKED] = value;
            newState = { ...state, settings: newSettings };
            return newState;
        case "SET_SEND_MIC_SETTING":
            newState = { ...state };
            newState.sendMicSetting = value;
            return newState;
        case "SET_SEND_VIDEO_SETTING":
            newState = { ...state };
            newState.sendVideoSetting = value;
            return newState;
        case "SET_SEND_CHAT_SETTING":
            newState = { ...state };
            newState.sendChatSetting = value;
            return newState;

        default:
            return state;
    }
}