export const hindiLang = {
  teacher: 'शिक्षक',
  student: 'छात्र',
  somethingWentWrongText: 'कुछ गलत हो गया',
  saveButtonText: 'सहेजें',
  createClassroomCancelButtonText: 'रद्द करें',
  createHomeworkContinueButtonText: 'जारी रखें',
  goLiveText: 'लाइव जाओ',
  dashboardSubheaderTextChat: 'बातचीत',
  feedbackText: 'प्रतिपुष्टि',
  required: 'आवश्यक',
  backButtonText: 'पीछे जाओ',
  goLiveClassRecordingOnText: 'कक्षा रिकॉर्डिंग चालू है',
  goLiveClassRecordingOffText: 'कक्षा रिकॉर्डिंग बंद है',
  goLiveMicrophoneOnText: 'माइक्रोफ़ोन चालू है',
  goLiveMicrophoneOffText: 'माइक्रोफ़ोन बंद है',
  microphoneText: 'माइक्रोफ़ोन',
  cameraText: 'कैमरा',
  goLiveRecordingOnText: 'रिकॉर्डिंग चालू है',
  goLiveRecordingOffText: 'रिकॉर्डिंग बंद है',
  goLiveVideoButtonText: 'वीडियो',
  goLiveMoreButtonText: 'अतिरिक्त',
  goLiveVideoOnText: 'वीडियो चालू है',
  goLiveVideoOffText: 'वीडियो बंद है',
  goLiveMicButtonText: 'माइक',
  goLiveMicOnText: 'माइक चालू है',
  goLiveMicOffText: 'माइक बंद है',
  goLiveAllowStudentToChatText: 'छात्रों को बात करने दें',
  goLiveEmptyChatText: 'यहाँ अभी तक कोई संदेश नहीं है...',
  goLiveEmptyChatSubText: 'अपनी लाइव क्लास चैट के साथ आरंभ करें',
  goLiveInteractWithStudentsText: 'अपने छात्रों के साथ बातचीत करें...',
  goLiveInteractWithClassmatesText: 'अपने सहपाठियों के साथ बातचीत करें...',
  goLiveDisabledStudentToChatText: 'शिक्षक ने चैट को अक्षम कर दिया है',
  goLiveOpenWhiteboardText: 'व्हाइटबोर्ड खोलें',
  goLiveShareMyScreenText: 'स्क्रीन साझा करें',
  goLiveShareFilesButtonText: 'फ़ाइलें बाटें',
  goLiveStudentControlsButtonText: 'छात्र नियंत्रण',
  goLiveStartRecordingButtonText: 'रिकॉर्डिंग शुरू करें',
  goLiveStopRecordingButtonText: 'रिकॉर्डिंग रोकें',
  goLivePauseRecordingButtonText: 'रिकॉर्डिंग रोकें',
  goLivePeopleButtonText: 'लोग',
  goLivePollsButtonText: 'चुनाव',
  goLiveButtonStudentDashboardText: 'जब आप कक्षा में शामिल होंगे तो आपका ऑडियो और वीडियो बंद होगा',
  goLiveStudentDashboardEnterTheClassroomButtonText: 'कक्षा में प्रवेश करें',
  goLiveStudentDashboardWaitingForTeacherText: 'शिक्षक की प्रतीक्षा में',
  goLiveStudentDashboardNotEnrolledText: 'आप कक्षा में नामांकित नहीं हैं। कृपया कक्षा आईडी के लिए शिक्षक से संपर्क करें',
  goLiveStudentDashboardExitButtonText: 'प्रस्थान',
  goLiveStudentDashboardHandRaiseText: 'हाथ उठाना',
  goLiveStudentDashboardHandLowerText: 'हाथ नीचे करें',
  goLiveStudentDashboardAudioOnlyText: 'सिर्फ़ ध्वनि',
  goLiveStudentDashboardReportAbuseText: 'दुरुपयोग होने की सूचना दें',
  goLiveStudentDashboardSubmitFeedbackText: 'प्रतिपुष्टि दें',
  goLiveClassroomEndedButtonText: 'कक्षा समाप्त',
  linkButton: 'लिंक कॉपी करें',
  createNoticeBoardCreateButtonText: 'सृजन करना',
  closeButtonText: 'बंद करे',
  dashboardStudentsCopyClipboard: 'क्लिपबोर्ड पर नकल',
  goLiveTeacherDashboardStreamLiveOnYoutubeText: 'YouTube पर लाइव स्ट्रीम करें',
  goLiveTeacherDashboardStreamLiveCustomText: 'अन्य प्लेटफॉर्म पर कस्टम लाइव स्ट्रीम करें',
  goLiveTeacherDashboardExitWhiteboardText: 'व्हाइटबोर्ड से बाहर निकलें',
  goLiveTeacherDashboardExitWhiteBoardExitContentText: 'क्या आप बाहर निकलने से पहले इस व्हाइटबोर्ड को सहेजना चाहेंगे?',
  goLiveTeacherDashboardExitWhiteBoardCloseText: 'वैसे भी बंद करें',
  goLiveTeacherDashboardWhiteBoardNoHandRaisedText: 'अभी तक कोई हाथ नहीं उठा',
  goLiveTeacherDashboardWhiteBoardLowerAllHandsText: 'सभी हाथ नीचे करें',
  goLiveTeacherDashboardWaitingRoomText: 'प्रतीक्षालय',
  goLiveInstantPollText: 'तत्काल मतदान शुरू करें',
  goLiveCreateNewPollText: 'नया पोल बनाएं',
  goLivePollsLiveButtonText: 'प्रक्षेपण',
  goLivePollsEmptyText: 'पोल बनाकर अपनी कक्षा में सहभागिता बढ़ाएँ',
  goLiveTeacherDashboardMuteAllText: 'सभी को म्यूट करें',
  goLiveTeacherDashboardYoutubeStreamingLiveText: 'YouTube स्ट्रीमिंग लाइव',
  goLiveTeacherDashboardScreenSharingText: 'आप अपनी स्क्रीन सभी के सामने प्रस्तुत कर रहे हैं',
  goLiveTeacherDashboardStopPresentingText: 'प्रस्तुत करना बंद करें',
  goLiveTeacherDashboardLeaveClassText: 'कक्षा छोड़ें',
  goLiveEndClassButtonSubText: 'सभी प्रतिभागियों के लिए कक्षा समाप्त करें?',
  goLiveEndClassButtonText: 'सभी के लिए अंतिम कक्षा',
  goLiveTeacherDashboardLeaveTheClassLetOthersContinueText: 'केवल आप कक्षा छोड़ना चाहते हैं और दूसरों को जारी रखना चाहते हैं?',
  feedbackLabel: 'हमें बताएं कि क्या आप किसी समस्या का सामना कर रहे हैं। हम आपसे फिर बात करेंगे।',
  sendFeedbackButton: 'प्रतिक्रिया भेजें',
  yesButtonText: 'हां',
  noButtonText: 'नहीं',
  secondText: 'सेकंड',
  allChangesLostText: 'क्या आपको यकीन है? सभी परिवर्तन खो जाएंगे।',
  offlineText: 'ऑफलाइन',
  scoreText: 'स्कोर',
  rankText: 'पद',
  playingPictureAndPictureModeText: 'पिक्चर-इन-पिक्चर मोड में बजाना',
  stoppingPictureAndPictureModeText: 'पिक्चर-इन-पिक्चर बंद करो',
  clearAllText: 'सभी साफ करें',
  clearAllAnnotationToolsText: 'क्या आप वाकई सभी साफ़ करना चाहते हैं?',
  answeredSmallText: 'जवाब',
  stopInstantPollText: 'तत्काल मतदान बंद करो',
  viewPollAfterpollEnds: 'मतदान समाप्त होने के बाद यहां देखें मतदान विश्लेषण',
  screenPollStudentsText: 'साझा स्क्रीन पोल बन जाएगी और छात्र ए, बी, सी और डी में से एक विकल्प चुन सकते हैं',
  selectPollDurationText: 'मतदान की अवधि चुनें',
  insecondsText: 'कुछ लम्हों में',
  markCorrectAnswerText: 'सही उत्तर चिह्नित करें',
  stopPollText: 'मतदान बंद करो',
  pickedCorrectAnswerText: 'आह! किसी ने सही उत्तर नहीं चुना',
  leaderboardText: 'लीडरबोर्ड',
  connectedAnotherDeviceText: 'दूसरे डिवाइस से कनेक्टेड',
  continueCurrentSession: 'आप किसी अन्य सिस्टम या ब्राउज़र से कक्षा में शामिल हुए हैं। क्या आप वर्तमान सत्र को जारी रखना चाहते हैं?',
  noOnePickedText: 'किसी ने नहीं चुना',
  instantPollCompletedText: 'तत्काल मतदान पूरा हुआ',
  createPollSettings: 'मतदान सेटिंग बनाएं',
  youSureStopText: 'क्या आप वाकई इसे रोकना चाहते हैं',
  instantText: 'तुरंत',
  pollText: 'मतदान?',
  stopInstantText: 'तुरंत बंद करो',
  pollDurationToLaunch: 'लॉन्च करने के लिए मतदान की अवधि चुनें',
  correctAnswerToLaunch: 'लॉन्च करने के लिए सही उत्तर चिह्नित करें',
  pollBeingLaunchedText: 'तत्काल मतदान शुरू किया जा रहा है',
  pollIsBeingLaunched: 'मतदान शुरू किया जा रहा है',
  teacherLaunchingPollText: 'शिक्षक एक मतदान शुरू कर रहा है',
  pollAnswerFromHere: 'मतदान का उत्तर यहां से चुनें',
  pollStartsText: 'मतदान शुरू',
  studentDisabledLivePollText: 'लाइव पोल के दौरान छात्रों को म्यूट कर दिया जाता है और चैट अक्षम कर दी जाती है',
  pollQuestionOptionsText: 'मतदान प्रश्न और विकल्प दर्ज करें',
  enterPollQuestionText: 'मतदान प्रश्न दर्ज करें',
  enterPollQuestionContinueText: 'जारी रखने के लिए कृपया मतदान प्रश्न दर्ज करें',
  enterPollOptionsText: 'मतदान विकल्प दर्ज करें',
  enterLeast2OptionsContinue: 'जारी रखने के लिए कम से कम 2 विकल्प दर्ज करें',
  enterAllOptionsContinue: 'जारी रखने के लिए कृपया सभी विकल्प दर्ज करें',
  addAnotherOptionText: '+ एक और विकल्प जोड़ें',
  durationOfPollText: 'मतदान की अवधि(सेकंड में)',
  selectPollDurationContinueText: 'जारी रखने के लिए मतदान की अवधि चुनें',
  markCorrectAnswerContinueText: 'जारी रखने के लिए सही उत्तर को चिह्नित करें',
  launchPollText: 'पोल लॉन्च करें',
  youtubeText: 'यूट्यूब',
  streamCustomLiveText: 'कस्टम लाइव स्ट्रीम करें',
  enterURLText: 'यू आर एल दर्ज करो',
  liveStreamKeyText: 'लाइव स्ट्रीम कुंजी',
  enterText: 'प्रवेश करना',
  enterLiveStreamKeyText: 'लाइव स्ट्रीम कुंजी दर्ज करें',
  startYouTubeLiveStreamText: 'यूट्यूब लाइव स्ट्रीम शुरू करें',
  startLiveStream: 'लाइव स्ट्रीम शुरू करें',
  youtubeStreamKey: 'यूट्यूब स्ट्रीम कुंजी कैसे खोजें?',
  youTubeTermsConditionsText: 'यूट्यूब नियम और शर्तें',
  signInWithGoogleText: 'यूट्यूब नियम और शर्तें',
  enterYouTubeLiveStreamKeyText: 'YouTube लाइव स्ट्रीम कुंजी दर्ज करें',
  stopLiveStreaming: 'क्या आप वाकई लाइव स्ट्रीमिंग बंद करना चाहते हैं?',
  stopStreamingText: 'स्ट्रीमिंग बंद करो',
  stopText: 'विराम',
  disabledAudioOnlyModeText: 'आपने केवल ऑडियो मोड को अक्षम कर दिया है',
  enabledAudioOnlyModeText: 'आपने केवल ऑडियो मोड सक्षम किया है',
  submittedYourResponseText: 'आपकी प्रतिक्रिया के रूप में विकल्प <1> </1> सबमिट किया गया है',
  handRaiseDuringPollText: 'मतदान के दौरान हाथ उठाना अक्षम',
  pollCompletedText: 'मतदान संपन्न',
  mutedTeacherText: 'आपको शिक्षक ने म्यूट कर दिया है',
  audioHasBeenDisabledText: 'ऑडियो अक्षम कर दिया गया है',
  videoHasBeenDisabledText: 'वीडियो अक्षम कर दिया गया है',
  chatHasBeenDisabledText: 'चैट अक्षम कर दी गई है',
  youText: 'आप',
  classroomInfoNotFetch: 'क्लासरूम की जानकारी नहीं मिल पाई ',
  couldNotConnectServer: 'सर्वर से कनेक्ट नहीं हो सके ',
  checkNetworkAndTry: 'कृपया अपने नेटवर्क चेक करें और दोबारा कोशिश करें ',
  rejoin: 'फिर से जॉइन करें ',
  roomId: 'रूम Id',
  userId: 'यूज़र Id',
  hotkey: 'हॉटकी',
  submit: 'Submit',
  gotoErr: 'एरर पर जाएँ ',
  classStatus: 'क्लास स्टेटस ',
  enableAudioPermissions: 'ऑडियो परमिशन एनेबल करें',
  switchOffMic: 'माइक बंद करें ',
  switchOnMic: 'माइक चालू करें',
  enableVideoPermissions: 'वीडियो परमिशन एनेबल करें',
  switchOffCamera: 'कैमरा बंद करें ',
  switchOnCamera: 'कैमरा चालू करें',
  HD: 'HD',
  High: 'हाई',
  Low: 'कम',
  hasStartedRecording: 'ने रिकॉर्डिंग शुरू कर दी है ',
  hasPausedRecording: 'ने रिकॉर्डिंग रोक दी है ',
  hasStartedStreaming: 'ने स्ट्रीमिंग शुरू कर दी है ',
  hasStoppedStreaming: 'ने स्ट्रीमिंग बंद कर दी है ',
  shareWhiteboard: 'व्हाइटबोर्ड शेयर करें ',
  allowStudentMic: 'स्टूडेंट के माइक को अनुमति दें',
  allowStudentCamera: 'स्टूडेंट के कैमरे की अनुमति दें',
  classPreference: 'क्लास प्रेफ़रेंस ',
  feedbackSuggestions: 'फीडबैक और सुझाव',
  systemInformation: 'सिस्टम की जानकारी',
  networkDeviceStats: 'नेटवर्क और डिवाइस के स्टैट्स ',
  audioOptions: 'ऑडियो विकल्प',
  audioOutput: 'ऑडियो आउटपुट',
  connected: 'कनेक्ट किए गए हैं ',
  noAudioAvailable: 'कोई ऑडियो उपलब्ध नहीं है',
  videoOptions: 'वीडियो विकल्प',
  noCameraAvailable: 'कोई कैमरा उपलब्ध नहीं है',
  videoQuality: 'विडियो की क्वालिटी ',
  liveVideoStreaming: 'लाइव वीडियो स्ट्रीमिंग',
  stopYoutubeStream: 'यूट्यूब स्ट्रीमिंग बंद करें ',
  stopSharing: 'शेयर करना शुरू करें',
  stopSharingScreen: 'स्क्रीन शेयर करना बंद करें',
  stopSharingWhiteboard: 'व्हाइटबोर्ड शेयर करना बंद करें',
  stopSharingFile: 'फ़ाइल शेयर करना बंद करें',
  takePresenterRole: 'प्रज़ेंटर का रोल लें',
  takeOverPresenterRole: '{{name}} के पास वर्तमान में प्रज़ेंटर का रोल है। क्या आप यह रोल लेना चाहते हैं?',
  openNewWhiteboard: 'नया व्हाइटबोर्ड खोलें',
  resumePreviousWhiteboard: 'पिछले व्हाइटबोर्ड को फिर से शुरू करें',
  selectFile: 'कोई फाइल चुनें ',
  openNewFile: 'नई फ़ाइल खोलें',
  resumePreviousFile: 'पिछली फ़ाइल फिर से शुरू करें',
  saveExit: 'सेव करें और एग्ज़िट हों ',
  micBlockedByTeacher: 'टीचर ने माइक ब्लॉक कर दिया है',
  videoBlockedByTeacher: 'टीचर ने वीडियो ब्लॉक कर दिया है',
  raiseHand: 'हाथ ऊपर करें',
  showMoreOptions: 'और विकल्प दिखाएँ ',
  backToVideoScreen: '"वापस वीडियो स्क्रीन में जाने के लिए यहाँ टैप करें"',
  feedbackSubmittedSuccessfully: 'फीडबैक सफलतापूर्वक सबमिट किए गए ',
  disableMic: 'माइक को डिसएबल करें ',
  allowToSpeak: 'बोलने की अनुमति दें',
  mute: 'म्यूट',
  allowMicUsage: 'माइक के इस्तेमाल की अनुमति दें',
  allowCameraUsage: 'कैमरा के इस्तेमाल  की अनुमति दें',
  allowChatUsage: 'चैट के इस्तेमाल की अनुमति दें',
  switchCamera: 'कैमरा बदलें ',
  studentAccess: 'स्टूडेंट का एक्सेस ',
  liveVideoStreamingLower: 'लाइव वीडियो स्ट्रीमिंग',
  startPoll: 'पोल शुरू करें',
  livePolls: 'लाइव पोल',
  sharedScreenBecomePoll: 'शेयर की गई स्क्रीन पोल बन जाएगी',
  launchInstantPoll: 'इंस्टेंट पोल लॉन्च करें',
  studentAccessUpper: 'स्टूडेंट का एक्सेस ',
  streamOnYoutbe: 'यूट्यूब पर स्ट्रीम करें',
  streamOnOtherPlatform: 'दूसरे प्लेटफॉर्म पर स्ट्रीम करें',
  unpin: 'अनपिन करें',
  pin: 'पिन करें ',
  hasPresenterAccess: 'के पास प्रज़ेंटर की एक्सेस है ',
  doYouWanToGive: 'क्या आप इसे देना चाहते हैं',
  confirmGivePresenterAccess: ' स्क्रीन, व्हाइटबोर्ड और फ़ाइलें शेयर कर पाएँगे। क्या आप सुनिश्चित हैं?',
  coTeacher: 'को-टीचर ',
  presenter: 'प्रज़ेंटर',
  stopAccess: 'एक्सेस रोकें',
  makePresenter: 'प्रेज़न्टर बनाएं',
  givePresenterAccess: 'प्रेज़न्टर एक्सेस दें',
  giveAccess: 'एक्सेस दें',
  stopPresenterAccess: 'प्रज़ेंटर की एक्सेस रोकें',
  cannotGivePresenterAccess: 'इस यूज़र को प्रेज़न्टर एक्सेस नहीं दिया जा सकता है',
  gotIt: 'समझ गए',
  askToUpdateApp: '{{name}} ने अपना ऐप अपडेट नहीं किया है। उन्हें प्रज़ेंटर बनाने के लिए अपने एप्लिकेशन को अपडेट करने के लिए कहें',
  leaderboard: 'लीडरबोर्ड',
  answered: 'जवाब दिया गया ',
  notAnswered: 'जवाब नहीं दिया गया',
  isCorrect: 'सही है',
  isWrong: ' गलत है',
  correctAnswer: 'सही जवाब',
  answeredCorrectly: 'सही जवाब दिया',
  everybodyAnswered: 'अरे वाह! सभी ने जवाब दिया',
  wrongAnswer: 'गलत जवाब!',
  correctAnswerUpper: 'सही जवाब!',
  line: '"रेखा"',
  ellipse: '"अंडाकार"',
  rectangle: '"आयत"',
  triangle: 'त्रिकोण',
  circle: '"वृत्त"',
  fileCorrupted: 'फ़ाइल खराब है। डॉक्युमेंट नहीं खोल पा रहे हैं ',
  youSavedWhiteboard: 'आपने व्हाइटबोर्ड सेव कर लिया है',
  youtubeLiveStreamKey: 'यूट्यूब लाइव स्ट्रीम की',
  liveStreamNotEnabled: '<1> </1> पर लाइव स्ट्रीमिंग एनेबल नहीं है। कृपया लाइव स्ट्रीमिंग एनेबल करें या उस अकाउंट में लॉग इन करें जिसमें लाइव स्ट्रीमिंग एनेबल है ',
  enterLiveStreamTitle:'अपनी लाइव स्ट्रीमिंग के लिए एक टाइटल डालें ',
  connectedTitle: 'कनेक्ट किए गए हैं ',
  public: 'पब्लिक ',
  private: 'प्राइवेट ',
  unlisted: 'अनलिस्टेड ',
  notMe: 'मैं नहीं',
  broadcastOnYoutubeLive: 'Teachmint क्लासरूम यूट्यूब लाइव पर ब्रॉडकास्ट करें',
  titleOfStream: 'यूट्यूब स्ट्रीम का टाइटल',
  enterYoutubeLiveStreamTitle: 'Teachmint क्लासरूम यूट्यूब लाइव स्ट्रीम का टाइटल यहाँ डालें ',
  privacySettings: 'प्राइवेसी सेटिंग्स ',
  goLiveOnYoutube: 'यूट्यूब पर लाइव हों ',
  liveStreamByYoutube: 'लाइव स्ट्रीम सर्विस यूट्यूब द्वारा दी जाती है ',
  googlePrivacyPolicy: 'गूगल प्राइवेसी पॉलिसी ',
  termsOfService: 'सर्विस की शर्तें',
  apply: 'अप्लाई करें ',
  canRevokeAccessToData: 'आप अपने डेटा की एक्सेस वापस ले सकते हैं | एक्सेस करें',
  googleSecuritySettings: 'गूगल सिक्योरिटी सेटिंग्स ',
  page: 'पेज ',
  copyrightTeachmintTPL: 'कॉपीराइट 2021, टीचमिंट (Teachmint) टेक्नोलॉजीस प्राइवेट लिमिटेड।',
  privacyPolicy: 'गोपनीयता नीति',
  cannotUnmute: 'आप अनम्यूट नहीं कर सकते',
  teacherToJoin : 'टीचर ने अभी ज्वाइन नहीं किया है',
  streamingLiveIn: 'लाइव स्ट्रीमिंग होने वाली है...',
  youtubeStreamingFailed: 'यूट्यूब स्ट्रीमिंग फेल हो गई है ',
  tryAgain: 'दोबारा कोशिश करें',
  isSharingScreen: 'स्क्रीन शेयर कर रहे हैं।',
  isSharingScreenDisableAudio: ' स्क्रीन शेयर कर रहे हैं। कृपया सिर्फ ऑडियो मोड को डिसएबल करें।',
  youAreMuted: 'आपको म्यूट कर दिया गया है',
  youAreOnline: 'आप अब ऑनलाइन हैं',
  networkDisconnected: 'नेटवर्क डिस्कनेक्ट हो गया है। फिर से कनेक्ट हो रहा है',
  audioEnabled: 'ऑडियो एनेबल कर दिया गया है',
  videoEnabled: 'वीडियो एनेबल कर दिया गया है',
  chatEnabled: 'चैट एनेबल कर दी गई है',
  teacherStoppedPoll: 'टीचर ने पोल रोक दिया है ',
  hasSavedWhiteboard: 'ने व्हाइटबोर्ड सेव किया है',
  youArePresenterNow: 'आप प्रज़ेंटर हैं। अब आप स्क्रीन, व्हाइटबोर्ड और फ़ाइलें शेयर कर सकते हैं',
  yourAreNotPresenter: 'अब आप प्रज़ेंटर नहीं हैं। अब <1>  </1> प्रज़ेंट कर रहा है ',
  presentationAccessRevoked: 'Pप्रेज़ेंटेशन की एक्सेस वापस ले ली गई है। आपकी वर्तमान फ़ाइल की स्थिति इस सेशन के लिए सेव की गई है',
  isPresentingNow: 'प्रज़ेंट कर रहा है',
  teacherIsCreatingPoll: 'टीचर पोल तैयार कर रहा है ',
  youLeftClassroom: 'आपने क्लासरूम छोड़ दिया है ',
  liveStreamStopped: 'लाइव स्ट्रीम बंद हो गई है ',
  youtubeLiveStreamStarted: 'यूट्यूब लाइव स्ट्रीम शुरू हो गई है। अब आप यूट्यूब पर लाइव हैं',
  liveStreamFailed: 'ओह! लाइव स्ट्रीम विफल हो गई है।',
  youtubeLiveStreamFailed: 'ओह! यूट्यूब लाइव स्ट्रीम विफल हो गई है।',
  teacherEnabledyourMic: 'टीचर ने आपका माइक चालू कर दिया है',
  handLowered: 'आपका हाथ नीचे कर दिया गया है',
  loadingWhiteboard: 'व्हाइटबोर्ड लोड हो रहा है',
  loadingDocument: 'डॉक्युमेंट लोड हो रहा है',
  toolbarHidden: 'टूल बार छिपा हुआ (हिडन) है ',
  clickLockToViewToolbar: 'टूलबार को फिर से देखने के लिए लॉक पर क्लिक करें',
  clickLock: 'लॉक पर क्लिक करें',
  closeAnyway: 'कैसे भी बंद कर दें',
  saveWhiteboardBeforeExiting: 'क्या आप एग्ज़िट करने से पहले इस व्हाइटबोर्ड को सेव करना चाहेंगे',
  searchPeopleByName: 'लोगों को नाम से ढूँढें ',
  internetUnstable: 'आपका इंटरनेट कनेक्शन स्थिर नहीं है',
  showDetails: 'डिटेल दिखाएं',
  systemStats: 'सिस्टम स्टेटस',
  networkStability: 'नेटवर्क स्टेबिलिटी',
  networkStabilityTxt: "अस्थिर या कमजोर नेटवर्क के कारण ऑडियो ब्रेक, वीडियो रुक-रुक कर चलना, आदि जैसी समस्या आ सकती है।",
  packetLoss: "पैकेट लॉस",
  jitter: "जिटर",
  connectionDelay: "कनेक्शन डिले",
  publishBitrate: "बिटरेट पब्लिश करें",
  subscribeBitrate: "बिटरेट सब्सक्राइब करें",
  quickFixTip: "क्विक फिक्स टिप्स",
  quickFixTipOne: 'ज़्यादा इंटरनेट खर्च करने वाले ऐप का इस्तेमाल करने से बचें',
  quickFixTipTwo: "ब्राउज़र टैब्स या रनिंग बैकग्राउंड एप्लीकेशन बंद करें",
  quickFixTipThree: "अपने इंटरनेट की स्पीड चेक करें और ज़्यादा इंटरनेट स्ट्रेंथ वाले एरिया में जाएं"
};

export default hindiLang;
