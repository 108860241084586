const initialState = {
    chatMessageArray: [],
    unreadMsgCount: 0
}

export function chatReducer(state = initialState, { type, payload }) {
    let newState;
    switch (type) {
        case "ADD_CHAT_MESSAGE":
            let newChatMessageArray = [...state.chatMessageArray]
            newChatMessageArray.push(payload)
            newState = { ...state, chatMessageArray: newChatMessageArray };
            return newState;
        case "SET_UNREAD_MSG_COUNT":
            newState = { ...state };
            newState.unreadMsgCount = payload.value
            return newState
        default:
            return state;
    }
}