export const addUser = (user) => ({
    type: "USER_ADDED",
    payload: user,
});
export const removeUser = (value) => ({
    type: "USER_REMOVED",
    payload: { value: value },
});
export const updateUser = (updates) => ({
    type: "USER_UPDATED",
    payload: updates,
});
export const updateAllUsers = (updates) => ({
    type: "ALL_USERS_UPDATED",
    payload: updates,
});
export const resetUsers = () => ({
    type: "RESET_USERS",
});
export const bulkUserUpdate = (updates) => ({
    type: "BULK_USER_UPDATE",
    payload: updates,
});
