export const getPermissions = (utype) => {
    const permissions = {
        isTeacher: (utype === 1),
        canShareContentToTM: (utype === 1),
        isStudent: (utype === 2),
        canRaiseHand: (utype === 2),
        isViewer: (utype === 3),
        isCoTeacher: (utype === 11),
        canMuteTeacher: (utype === 11),

        hasMediaControls: !(utype === 3),
        shouldSendDummyStream: !(utype === 3),
        canGetMediaPermissions: !(utype === 3),
        canViewUserTiles: !(utype === 3),
        canViewTeacherTile: !(utype === 3),
        canViewParticipants: !(utype === 3),
        canPinAUser: !(utype === 3),

        isHost: (utype === 1 || utype === 11),
        canSetMicStatus: (utype === 1 || utype === 11),
        canSetCameraStatus: (utype === 1 || utype === 11),
        canSetChatStatus: (utype === 1 || utype === 11),
        canMuteAll: (utype === 1 || utype === 11),
        canLaunchPoll: (utype === 1 || utype === 11),
        canStopPoll: (utype === 1 || utype === 11),
        canSaveWhiteboard: (utype === 1 || utype === 11),
        canShareScreen: (utype === 1 || utype === 11),
        canSetRecordingStatus: (utype === 1 || utype === 11),
        canChangeVideoQuality: (utype === 1 || utype === 11),
        canEndClassForAll: (utype === 1 || utype === 11),
        canViewLivePollUpdates: (utype === 1 || utype === 11),
        canSavePreviewSettings: (utype === 1 || utype === 11),
        canSetPreviewSettings: (utype === 1 || utype === 11),
        canStartClass: (utype === 1 || utype === 11),
        canStream: (utype === 1 || utype === 11),
        showTeacherPreview: (utype === 1 || utype === 11),
        canMuteUser: (utype === 1 || utype === 11),
        canChangeStudentControls: (utype === 1 || utype === 11),
        canDropRaisedHand: (utype === 1 || utype === 11),
        canSetAllowMicForHandRaise : (utype === 1 || utype === 11),

        canParticipateInPoll: (utype === 2 || utype === 3),
        shouldCheckClassIsLive: (utype === 2 || utype === 3),
        shouldGotoWaitingRoom: (utype === 2 || utype === 3),
        canUpdateStudentControls: (utype === 2 || utype === 3),
        canEnableAudioOnly: (utype === 2 || utype === 3),
        hasNoMediaAtStart: (utype === 2 || utype === 3),
        showStudentPreview: (utype === 2 || utype === 3),

        canViewPollWinners: true,
        canChat: true,
        showRecorderTime: true,
    }
    return permissions
}