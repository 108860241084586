export const indonesiaLang = {
  teacher: 'Guru',
  student: 'Siswa',
  dashboardSubheaderTextChat: 'Obrolan',
  goLiveText: 'Mulai Live',
  cameraText: 'Kamera',
  microphoneText: 'Mikrofon',
  goLiveMicrophoneOnText: 'Mikrofon aktif',
  goLiveMicrophoneOffText: 'Mikrofon nonaktif',
  goLiveMicOnText: 'Mik aktif',
  goLiveMicOffText: 'Mik nonaktif',
  goLiveVideoOnText: 'Video aktif',
  goLiveVideoOffText: 'Video nonaktif',
  goLiveRecordingOnText: 'Perekaman aktif',
  goLiveRecordingOffText: 'Perekaman nonaktif',
  goLiveClassRecordingOnText: 'Perekaman Kelas aktif',
  goLiveClassRecordingOffText: 'Perekaman Kelas nonaktif',
  goLiveMicButtonText: 'Mik',
  goLiveVideoButtonText: 'Video ',
  goLiveStartRecordingButtonText: 'Mulai perekaman',
  goLiveStopRecordingButtonText: 'Stop perekaman',
  goLivePauseRecordingButtonText: 'Jeda perekaman',
  goLiveShareFilesButtonText: 'Bagikan File',
  goLiveStudentControlsButtonText: 'Kontrol Siswa',
  goLiveMoreButtonText: 'Selengkapnya',
  goLivePeopleButtonText: 'Orang',
  goLiveOpenWhiteboardText: 'Buka Papan Tulis',
  goLiveShareMyScreenText: 'Bagikan Layar Saya',
  goLiveAllowStudentToChatText: 'Izinkan siswa mengobrol',
  goLiveDisabledStudentToChatText: 'Guru telah menonaktifkan Obrolan',
  goLiveEmptyChatText: 'Belum ada pesan di sini...',
  goLiveEmptyChatSubText: 'Mulai dengan obrolan Kelas Live Anda',
  goLiveInteractWithStudentsText: 'Berinteraksi dengan siswa Anda',
  goLiveInteractWithClassmatesText: 'Berinteraksi dengan teman kelas Anda',
  goLivePollsButtonText: 'Polling',
  goLiveInstantPollText: 'Mulai polling instan',
  goLivePollsLiveButtonText: 'Luncurkan',
  goLiveCreateNewPollText: 'Buat polling baru',
  goLivePollsEmptyText: 'Tingkatkan interaksi di kelas Anda dengan membuat polling',
  goLiveEndClassButtonText: 'Akhiri Kelas untuk semua',
  goLiveEndClassButtonSubText: 'Akhiri kelas untuk semua peserta?',
  goLiveClassroomEndedButtonText: 'Ruang Kelas berakhir',
  linkButton: 'Salin Tautan',
  backButtonText: 'Kembali',
  closeButtonText: 'Tutup',
  createHomeworkContinueButtonText: 'Lanjutkan',
  saveButtonText: 'Simpan',
  feedbackText: 'Masukan',
  feedbackLabel: 'Sampaikan kepada kami jika Anda mengalami masalah. Kami akan segera menghubungi Anda.',
  required: 'Wajib diisi',
  sendFeedbackButton: 'Kirim masukan',
  createClassroomCancelButtonText: 'Batal',
  goLiveButtonStudentDashboardText: 'Audio dan video Anda akan nonaktif ketika Anda bergabung di ruang kelas',
  goLiveStudentDashboardEnterTheClassroomButtonText: 'Masuk ke Ruang Kelas',
  goLiveStudentDashboardExitButtonText: 'Keluar',
  goLiveStudentDashboardWaitingForTeacherText: 'Menunggu Guru',
  createNoticeBoardCreateButtonText: 'Buat',
  goLiveStudentDashboardNotEnrolledText: 'Anda tidak terdaftar di ruang kelas. Hubungi guru untuk mendapatkan ID ruang kelas',
  goLiveStudentDashboardHandRaiseText: 'Tangan Diangkat',
  goLiveStudentDashboardHandLowerText: 'Turunkan Tangan',
  goLiveStudentDashboardAudioOnlyText: 'Hanya audio',
  goLiveStudentDashboardReportAbuseText: 'Laporkan Penyalahgunaan',
  goLiveStudentDashboardSubmitFeedbackText: 'Kirim Masukan',
  dashboardStudentsCopyClipboard: 'Disalin ke papan klip',
  goLiveTeacherDashboardStreamLiveOnYoutubeText: 'Stream live di YouTube',
  goLiveTeacherDashboardStreamLiveCustomText: 'Lakukan stream live kustom di platform lain',
  goLiveTeacherDashboardExitWhiteboardText: 'Keluar dari Papan Tulis',
  goLiveTeacherDashboardExitWhiteBoardExitContentText: 'Apakah Anda ingin menyimpan papan tulis ini sebelum keluar?',
  goLiveTeacherDashboardExitWhiteBoardCloseText: 'Tetap Tutup',
  goLiveTeacherDashboardWhiteBoardNoHandRaisedText: 'Belum ada angkat tangan',
  goLiveTeacherDashboardWhiteBoardLowerAllHandsText: 'Turunkan semua tangan',
  goLiveTeacherDashboardWaitingRoomText: 'Ruang Tunggu',
  goLiveTeacherDashboardMuteAllText: 'Bisukan semua',
  goLiveTeacherDashboardYoutubeStreamingLiveText: 'Streaming Live YouTube',
  goLiveTeacherDashboardScreenSharingText: 'Anda menyajikan layar Anda kepada semua orang',
  goLiveTeacherDashboardStopPresentingText: 'Berhenti Menyajikan',
  goLiveTeacherDashboardLeaveClassText: 'Tinggalkan Kelas',
  goLiveTeacherDashboardLeaveTheClassLetOthersContinueText: 'Ingin tinggalkan kelas dan biarkan lainnya melanjutkan?',
  secondText: 'dtk',
  offlineText: 'Offline',
  somethingWentWrongText: 'Ada yang salah',
  allChangesLostText: 'Apa kamu yakin? Semua perubahan akan hilang.',
  noButtonText: 'tidak',
  yesButtonText: 'Ya',
  scoreText: 'Skor',
  rankText: 'Ranking',
  playingPictureAndPictureModeText: 'Bermain dalam mode gambar-dalam-gambar',
  stoppingPictureAndPictureModeText: 'Stop picture-in-picture',
  clearAllText: 'Bersihkan semua',
  clearAllAnnotationToolsText: 'Apakah Anda yakin ingin menghapus semua?',
  answeredSmallText: 'dijawab',
  stopInstantPollText: 'Hentikan Poll Instan',
  viewPollAfterpollEnds: ' Lihat analisis jajak pendapat di sini setelah jajak pendapat berakhir',
  screenPollStudentsText: 'Layar bersama akan menjadi jajak pendapat dan siswa dapat memilih satu opsi dari A, B, C & D',
  selectPollDurationText: 'Pilih Polling Durasi',
  insecondsText: 'dalam hitungan detik',
  markCorrectAnswerText: 'Tandai jawaban yang benar',
  stopPollText: 'Hentikan Poll.',
  pickedCorrectAnswerText: 'Ah! Tidak ada yang memilih jawaban yang benar',
  leaderboardText: 'Papan peringkat',
  connectedAnotherDeviceText: 'Terhubung dari perangkat lain',
  continueCurrentSession: 'Vyou telah bergabung dengan kelas dari sistem atau browser lain. Apakah Anda ingin melanjutkan sesi saat ini?',
  noOnePickedText: 'Tidak ada yang memilih',
  instantPollCompletedText: 'Jajak pendapat instan selesai',
  createPollSettings: 'Buat Pengaturan Polling ..',
  youSureStopText: 'Apakah Anda yakin ingin menghentikannya',
  instantText: 'instan.',
  pollText: 'pemilihan?',
  stopInstantText: 'Hentikan Instan.',
  pollDurationToLaunch: 'Pilih Durasi Polling untuk diluncurkan',
  correctAnswerToLaunch: 'Tandai jawaban yang benar untuk diluncurkan',
  pollBeingLaunchedText: 'Jajak pendapat instan sedang diluncurkan',
  pollIsBeingLaunched: 'POLL sedang diluncurkan',
  teacherLaunchingPollText: 'Guru meluncurkan jajak pendapat',
  pollAnswerFromHere: 'Pilih jawaban jajak pendapat dari sini',
  pollStartsText: 'Polling dimulai pada',
  studentDisabledLivePollText: 'Siswa diredam dan obrolan dinonaktifkan selama jajak pendapat langsung',
  pollQuestionOptionsText: 'Masukkan pertanyaan dan opsi jajak pendapat',
  enterPollQuestionText: 'Masukkan Pertanyaan Poll.',
  enterPollQuestionContinueText: 'Silakan masukkan pertanyaan jajak pendapat untuk melanjutkan',
  enterPollOptionsText: 'Masukkan opsi jajak pendapat',
  enterLeast2OptionsContinue: 'Masukkan setidaknya 2 opsi untuk melanjutkan',
  enterAllOptionsContinue: 'Silakan masukkan semua opsi untuk melanjutkan',
  addAnotherOptionText: '+ Nilai',
  durationOfPollText: 'Durasi jajak pendapat (dalam detik)',
  selectPollDurationContinueText: 'Pilih Durasi Polling untuk melanjutkan',
  markCorrectAnswerContinueText: 'Tandai jawaban yang benar untuk melanjutkan',
  launchPollText: 'Luncurkan Poll.',
  youtubeText: 'Youtube',
  streamCustomLiveText: 'Streaming Custom Hidup',
  enterURLText: 'Masukkan URL',
  liveStreamKeyText: 'kunci streaming langsung',
  enterText: 'Memasuki',
  enterLiveStreamKeyText: 'Masukkan kunci streaming langsung',
  startYouTubeLiveStreamText: 'Mulai Streaming Langsung YouTube',
  startLiveStream: 'Mulai Live Stream',
  youtubeStreamKey: 'Bagaimana cara menemukan Key Stream YouTube?',
  youTubeTermsConditionsText: 'Syarat dan Ketentuan YouTube',
  signInWithGoogleText: 'Masuk dengan Google',
  enterYouTubeLiveStreamKeyText: 'Masukkan Kunci Streaming Langsung YouTube',
  stopLiveStreaming: 'Apakah Anda yakin ingin menghentikan streaming langsung?',
  stopStreamingText: 'Berhenti streaming.',
  stopText: 'Berhenti',
  handRaiseDuringPollText: 'Naikkan tangan dinonaktifkan selama jajak pendapat',
  pollCompletedText: 'Polling selesai',
  mutedTeacherText: 'Anda telah diredam oleh guru',
  audioHasBeenDisabledText: 'Audio telah dinonaktifkan',
  videoHasBeenDisabledText: 'Video telah dinonaktifkan',
  chatHasBeenDisabledText: 'Obrolan telah dinonaktifkan',
  youText: 'Kamu',
  disabledAudioOnlyModeText: 'Anda telah menonaktifkan mode Audio Only',
  enabledAudioOnlyModeText: 'Anda telah mengaktifkan mode Audio Only',
  submittedYourResponseText: 'Opsi <1> </ 1> dikirim sebagai respons Anda',
  classroomInfoNotFetch: 'Tidak dapat mengambil info ruang kelas',
  couldNotConnectServer: 'Tidak dapat terhubung ke server',
  checkNetworkAndTry: 'Periksa jaringan Anda, lalu coba lagi',
  rejoin: 'Gabung kembali',
  roomId: 'Id Ruangan',
  userId: 'Id Pengguna',
  hotkey: 'HotKey',
  submit: 'Serahkan',
  gotoErr: 'Buka kesalahan',
  classStatus: 'Status kelas',
  enableAudioPermissions: 'Aktifkan Izin Audio',
  switchOffMic: 'Matikan Mik',
  switchOnMic: 'Nyalakan Mik',
  enableVideoPermissions: 'Aktifkan Izin Video',
  switchOffCamera: 'Matikan Kamera',
  switchOnCamera: 'Nyalakan Kamera',
  HD: 'HD',
  High: 'Tinggi',
  Low: 'Rendah',
  hasStartedRecording: 'telah memulai rekaman',
  hasPausedRecording: 'telah menjeda rekaman',
  hasStartedStreaming: 'telah memulai streaming',
  hasStoppedStreaming: 'telah menghentikan streaming',
  shareWhiteboard: 'Bagikan papan tulis',
  allowStudentMic: 'Izinkan Mik Siswa',
  allowStudentCamera: 'Izinkan Kamera Siswa',
  classPreference: 'PREFERENSI KELAS',
  feedbackSuggestions: 'MASUKAN & SARAN',
  systemInformation: 'INFORMASI SISTEM',
  networkDeviceStats: 'Statistik Jaringan & Perangkat',
  audioOptions: 'OPSI AUDIO',
  audioOutput: 'Output Audio',
  connected: 'terhubung',
  noAudioAvailable: 'Audio Tidak Tersedia',
  videoOptions: 'OPSI VIDEO',
  noCameraAvailable: 'Kamera Tidak Tersedia',
  videoQuality: 'Kualitas Video',
  liveVideoStreaming: 'STREAMING VIDEO LIVE',
  stopYoutubeStream: 'Hentikan streaming YouTube',
  stopSharing: 'Mulai Berbagi',
  stopSharingScreen: 'Hentikan Berbagi Layar',
  stopSharingWhiteboard: 'Hentikan Berbagi Papan Tulis',
  stopSharingFile: 'Hentikan Berbagi File',
  takePresenterRole: 'Ambil Peran Penyaji',
  takeOverPresenterRole: '{{name}} saat ini memegang peran penyaji. Apakah Anda ingin mengambil alih peran ini?',
  openNewWhiteboard: 'Buka papan tulis baru',
  resumePreviousWhiteboard: 'Lanjutkan papan tulis sebelumnya',
  selectFile: 'Pilih File',
  openNewFile: 'Buka file baru',
  resumePreviousFile: 'Lanjutkan file sebelumnya',
  saveExit: 'Simpan & Keluar',
  micBlockedByTeacher: 'Mik diblokir oleh guru',
  videoBlockedByTeacher: 'Video diblokir oleh guru',
  raiseHand: 'Angkat Tangan',
  showMoreOptions: 'Tampilkan Opsi Lain',
  backToVideoScreen: 'Ketuk di sini untuk kembali ke layar video',
  feedbackSubmittedSuccessfully: 'Masukan berhasil terkirim',
  disableMic: 'Nonaktifkan mik',
  allowToSpeak: 'Izinkan bicara',
  mute: 'Bisukan',
  allowMicUsage: 'Izinkan penggunaan Mik',
  allowCameraUsage: 'Izinkan penggunaan Kamera',
  allowChatUsage: 'Izinkan penggunaan Obrolan',
  switchCamera: 'Alihkan Kamera',
  studentAccess: 'Akses Siswa',
  liveVideoStreamingLower: 'Streaming Video Live',
  startPoll: 'Mulai Polling',
  livePolls: 'Polling Live',
  sharedScreenBecomePoll: 'Layar yang dibagikan akan menjadi polling',
  launchInstantPoll: 'Luncurkan Polling Instan',
  studentAccessUpper: 'AKSES SISWA',
  streamOnYoutbe: 'Streaming di YouTube',
  streamOnOtherPlatform: 'Streaming di Platform lain',
  unpin: 'Lepas sematan',
  pin: 'Sematkan',
  hasPresenterAccess: 'memegang akses penyaji',
  doYouWanToGive: 'Apakah Anda ingin memberikannya kepada',
  confirmGivePresenterAccess: 'akan dapat berbagi layar, papan tulis, & file. Anda yakin?',
  coTeacher: 'Rekan Guru',
  presenter: 'Penyaji',
  stopAccess: 'Hentikan Akses',
  makePresenter: 'Jadikan Penyaji',
  givePresenterAccess: 'Beri Akses Penyaji',
  giveAccess: 'Beri Akses',
  stopPresenterAccess: 'Hentikan Akses Penyaji',
  cannotGivePresenterAccess: 'Tidak dapat Memberikan Akses Penyaji kepada Pengguna ini',
  gotIt: 'Paham',
  askToUpdateApp: '{{name}} belum memperbarui aplikasinya. Minta yang bersangkutan memperbarui aplikasi untuk menjadikannya penyaji',
  leaderboard: 'Papan Peringkat',
  answered: 'Dijawab',
  notAnswered: 'Tidak Dijawab',
  isCorrect: 'Benar',
  isWrong: 'Salah',
  correctAnswer: 'Jawaban benar',
  answeredCorrectly: 'dijawab dengan benar',
  everybodyAnswered: 'Hore! Semua orang sudah menjawab',
  wrongAnswer: 'Jawaban Salah!',
  correctAnswerUpper: 'Jawaban Benar!',
  line: 'Garis',
  ellipse: 'Elips',
  rectangle: 'Persegi',
  triangle: 'Segitiga',
  circle: 'Lingkaran',
  fileCorrupted: 'File ini rusak. Tidak dapat membuka dokumen',
  youSavedWhiteboard: 'Anda sudah menyimpan papan tulis',
  youtubeLiveStreamKey: 'Kunci streaming Live YouTube',
  liveStreamNotEnabled: 'Streaming Live tidak diaktifkan di <1> </1> . Harap aktifkan streaming live atau masuk ke akun dengan streaming live diaktifkan',
  enterLiveStreamTitle: 'Masukkan judul untuk streaming live Anda',
  connectedTitle: 'Publik',
  public: 'Privat',
  private: 'Tidak Terdaftar',
  unlisted: 'Terhubung',
  notMe: 'Bukan saya',
  broadcastOnYoutubeLive: 'Siarkan ruang kelas Teachmint di YouTube Live',
  titleOfStream: 'Judul Streaming YouTube',
  enterYoutubeLiveStreamTitle: 'Masukkan judul streaming live YouTube ruang kelas Teachmint di sini',
  privacySettings: 'Pengaturan Privasi',
  goLiveOnYoutube: 'Mulai Live di YouTube',
  liveStreamByYoutube: 'Layanan streaming live disediakan oleh YouTube',
  googlePrivacyPolicy: 'Kebijakan Privasi Google',
  termsOfService: 'Ketentuan Layanan',
  apply: 'terapkan',
  canRevokeAccessToData: 'Anda dapat mencabut akses ke data Anda dengan mengakses',
  googleSecuritySettings: 'pengaturan keamanan Google',
  page: 'halaman',
  copyrightTeachmintTPL: 'Hak cipta 2021, Teachmint Technologies Pvt. Ltd.',
  privacyPolicy: 'Kebijakan Privasi',
  cannotUnmute: 'Anda tidak dapat membunyikan',
  teacherToJoin: 'Guru belum Bergabung',
  streamingLiveIn: 'Streaming Live dalam...',
  youtubeStreamingFailed: 'Streaming YouTube gagal',
  tryAgain: 'Coba lagi',
  isSharingScreen: 'sedang berbagi layar',
  isSharingScreenDisableAudio: 'sedang berbagi layar. Harap nonaktifkan mode hanya Audio.',
  youAreMuted: 'Anda dibisukan',
  youAreOnline: 'Anda sedang online sekarang',
  networkDisconnected: 'Jaringan terputus. Menghubungkan kembali',
  audioEnabled: 'Audio telah diaktifkan',
  videoEnabled: 'Video telah diaktifkan',
  chatEnabled: 'Obrolan telah diaktifkan',
  teacherStoppedPoll: 'Guru menghentikan polling',
  hasSavedWhiteboard: 'telah menyimpan papan tulis',
  youArePresenterNow: 'Anda menjadi penyaji. Sekarang Anda dapat berbagi layar, papan tulis, & file',
  yourAreNotPresenter: 'Anda tidak lagi menjadi penyaji. <1> Name </1> sedang menyajikan sekarang',
  presentationAccessRevoked: 'Akses Presentasi dicabut. Keadaan file Anda saat ini disimpan untuk sesi ini',
  isPresentingNow: 'sedang menyajikan sekarang',
  teacherIsCreatingPoll: 'Guru sedang membuat polling',
  youLeftClassroom: 'Anda telah meninggalkan ruang kelas',
  liveStreamStopped: 'Streaming live dihentikan',
  youtubeLiveStreamStarted: 'Streaming Live YouTube dimulai. Sekarang Anda live di YouTube',
  liveStreamFailed: 'Ups! Streaming live gagal.',
  youtubeLiveStreamFailed: 'Ups! Streaming live YouTube gagal.',
  teacherEnabledyourMic: 'Guru mengaktifkan mikrofon Anda',
  handLowered: 'Tangan Anda telah diturunkan',
  loadingWhiteboard: 'Memuat Papan Tulis',
  loadingDocument: 'Memuat dokumen',
  toolbarHidden: 'Bilah alat disembunyikan',
  clickLockToViewToolbar: 'Klik pada kunci untuk melihat bilah alat lagi',
  clickLock: 'Klik pada Kunci',
  closeAnyway: 'Tetap tutup',
  saveWhiteboardBeforeExiting: 'Apakah Anda ingin menyimpan papan tulis ini sebelum keluar',
  searchPeopleByName: 'Cari Orang menurut Nama'
};


export default indonesiaLang;
