const initalState = {};


export function usersReducer(state = initalState, { type, payload }) {
    let user, users, prop, value, userId;
    switch (type) {
        case "USER_ADDED":
            users = { ...state };
            user = payload;
            users[user.uid] = user;
            return users;
        case "USER_REMOVED":
            users = { ...state };
            userId = payload.value;
            delete users[userId];
            return users;
        case "ALL_USERS_UPDATED":
            users = { ...state };
            prop = payload.prop;
            value = payload.value;
            Object.values(users).forEach(u => u[prop] = value);
            return users;
        case "RESET_USERS":
            return {}
        case "USER_UPDATED": {
            users = { ...state };
            prop = payload.prop;
            value = payload.value;
            let { uid } = payload;
            user = users[uid];
            if (user) {
                user[prop] = value;
                users[uid] = user;
            }
            return users;
        }
        case "BULK_USER_UPDATE": {
            users = { ...state }
            let { uid, updates } = payload
            user = users[uid]
            Object.keys(updates).forEach(key => user[key] = updates[key]);
            if (user) {
                users[uid] = user
            }
            return users
        }
        default:
            return state;

    }
}