const initialState = {
    mic: false,
    currentSpeakerUid: null,
    lastActiveSpeakerUid: null,
    frequentSpeakers: [],
    audioDevices: [],
    currAudioDevice: {},
    audioPermissionDenied: false,
    speaker: {
        uid: '',
        status: false
    }
}

export function audioReducer(state = initialState, { type, payload }) {
    let newState;
    switch (type) {
        case "SET_MIC_STATUS":
            newState = { ...state, mic: payload.value }
            return newState;
        case "SET_LAST_ACTIVE_SPEAKER_UID":
            newState = { ...state, lastActiveSpeakerUid: payload.value }
            return newState
        case "SET_CURRENT_SPEAKER_UID":
            newState = { ...state, currentSpeakerUid: payload.value }
            return newState
        case "SET_FREQUENT_SPEAKERS":
            newState = { ...state, frequentSpeakers: payload }
            return newState
        case "SET_AUDIO_DEVICES":
            newState = { ...state, audioDevices: payload }
            return newState
        case "SET_CURRENT_AUDIO_DEVICE":
            newState = { ...state, currAudioDevice: payload }
            return newState
        case "SET_AUDIO_PERMISSION_DENIED":
            newState = { ...state, audioPermissionDenied: payload.value }
            return newState
        case "UPDATE_SPEAKER_STATUS":
            newState = { ...state }
            let newSpeaker = newState.speaker
            Object.keys(newSpeaker).forEach(key => newSpeaker[key] = payload[key]);
            return { ...state, speaker: newSpeaker }
        default:
            return state;
    }
}