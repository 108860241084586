import { WS_MSG_TYPE, WS_MSG_TO, WS_STATE, ROOM_META, INTEGRATION_STATUS } from "../../constants/Enums";
import { getTimestamp } from "../utils/Utils";
import store from "../../redux/store";
import { updateSetting } from "../../redux/actions/videoroomActions";
import { updatePresenterUid } from "../../redux/actions/presenter";
import { updateYoutubeIntegrationStatus } from "../../redux/actions/streamingAction";
import { updateEndClassSettings } from "../../redux/actions/closeActions";

export default class WSConnection {
    constructor(evaasUrl, classId, userId, hotKey, videoroom) {
        // this.evaasUrl = evaasUrl;
        // this.classId = classId;
        // this.userId = userId;
        // this.hotKey = hotKey;
        // this.videoroom = videoroom;
        // this.status = "INIT";
        // this.restart = false;
        // this.timestampMargin = 0;

        if (this.constructor.instance) {
            return this.constructor.instance;
        }

        if (evaasUrl && classId && userId && hotKey && videoroom) {
            this.evaasUrl = evaasUrl;
            this.classId = classId;
            this.userId = userId;
            this.hotKey = hotKey;
            this.videoroom = videoroom;

            this.status = "INIT";
            this.restart = false;
            this.timestampMargin = 0;
            this.tryReconnect = true;
            // this.url = `wss://${this.evaasUrl}/${this.classId}/${this.userId}/${this.hotKey}`;

            // this.ws = new WebSocket(this.url);
            // this.ws.onopen = this.onOpen;
            // this.ws.onmessage = this.onMessage;
            // this.ws.onclose = this.onClose;
            // this.ws.onerror = this.onError;

            this.constructor.instance = this;
        }
    }

    start() {
        const url = `wss://${this.evaasUrl}/${this.classId}/${this.userId}/${this.hotKey}`;
        this.ws = new WebSocket(url);
        store.dispatch(updateSetting({
            setting: "WSState",
            value: WS_STATE.CONNECTING
        }))
        this.ws.onopen = this.onOpen;
        this.ws.onmessage = this.onMessage;
        this.ws.onclose = this.onClose;
        this.ws.onerror = this.onError;
    }

    onOpen = () => {
        if (this.ws.readyState === WS_STATE.OPEN) {
            store.dispatch(updateSetting({
                setting: "WSState",
                value: WS_STATE.OPEN
            }))
            this.heartbeatInterval = window.setInterval(() => {
                this.heartbeat();
            }, 10 * 1000);
        }
    };

    onMessage = (msg) => {
        console.log("MESSAGE", msg);
        let data = JSON.parse(msg.data);

        if (data.type === WS_MSG_TYPE.RTC) {
            this.videoroom.onReceiveRTCMessage(data);
        } else if (data.type === WS_MSG_TYPE.SESSION_INFO) {
            store.dispatch(
                updateSetting({
                    setting: "sessionId",
                    value: data.session_id,
                })
            );
            this.timestampMargin = data.curr_system_time - getTimestamp();
        } else if (data.type === WS_MSG_TYPE.USERS && data.users !== null) {
            for (const user of data.users) {
                if (user.uid === WS_MSG_TO.RTC) {
                    this.status = "OPEN";
                    this.videoroom.startPeerConnection();
                } else if (user.uid !== this.userId) {
                    this.videoroom.addUsers(user);
                }
            }
        } else if (data.type === WS_MSG_TYPE.RUSERS && data.users !== null) {
            this.videoroom.removeUsers(data.users);
        } else if (data.type === WS_MSG_TYPE.STATUS) {
            if (data.from !== this.userId) {
                this.videoroom.updateStatus(data);
            }
        } else if (data.type === WS_MSG_TYPE.ROOM_META) {
            this.getRoomMeta(data);
        } else if (data.type === WS_MSG_TYPE.MESSAGE) {
            this.videoroom.setTypeMessage(data);
        } else if (data.type === WS_MSG_TYPE.RTMP_FAILED) {
            // this.videoroom.onStreamingFailed();
            store.dispatch(updateYoutubeIntegrationStatus(INTEGRATION_STATUS.FAIL));
        }
    };

    getRoomMeta = (data) => {
        const roomMeta = JSON.parse(data[WS_MSG_TYPE.ROOM_META]);
        console.log("roomMeta", roomMeta);
        const roomState = roomMeta[ROOM_META.ROOM_STATE];
        if (
            roomState !== null &&
            data.from !== this.userId &&
            this.videoroom.props.videoroom.roomState !== roomState
        ) {
            this.videoroom.updateRoomStatus(roomState, data.from);
        }

        const recordingActivity = roomMeta[ROOM_META.RECORD_LOG];
        if (recordingActivity) {
            // this.videoroom.calculateRecordingTimer(recordingActivity);
            store.dispatch(
                updateSetting({
                    setting: "recordingActivity",
                    value: recordingActivity,
                })
            );
        }

        const presenterUID = roomMeta[ROOM_META.PRESENTER_UID];
        if (
            data.from !== this.userId &&
            this.videoroom.roomInfo &&
            this.videoroom.props.videoroom.presenterId !== presenterUID
        ) {
            store.dispatch(updatePresenterUid(presenterUID));
        }
        store.dispatch(updatePresenterUid(presenterUID));
        store.dispatch(
            updateSetting({
                setting: "roomMeta",
                value: roomMeta,
            })
        );
    };

    onClose = (msg) => {
        const code = msg.code;
        store.dispatch(updateSetting({
            setting: "WSState",
            value: WS_STATE.CLOSED
        }))
        if (msg) {
            if (code === 4000) {
                this.tryReconnect = false;
                this.videoroom.onConnectOtherInstance();
            } else if (code === 4001 || code === 4002 || code === 4003) {
                // this.videoroom.onEndClass();
                store.dispatch(updateEndClassSettings({ classEnded: true }));
            } else if (code === 1005 || code === 1006) {
                if (this.restart) {
                    this.videoroom.startWSConnection();
                    this.restart = false;
                }
            }
        }
    };

    onError = () => {
        this.videoroom.onWebsocketError();
    };

    heartbeat = () => {
        if (this.ws.readyState === WS_STATE.OPEN) {
            this.sendMsg({
                type: WS_MSG_TYPE.HEARTBEAT,
            });
        }
    };

    sendMessageToHosts = (msg) => {
        const users = store.getState().users;
        const hosts = Object.values(users).filter((u) => u.utype === 1 || u.utype === 11);
        hosts.forEach((host) => {
            this.sendMessage(msg, host.uid);
        });
    };

    sendMessage = (msg, to = null) => {
        let message = {
            type: WS_MSG_TYPE.MESSAGE,
            text: JSON.stringify(msg),
        };
        if (to != null) {
            message.to = to;
        }
        this.sendMsg(message);
    };

    sendRoomMeta = (type, obj) => {
        const { roomMeta } = store.getState().videoroom;
        roomMeta[type] = obj;
        const msg = {
            type: WS_MSG_TYPE.ROOM_META,
            [WS_MSG_TYPE.ROOM_META]: JSON.stringify(roomMeta),
        };
        this.sendMsg(msg);
    };

    sendMsg = (msg) => {
        if (this.ws.readyState === WS_STATE.OPEN) {
            this.ws.send(JSON.stringify(msg));
        }
    };

    close = () => {
        this.status = "INIT";
        window.clearInterval(this.heartbeatInterval);
        this?.ws?.close();
    };
}
