import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./TopbarVideoroom.scss";

import Cross from "../../images/toast-cross.svg";
import OfflineGifIcon from "../../images/offline.gif";
import OnlineIcon from "../../images/online.svg";
import TeachmintLogo from "../../images/Teachmint.svg";
import TeachmintMiniLogo from "../../images/Teachmint-mini.svg";
import RecordingOff from "../../images/recording-off-white.svg";
import ModalExitWhiteboard from "../../images/modal-exit-wb-icon.svg";
import { useTranslation } from "react-i18next";
import TimerUI from "../Recording/TimerUI";
import YTIntegrationStatus from "../Streaming/YTIntegrationStatus";
import { updateIsPopupOpened } from "../../../redux/actions/modals";
// import WSConnection from "../../connections/WSConnection";
import { updateEndClassSettings } from "../../../redux/actions/closeActions";

export default function TopbarVideoroom({
    sendLiveStatus
}) {
    const dispatch = useDispatch()
    const { canEndClassForAll, canStream } = useSelector(state => state.permissions)
    const { device, classInfo, recording } = useSelector(state => state.videoroom)
    const { logo_url, name } = classInfo;
    const { closeAllPopups } = useSelector(state => state.modals)
    const [showEndClass, setShowEndClass] = useState(false);
    const { t } = useTranslation();
    const isLaptop = device && device.laptop;

    useEffect(() => {
        if (closeAllPopups) {
            setShowEndClass(false)
        }
    }, [closeAllPopups]);

    const onClickEndClass = (e) => {
        e.stopPropagation()
        canEndClassForAll
            ? setShowEndClass(!showEndClass)
            : dispatch(updateEndClassSettings({ endClassForSelf: true }));
        dispatch(updateIsPopupOpened(true))
        // permissions.canEndClassForAll ? setShowEndClass(!showEndClass) : sendLiveStatus(null, true);
    };

    return (
        <>
            <div className="topbar-videoroom">
                {!isLaptop ? (
                    <div className="topbar-mobile">
                        <img
                            alt="logo"
                            style={{ height: "25px", maxWidth: "120px" }}
                            src={logo_url || TeachmintMiniLogo}
                        />
                        {name}
                        <div className="end-class" onClick={onClickEndClass}>
                            {t("goLiveStudentDashboardExitButtonText")}
                        </div>
                    </div>
                ) : null}
                <div className="topbar-left">
                    {isLaptop ? (
                        <div className="topbar-left-div">
                            <img alt="logo" src={logo_url || TeachmintLogo} />
                        </div>
                    ) : null}

                    {isLaptop ? (
                        <div
                            className="topbar-left-div"
                            style={{
                                cursor: "default",
                            }}
                            data-qa="txt-class-name"
                        >
                            {name}
                        </div>
                    ) : null}

                    {isLaptop && canStream &&
                        <YTIntegrationStatus />}
                </div>
                <div className="topbar-right">
                    {/* {isLaptop && recording ? <TimerUI wsConnection={wsConnection}></TimerUI> : null} */}
                    {isLaptop && recording ? <TimerUI></TimerUI> : null}
                    {/* {isLaptop && recording && recordingTimer ? (
                        <div className="recording-display">
                            <span>
                                <img alt="recording" src={RecordingOff} />
                            </span>
                            <span className="recording-timer">{recordingTimer}</span>
                        </div>
                    ) : null} */}
                    {isLaptop ? (
                        <div className="end-class" onClick={onClickEndClass}>
                            {t("goLiveStudentDashboardExitButtonText")}
                        </div>
                    ) : null}
                </div>
            </div>
            {showEndClass && canEndClassForAll ? (
                <div className="end-class-cont">
                    <div>
                        <div className="end-class-text">
                            {t("goLiveTeacherDashboardLeaveTheClassLetOthersContinueText")}
                        </div>
                        <button
                            className="end-class-btn leave-class"
                            onClick={() => {
                                dispatch(updateEndClassSettings({ endClassForSelf: true }));
                                // sendLiveStatus(null, true);
                            }}
                        >
                            {t("goLiveTeacherDashboardLeaveClassText")}
                        </button>
                    </div>
                    <div>
                        <div className="end-class-text">{t("goLiveEndClassButtonSubText")}</div>
                        <button
                            className="end-class-btn end-for-all"
                            onClick={() => {
                                dispatch(updateEndClassSettings({ endClassForAll: true }));
                                // sendLiveStatus(true);
                            }}
                        >
                            {t("goLiveEndClassButtonText")}
                        </button>
                    </div>
                </div>
            ) : null}
            {/* Cache images for offline use */}
            <img alt="offline" className="display-none" src={OfflineGifIcon} />
            <img alt="online" className="display-none" src={OnlineIcon} />
            <img alt="cross" className="display-none" src={Cross} />
            <img alt="exit-wb" className="display-none" src={ModalExitWhiteboard} />
        </>
    );
}
