import React, { useEffect, useState } from "react";
import "./SystemStats.scss";
import Cross from "../../images/modal-cross-icon.svg";
import NetworkIcon from "../../images/network.svg";
import infoIcon from "../../images/info-icon.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateSetting } from "../../../redux/actions/videoroomActions";

const SystemStatsModal = (props) => {
    const { statsData } = props;
    const [stats, setStats] = useState({
        packetLoss: 0,
        jitter: 0,
        connectionDelay: 0,
        publishBitrate: 0,
        subscribeBitrate: 0,
    });
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isOnline } = useSelector((state) => state.videoroom);

    useEffect(() => {
        setStats((prevData) => {
            return { ...prevData, ...statsData };
        });
    }, [statsData]);

    const closeModal = () => {
        dispatch(updateSetting({ setting: "showSystemStats", value: false }));
    };
    return (
        <div className="sys-stats-container center">
            <div className={`${props.contentClass} sys-stats-mainBox`}>
                <div className="header">
                    <div className="sys-title">{t("systemStats")}</div>
                    <div className="sys-close">
                        <img
                            alt="cross"
                            src={Cross}
                            className="cross-btn"
                            onClick={() => {
                                closeModal();
                            }}
                        />
                    </div>
                </div>
                <div className="sys-stat-content">
                    <div className="sec-1">
                        <div className="sec-1-icon">
                            <img alt="net" src={NetworkIcon} />
                        </div>
                        <div className="sec-1-txt">
                            <p className="sec-1-txt-title">{t("networkStability")}</p>
                            <p className="sec-1-txt-info">{t("networkStabilityTxt")}</p>
                        </div>
                    </div>
                    <div className="sec-2">
                        <div className="stat-info-box">
                            <p>{`${!isOnline ? "-" : stats.packetLoss}%`}</p>
                            <p className="stat-info-key">{t("packetLoss")}</p>
                        </div>
                        <div className="stat-info-box">
                            <p>{!isOnline ? "-" : stats.jitter} ms</p>
                            <p className="stat-info-key">{t("jitter")}</p>
                        </div>
                        <div className="stat-info-box">
                            <p>{!isOnline ? "-" : stats.connectionDelay} ms</p>
                            <p className="stat-info-key">{t("connectionDelay")}</p>
                        </div>
                        <div className="stat-info-box">
                            <p>{`${!isOnline ? "-" : stats.publishBitrate} Kbps`}</p>
                            <p className="stat-info-key">{t("publishBitrate")}</p>
                        </div>
                        <div className="stat-info-box">
                            <p>{`${!isOnline ? "-" : stats.subscribeBitrate} Kbps`}</p>
                            <p className="stat-info-key">{t("subscribeBitrate")}</p>
                        </div>
                    </div>
                    <div className="sec-3">
                        <div className="sec-3-title">
                            <img alt="info" src={infoIcon} height="14px" width="14px" />
                            <p className="title">{t("quickFixTip")}</p>
                        </div>
                        <ul>
                            <li>{t("quickFixTipOne")}</li>
                            <li>{t("quickFixTipTwo")}</li>
                            <li>{t("quickFixTipThree")}</li>
                        </ul>
                    </div>
                </div>
                <div className="main-content">{props.children}</div>
            </div>
        </div>
    );
};

export default SystemStatsModal;
