export const updateShowGiveAccess = (value) => ({
    type: "SHOW_GIVE_ACCESS",
    payload: { value },
})

export const updateShowStopAccess = (value) => ({
    type: "SHOW_STOP_ACCESS",
    payload: { value },
})

export const updateShowAccessNotSupported = (value) => ({
    type: "SHOW_ACCESS_NOT_SUPPORTED",
    payload: { value },
})

export const updateCloseAllPopups = (value) => ({
    type: "CLOSE_ALL_POPUPS",
    payload: { value }
})

export const updateIsPopupOpened = (value) => ({
    type: "IS_POPUP_OPENED",
    payload: { value }
})

export const updateShowYoutubeModal = (flag) =>
({
    type: "UPDATE_SHOW_YOUTUBE_MODAL",
    payload: { value: flag }
})

export const updateShowPart2YoutubeModal = (flag) =>
({
    type: "UPDATE_SHOW_PART2_YOUTUBE_MODAL",
    payload: { value: flag }
})

export const updateShowCustomStreamModal = (flag) =>
({
    type: "UPDATE_SHOW_CUSTOM_STREAM_MODAL",
    payload: { value: flag }
})

export const updateShowStopStreamingModal = (flag) =>
({
    type: "UPDATE_SHOW_STOP_STREAMING_MODAL",
    payload: { value: flag }
})

export const updateBulkModals = (updates) =>
({
    type: "BULK_UPDATE_MODALS",
    payload: updates
})

export const closeAllStreamingModals = () =>
({
    type: "CLOSE_ALL_STREAMING_MODALS"
})