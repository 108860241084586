import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createSetIntervalPolyfill } from "../../utils/Utils";
import { SENDER } from "../../../constants/Enums";

export function BufferStream({
    webrtc,
    peerConnection
}) {
    const { setIntervalPolyfill, clearIntervalPolyfill } = createSetIntervalPolyfill();
    const [bufferInterval, setBufferInterval] = useState(null)
    const { presenterUid } = useSelector(state => state.presenter)
    const { isStreaming } = useSelector(state => state.streaming)
    const { camera } = useSelector(state => state.video)
    const { sendScreenshare, recording, device,
        userId, classInfo, classStatus } = useSelector(state => state.videoroom)
    const users = useSelector(state => state.users)
    const publisher = users[userId]

    useEffect(() => {
        if (!classStatus) {
            clearIntervalPolyfill(bufferInterval);
        }
    }, [classStatus])

    useEffect(() => {
        handleBufferStream()
    }, [camera, sendScreenshare, recording, isStreaming, presenterUid])

    const handleBufferStream = () => {
        const hasVideo = camera || sendScreenshare;
        const shouldSendStream = recording || isStreaming;
        const amIPresenter = presenterUid === userId;

        if (amIPresenter && shouldSendStream && !hasVideo) {
            sendBufferStream();
        }
        if (!amIPresenter || !shouldSendStream || hasVideo) {
            stopBufferStream();
        }
    }

    const sendBufferStream = () => {
        if (!bufferInterval) {
            const canvasStream = webrtc.getCanvasStream();
            webrtc.setCanvasStream(canvasStream);
            if (peerConnection) {
                peerConnection.addTrack(SENDER.VIDEO, canvasStream.getVideoTracks()[0]);
                console.log("send buffer stream");
                setBufferInterval(
                    setIntervalPolyfill(() => {
                        webrtc.createAnnotations(classInfo.uname, publisher.color);
                    }, 500)
                );
            }
        }
    };

    const stopBufferStream = () => {
        if (bufferInterval) {
            console.log("stop buffer stream");
            webrtc.stopCanvasStream();
            clearIntervalPolyfill(bufferInterval);
            setBufferInterval(null)
        }
    };

    return (
        <canvas id='vc_dummy_canvas'
            width={device.mobile ? 480 : 720}
            height={device.mobile ? 720 : 480}
        ></canvas>
    );
}