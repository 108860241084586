export const updatePresenterUid = (uid) => ({
    type: "UPDATE_PRESENTER_UID",
    payload: { value: uid },
})

export const updateUserPresenterStatus = (flag) => ({
    type: "UPDATE_USER_PRESENTER_STATUS",
    payload: { value: flag },
})

export const updatedSelectedPresenterID = (flag) => ({
    type: "UPDATE_SELECTED_PRESENTER_ID",
    payload: { value: flag },
})
