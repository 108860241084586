import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "../../../redux/actions/usersActions";
import { addToast } from "../../../redux/actions/toasts";
import {
    setLastActiveSpeakerUid,
    setCurrentSpeakerUid,
    setFrequentSpeakers,
    setAudioDevices,
    setCurrentAudioDevice,
} from "../../../redux/actions/audioActions";
import { STATUS, NEW_TOAST_TYPES, SENDER, WS_STATE } from "../../../constants/Enums";
import { creatToast } from "../../containers/Toast/toastCreator";
import store from "../../../redux/store";

const activeLimit = 4;
export default function Audio({ webrtc, control, sendStatus, peerConnection, subscribe }) {
    const dispatch = useDispatch();
    const { mic, speaker, frequentSpeakers, currAudioDevice, audioDevices } = useSelector(state => state.audio)
    const { userId, PCCState, WSState } = useSelector(state => state.videoroom)
    const [audioEnabled, setAudioEnabled] = useState(false)
    const [speakerList, setSpeakerList] = useState([])
    useEffect(() => {
        navigator.mediaDevices &&
            navigator.mediaDevices.addEventListener("devicechange", onAudioDeviceChange);
    }, []);

    useEffect(() => {
        if (PCCState === "connected" && WSState === WS_STATE.OPEN) {
            // replace tracks only after connections are formed
            replaceAudioTracks(currAudioDevice);
        }
    }, [currAudioDevice.label]);

    useEffect(() => {
        if (PCCState === "connected" && mic && !audioEnabled) {
            //when mic is turned on for first time
            enableAudio();
        }
        if (WSState === WS_STATE.OPEN) {
            handleAudioStatus(mic);
        }
    }, [mic, PCCState]);

    useEffect(() => {
        if (speaker.uid) {
            if (WSState === WS_STATE.OPEN && speaker.uid === userId) {
                // if speaking status is mine, send speaking bit as SET/UNSET in status to others
                dispatch(updateUser({
                    uid: userId,
                    prop: "isSpeaking",
                    value: speaker.status
                }))
                sendSpeakingStatus(speaker.status);
            }
            updateSpeakerList(speaker.uid, speaker.status);
        }
    }, [speaker.uid, speaker.status]);

    useEffect(() => {
        //clear variables when peerconnection is closed -> (on offline or restart)
        if (WSState === WS_STATE.CLOSED) {
            dispatch(setCurrentSpeakerUid(null));
            dispatch(setLastActiveSpeakerUid(null));
            dispatch(setFrequentSpeakers([]));
            setSpeakerList([]);
            setAudioEnabled(false)
        }
    }, [WSState]);

    const handleAudioStatus = (micState) => {
        webrtc.updateAudioStatus(micState);
        dispatch(
            updateUser({
                uid: userId,
                prop: "mute",
                value: !micState,
            })
        );
        sendAudioStatus(micState);
    };

    const sendAudioStatus = (micState) => {
        let newControl = control;
        let mask = 1 << STATUS.AUDIO;
        micState ? (newControl |= mask) : (newControl &= ~mask);
        sendStatus(newControl);
    };

    const sendSpeakingStatus = (micState) => {
        let newControl = control;
        let mask = 1 << STATUS.SPEAKING;
        micState ? (newControl |= mask) : (newControl &= ~mask);
        sendStatus(newControl);
    };

    const showToastMessage = (msg) => {
        dispatch(addToast(creatToast(NEW_TOAST_TYPES.GENERIC_TOAST, msg)));
    };

    //to get the current speaker uid
    const updateSpeakerList = (uid, status) => {
        let newSpeakerList = speakerList;
        if (status) {
            newSpeakerList.push(uid);
            setSpeakerList(newSpeakerList);
            dispatch(setLastActiveSpeakerUid(uid));
            if (uid !== userId) {
                updateFrequentSpeakersList(uid);
            }
        } else {
            let index = newSpeakerList.indexOf(uid);
            if (index !== -1) {
                newSpeakerList.splice(index, 1);
                setSpeakerList(newSpeakerList);
            }
        }
        dispatch(setCurrentSpeakerUid(newSpeakerList[0]));
    };

    //maintain frequent speakers list so that they can be subscribed if their camera is ON
    const updateFrequentSpeakersList = (uid) => {
        const newFrequentSpeakers = frequentSpeakers;
        let index = newFrequentSpeakers.indexOf(uid);
        if (index !== -1) {
            newFrequentSpeakers.splice(index, 1);
            newFrequentSpeakers.push(uid);
        } else if (newFrequentSpeakers.length >= activeLimit) {
            newFrequentSpeakers.splice(0, 1);
            newFrequentSpeakers.push(uid);
        } else {
            newFrequentSpeakers.push(uid);
        }
        dispatch(setFrequentSpeakers(newFrequentSpeakers));
    };

    // Called when the audio is turned on for first time
    const enableAudio = async () => {
        try {
            setAudioEnabled(true);
            const { audioStream } = await webrtc.getAudioStream(currAudioDevice.deviceId);
            webrtc.setMainAudio(audioStream);
            let finalTrack = webrtc.mergeAudioTracks();
            peerConnection.addTrack(SENDER.AUDIO, finalTrack);
            peerConnection.getSpeakingSignals();
            subscribe();
        } catch { }
    };

    const onAudioDeviceChange = async () => {
        try {
            const newAudioDevices = await webrtc.getAudioDevices();
            const currAudioDevice = webrtc.getConnectedAudioDevice(store.getState().audio.audioDevices, newAudioDevices);
            if (currAudioDevice) {
                dispatch(setCurrentAudioDevice(currAudioDevice));
                dispatch(setAudioDevices(newAudioDevices));
            }
        } catch { }
    };

    const replaceAudioTracks = async (device) => {
        try {
            showToastMessage(`${device.label} connected`);
            const { audioStream } = await webrtc.getAudioStream();
            webrtc.replaceAudioTracks(audioStream);
            webrtc.updateAudioStatus(mic);
            const finalTrack = webrtc.mergeAudioTracks();
            peerConnection.addTrack(SENDER.AUDIO, finalTrack);
        } catch { }
    };

    return null;
}
