export const setVideo = (status) =>
({
    type: 'SET_VIDEO',
    payload: status
});
export const updateSetting = (update) =>
({
    type: 'UPDATE_SETTING',
    payload: update
});
export const bulkUpdateSettings = (updates) =>
({
    type: 'BULK_UPDATE_SETTINGS',
    payload: updates
});
export const updateClassInfoSettings = (update) =>
({
    type: "UPDATE_CLASSINFO_SETTINGS",
    payload: update
})


