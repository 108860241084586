import React from "react";
import LoaderGif from '../images/loader.gif'

export default class Loader extends React.Component {
    render() {
        return this.props.show ? (
            <div className='vc-loader'
                style={{
                    backgroundColor:  this.props.pollLoading ? 'rgb(60, 68, 88)' : 'rgba(60, 68, 88, 0.9)',
                }}
            >
                <div>
                    <img alt='loader' src={LoaderGif} className='vc-loader-gif'/>
                </div>
                {this.props.text &&
                    <div className='vc-loader-text'>
                        {this.props.text}
                    </div>
                }
            </div>
        ) : null;
    }
}
