const initialState = {
    isStreaming: false,
    isGoogleSignedIn: false,
    isLiveStreamingDisabled: false,
    basicProfile: {
        name: "",
        imgUrl: "",
        email: "",
    },
    currStreamOption: null,
    ytIntegrationStatus: null,
    ytBroadcastId: ""
}

export function streamingReducer(state = initialState, { type, payload }) {
    let newState;
    switch (type) {
        case "UPDATE_STREAMING_STATUS":
            newState = { ...state, isStreaming: payload.value }
            return newState;
        case "UPDATE_IS_GOOGLE_SIGNED_IN":
            newState = { ...state, isGoogleSignedIn: payload.value }
            return newState;
        case "UPDATE_BASIC_PROFILE":
            newState = { ...state, basicProfile: payload }
            return newState;
        case "UPDATE_LIVE_STREAMING_DISABLED":
            newState = { ...state, isLiveStreamingDisabled: payload.value }
            return newState;
        case "UPDATE_CURRENT_STREAMING_OPTION":
            newState = { ...state, currStreamOption: payload.value }
            return newState;
        case "UPDATE_YOUTUBE_INTEGRATION_STATUS":
            newState = { ...state, ytIntegrationStatus: payload.value }
            return newState;
        case "UPDATE_YOUTUBE_BROADCAST_ID":
            newState = { ...state, ytBroadcastId: payload.value }
            return newState;
        case "BULK_UPDATE_STREAMING":
            newState = { ...state };
            let settings = payload;
            Object.keys(settings).forEach((key) => (newState[key] = settings[key]));
            return newState;
        case "RESET_ALL_STREAMING_STATES":
            return initialState;
        default:
            return state;
    }
}