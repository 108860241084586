import { STATUS } from "../../constants/Enums";
import store from "../../redux/store";
import { updateSpeakerStatus } from "../../redux/actions/audioActions";

export const setControls = (status, user, videoroom) => {
    let diff = status ^ user.status;
    if (diff & (1 << STATUS.AUDIO)) {
        // audio
        let bitVal = (status >> STATUS.AUDIO) & 1;
        user.mute = !Boolean(bitVal);
        if (!bitVal) {
            user.isSpeaking = bitVal
            store.dispatch(updateSpeakerStatus({
                uid: user.uid,
                status: bitVal
            }))
        }
    }
    if (diff & (1 << STATUS.VIDEO)) {
        // video
        let bitVal = (status >> STATUS.VIDEO) & 1;
        if (bitVal) {
            let stream = videoroom.userStreams[user.uid];
            if (stream) {
                let vtrack = stream.getVideoTracks()[0];
                if (vtrack) {
                    user.playing = Boolean(bitVal);
                }
            }
        } else {
            user.playing = Boolean(bitVal);
        }
    }
    if (diff & (1 << STATUS.SPEAKING)) {
        // speaking
        let bitVal = (status >> STATUS.SPEAKING) & 1;
        user.isSpeaking = bitVal
        store.dispatch(updateSpeakerStatus({
            uid: user.uid,
            status: bitVal
        }))
    }
    if (diff & (1 << STATUS.LANDSCAPE)) {
        //landscape
    }

    if (diff && 1 << STATUS.POLL) {
        //poll loading is handled first than screenshare
        if (user.isTeacher) {
            let bitVal = (status >> STATUS.POLL) & 1;

            user.didLaunchPoll = Boolean(bitVal);
            const loaderTxt = Boolean(bitVal) ? "Teacher is creating a poll" : "";
            videoroom.setLoading(Boolean(bitVal), loaderTxt);
        }
    }
    if (diff & (1 << STATUS.SCREENSHARE)) {
        //screenshare
        let bitVal = (status >> STATUS.SCREENSHARE) & 1;
        user.isSharingScreen = Boolean(bitVal);
        videoroom.handleScreenshare(
            Boolean(bitVal),
            `${user.uid}-screen`,
            `${user.name}'s screen`,
            user.uid
        );
    }

    if (diff & (1 << STATUS.HANDRAISE)) {
        //handraise
        let bitVal = (status >> STATUS.HANDRAISE) & 1;
        user.isHandraise = Boolean(bitVal);
        user.allowToSpeakEnabled = false
        // videoroom.teacherHandleHandRaise(user.uid, user.isHandraise);
    }

    user.status = status;
    return user;
};

export const isRTCconnected = (user) => {
    return Boolean((user.status >> STATUS.RTC) & 1);
};

export const getName = (user) => {
    return user.isScreenShare ? user.name.replace("-screen", "") : user.name.slice(0, 25);
};

export const isPlaying = (user) => {
    return Boolean((user.status >> STATUS.VIDEO) & 1);
};

export const isPresenterAccessSupported = (user) => {
    return Boolean((user.status >> STATUS.PRESENTER_ACCESS_SUPPORTED) & 1);
};

export const isPresenter = (user) => {
    const { presenter } = store.getState();
    return presenter.presenterUid === user.uid;
};
