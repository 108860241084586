import React from "react"

const originalState = {
    showGiveAccess: false,
    showStopAccess: false,
    showAccessNotSupported: false,
    closeAllPopups: false,
    isPopOpened: false,
    showYoutubeModal: false,
    showPart2YoutubeModal: false,
    showCustomStreamModal: false,
    showStopStreamingModal: false
}

export default function modalReducer(state = originalState, { type, payload }) {
    let newState = {}
    switch (type) {
        case "SHOW_GIVE_ACCESS":
            newState = { ...state, showGiveAccess: payload.value }
            return newState
        case "SHOW_STOP_ACCESS":
            newState = { ...state, showStopAccess: payload.value }
            return newState
        case "SHOW_ACCESS_NOT_SUPPORTED":
            newState = { ...state, showAccessNotSupported: payload.value }
            return newState
        case "CLOSE_ALL_POPUPS":
            newState = { ...state, closeAllPopups: payload.value }
            return newState
        case "IS_POPUP_OPENED":
            newState = { ...state, isPopOpened: payload.value }
            return newState
        case "UPDATE_SHOW_YOUTUBE_MODAL":
            newState = { ...state, showYoutubeModal: payload.value }
            return newState;
        case "UPDATE_SHOW_PART2_YOUTUBE_MODAL":
            newState = { ...state, showPart2YoutubeModal: payload.value }
            return newState;
        case "UPDATE_SHOW_CUSTOM_STREAM_MODAL":
            newState = { ...state, showCustomStreamModal: payload.value }
            return newState;
        case "UPDATE_SHOW_STOP_STREAMING_MODAL":
            newState = { ...state, showStopStreamingModal: payload.value }
            return newState;
        case "CLOSE_ALL_STREAMING_MODALS":
            newState = {
                ...state,
                showYoutubeModal: false,
                showPart2YoutubeModal: false,
                showCustomStreamModal: false,
                showStopStreamingModal: false
            }
            return newState;
        case "BULK_UPDATE_MODALS":
            newState = { ...state };
            let settings = payload;
            Object.keys(settings).forEach((key) => (newState[key] = settings[key]));
            return newState;
        default:
            return state
    }
}
