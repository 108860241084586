import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateSetting } from "../../../redux/actions/videoroomActions";
import { updateEndClassSettings } from "../../../redux/actions/closeActions";
import { resetUsers } from "../../../redux/actions/usersActions";
import WSConnection from "../../connections/WSConnection";
import { WS_MSG_TYPE, SHARE } from "../../../constants/Enums";
import { teachmintWebsite } from "../../../constants/envVariables";

export default function Close({ publisher, clearIntervals, displayEndPage, location }) {
    const dispatch = useDispatch();
    const t = useTranslation();
    const {
        sendScreenshare,
        screenShareOption,
        recordedAtleastOnce,
        savedWhiteBoardAtleastOnce,
        classId,
        classStatus,
    } = useSelector((state) => state.videoroom);
    const { canEndClassForAll, isStudent } = useSelector((state) => state.permissions);
    const { mic } = useSelector((state) => state.audio);
    const { camera } = useSelector((state) => state.video);
    const { PCCState } = useSelector((state) => state.videoroom);
    const { endClassForAll, endClassForSelf, classEnded, saveAndClose } = useSelector(
        (state) => state.closeClassRoom
    );
    const wsConnection = new WSConnection();
    const [firstRender, setFirstRender] = useState(true);
    const [micOnAtleastOnce, setMicOnAtleastOnce] = useState(false);
    const [videoEnabledAtleastOnce, setVideoEnabledAtleastOnce] = useState(false);

    useEffect(() => {
        if (endClassForAll && canEndClassForAll) {
            //End classroom for all
            wsConnection.sendMsg({ type: WS_MSG_TYPE.CLOSE_ALL });
        }
    }, [endClassForAll]);

    useEffect(() => {
        if (PCCState === "connected") {
            if (mic) {
                setMicOnAtleastOnce(true);
            }
            if (camera) {
                setVideoEnabledAtleastOnce(true);
            }
        }
    }, [mic, camera, PCCState]);

    useEffect(() => {
        if (!firstRender) {
            dispatch(
                updateSetting({
                    setting: "classStatus",
                    value: false,
                })
            );
            if (publisher.isTeacher && sendScreenshare && screenShareOption === SHARE.WHITEBOARD) {
                dispatch(
                    updateSetting({
                        setting: "showExitWB",
                        value: true,
                    })
                );
            } else if (publisher.isTeacher && sendScreenshare && screenShareOption === SHARE.PDF) {
                dispatch(
                    updateSetting({
                        setting: "showExitPdf",
                        value: true,
                    })
                );
            } else {
                dispatch(resetUsers());
                endClass();
            }
        }
        setFirstRender(false);
    }, [classEnded, endClassForSelf, saveAndClose]);

    const endClass = () => {
        const query = new URLSearchParams(location.search);
        const is_feedback_required = query.get("is_feedback_required");
        sendMessageToParent(!endClassForAll);
        if (wsConnection) {
            wsConnection.close();
        }
        clearIntervals();
        // content sharing is paused for now
        // if(((this.state.utype === 1) &&
        //     (this.state.companyId === COMPANY.EVAAS || this.state.companyId === COMPANY.TEACHMINT)) &&
        //     (this.state.recordedAtleastOnce || this.state.savedWhiteBoardAtleastOnce)){
        //     const obj = {
        //         session_id: this.sessionId,
        //         class_id: this.state.classId.split("::")[1],
        //         recordedAtleastOnce: this.state.recordedAtleastOnce,
        //         savedWhiteBoardAtleastOnce: this.state.savedWhiteBoardAtleastOnce
        //     }
        //     let url = `${teachmintWebsite}content-sharing?data=${encodeURIComponent(JSON.stringify(obj))}`;
        //     window.location.href = url
        // }
        if (is_feedback_required === "true") {
            const additionalData = {
                platform: "WEB",
                date: +new Date(),
                class_id: classId.split("::")[1],
                audioEnabled: micOnAtleastOnce,
                videoEnabled: videoEnabledAtleastOnce,
                recordedAtleastOnce: recordedAtleastOnce,
                savedWhiteBoardAtleastOnce: savedWhiteBoardAtleastOnce,
            };
            window.open(
                `${teachmintWebsite}feedback?from=LIVECLASS&student=${+isStudent}&data=${encodeURIComponent(
                    JSON.stringify(additionalData)
                )}`,
                "_self"
            );
        }

        displayEndPage();
    };

    const sendMessageToParent = (isLive) => {
        const msg = {
            room_id: classId,
            is_live: isLive,
        };
        console.log("send to parent", msg);
        window.parent.postMessage(msg, "*");
    };
    return null;
}
