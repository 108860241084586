import { VIDEO_OPTIONS, QUALITY } from "../../constants/Enums";
const initalState = {
    pollType: null, /* 1 -> instant, 2 -> create new */
    pollLaunched: false,
    pollDuration: null,
    pollQuestion: '',
    pollFinished: false,
    showPollContainer: false,
    stuSelectedPoll: null,
    correctAnswer: null,
    showLeaderboard: false,
    showStopPoll: false,
    optionsNum: 2,
    pollOptions: ['', ''],
    pollResponses: []
};


export function pollsReducer(state = initalState, { type, payload }) {
    let newState;
    switch (type) {
        case "UPDATE_POLL_SETTING":
            newState = { ...state };
            let { setting, value } = payload;
            newState[setting] = value;
            return newState;
        case "BULK_UPDATE_POLL_SETTINGS":
            newState = { ...state };
            let settings = payload;
            Object.keys(settings).forEach(key => newState[key] = settings[key]);
            return newState;
        default:
            return state;

    }
}