import store from "../../redux/store";
import { ERROR } from "../../constants/Enums";
import { setCameraStatus } from "../../redux/actions/videoActions";
import { setMicStatus } from "../../redux/actions/audioActions";

export default class WebrtcClient {
    constructor() {
        this.previewStream = new MediaStream()
        this.mediaStream = new MediaStream()
        this.screenStream = new MediaStream()
        this.canvasStream = new MediaStream()
        this.permissions = store.getState().permissions
    }

    getAudioStream = (deviceId = null, groupId = null) => {
        const { currAudioDevice } = store.getState().audio
        return new Promise((resolve, reject) => {
            navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId: { ideal: deviceId || currAudioDevice.deviceId },
                    groupId: { ideal: groupId || currAudioDevice.groupId }
                },
                video: false
            })
                .then((stream) => {
                    const track = stream.getAudioTracks()[0]
                    if (track) {
                        resolve({ audioStream: stream })
                    }
                })
                .catch(() => {
                    store.dispatch(setMicStatus(false))
                    resolve({ audioStream: null })
                })
        })
    }

    setPreviewAudio = (stream) => {
        this.previewStream.addTrack(stream.getAudioTracks()[0]);
    }

    setMainAudio = (stream) => {
        this.mediaStream.addTrack(stream.getAudioTracks()[0])
    }

    setPreviewVideo = (stream) => {
        this.previewStream.addTrack(stream.getVideoTracks()[0]);
        try {
            document.getElementById('preview_video').srcObject = stream;
        }
        catch { }
    }

    setMainVideo = (stream) => {
        const { userId } = store.getState().videoroom;
        this.mediaStream.addTrack(stream.getVideoTracks()[0])
        try {
            document.getElementById('video_' + userId).srcObject = stream;
        }
        catch { }
    }

    setScreenVideo = (stream) => {
        const { userId } = store.getState().videoroom;
        window.setTimeout(() => {
            try {
                document.getElementById('video_' + userId + '-screen').srcObject = stream;
                document.getElementById('video_' + userId).srcObject = this.mediaStream
            }
            catch { }
        }, 500)
    }

    stopPreviewTracks = () => {
        this.previewStream.getTracks().forEach(track => track.stop())
    }

    stopMainVideoTracks = () => {
        this.mediaStream.getVideoTracks().forEach(track => track.stop())
    }

    getAudioDevices = () => {
        return new Promise((resolve, reject) => {
            navigator.mediaDevices.enumerateDevices()
                .then((devices) => {
                    const audioDevices = devices.filter(device => device.kind === "audioinput");
                    if (audioDevices.length > 0) {
                        resolve(audioDevices)
                    }
                })
                .catch((err) => {
                    resolve(null)
                })
        })
    }

    updateAudioStatus = (status) => {
        this.mediaStream.getAudioTracks().forEach((track) => {
            track.enabled = status
        })
    }

    mergeAudioTracks = () => {
        let scAudioTrack = this.screenStream.getAudioTracks()[0];
        let audioTrack = this.mediaStream.getAudioTracks()[0];
        let finalTrack = null;
        if (scAudioTrack && audioTrack) {
            const audioContext = new AudioContext();
            let m1 = new MediaStream();
            let m2 = new MediaStream();
            m1.addTrack(audioTrack)
            m2.addTrack(scAudioTrack)
            let audioInput1 = audioContext.createMediaStreamSource(m1);
            let audioInput2 = audioContext.createMediaStreamSource(m2);
            let dest = audioContext.createMediaStreamDestination()
            audioInput1.connect(dest);
            audioInput2.connect(dest);
            finalTrack = dest.stream.getAudioTracks()[0];
        }
        else if (!scAudioTrack) {
            if (audioTrack)
                finalTrack = audioTrack;
        }
        else if (!audioTrack) {
            if (scAudioTrack)
                finalTrack = scAudioTrack
        }

        return finalTrack
    }

    replaceAudioTracks = (newStream) => {
        let audioTrack = newStream.getAudioTracks()[0];
        this.mediaStream.getAudioTracks().forEach((track) => {
            this.mediaStream.removeTrack(track);
        })
        this.mediaStream.addTrack(audioTrack);
    }

    replaceVideoTracks = (newStream) => {
        let videoTrack = newStream.getVideoTracks()[0];
        this.mediaStream.getVideoTracks().forEach((track) => {
            this.mediaStream.removeTrack(track);
        })
        this.mediaStream.addTrack(videoTrack);
    }

    getConnectedAudioDevice = (oldDevices, newDevices) => {
        let connectedDevice;
        if (newDevices.length > oldDevices.length) { //if new device is added
            for (const nd of newDevices) {
                let flag = false;
                for (const d of oldDevices) {
                    if (nd.deviceId === d.deviceId) {
                        flag = true;
                    }
                }
                if (!flag) {
                    connectedDevice = nd
                    break;
                }
            }
        }
        else if (newDevices.length < oldDevices.length) { //if a device is removed
            connectedDevice = newDevices.find(device => device.deviceId === "default");
        }
        else { // add/remove wired earphones 
            const newAudioStr = newDevices.reduce((acc, d) => { return acc + d.label + ':' }, '')
            const oldAudioStr = oldDevices.reduce((acc, d) => { return acc + d.label + ':' }, '')
            if (newAudioStr !== oldAudioStr) { //check contents of the array
                connectedDevice = newDevices.find(device => device.deviceId === "default");
            }
        }
        return connectedDevice
    }

    getVideoDimensions = () => {
        let width, height;
        const { device } = store.getState().videoroom
        const { currVideoQuality } = store.getState().video
        const { permissions } = store.getState()

        if (permissions.canChangeVideoQuality) {
            width = device.mobile ? currVideoQuality.mbWidth : currVideoQuality.width
            height = device.mobile ? currVideoQuality.mbHeight : currVideoQuality.height
        }
        else {
            width = device.mobile ? 240 : 360
            height = device.mobile ? 360 : 240
        }
        return ({ width, height });
    }

    getVideoConstraints = (deviceId) => {
        const { width, height } = this.getVideoDimensions();
        const { device } = store.getState().videoroom;
        const { currVideoDevice, isUserFacingCam } = store.getState().video
        let constraint = {
            width: { ideal: width },
            height: { ideal: height },
            aspectRatio: { ideal: 1.777 },
            resizeMode: 'none',
            frameRate: 20
        }
        if (device.laptop) {
            constraint.deviceId = { ideal: deviceId || (currVideoDevice ? currVideoDevice.deviceId : undefined) }
        }
        else {
            constraint.facingMode = {
                ideal: isUserFacingCam ? 'user' : 'environment'
            }
        }
        return {
            video: constraint,
            audio: false
        }
    }

    getVideoStream = (deviceId = null) => {
        return new Promise((resolve, reject) => {
            navigator.mediaDevices.getUserMedia(this.getVideoConstraints(deviceId))
                .then((stream) => {
                    const track = stream.getVideoTracks()[0]
                    const mstream = new MediaStream()
                    mstream.addTrack(track)
                    if (track) {
                        resolve({ videoStream: mstream })
                    }
                })
                .catch(() => {
                    store.dispatch(setCameraStatus(false))
                    resolve({ videoStream: null })
                })
        })
    }

    getVideoDevices = () => {
        return new Promise((resolve, reject) => {
            navigator.mediaDevices.enumerateDevices()
                .then((devices) => {
                    const videoDevices = devices.filter(device => device.kind === "videoinput");
                    if (videoDevices.length > 0) {
                        resolve({ videoDevices: videoDevices })
                    }
                })
                .catch(() => {
                    resolve({ videoDevices: null })
                })
        })
    }

    setVideoDimensions = () => {
        const { device } = store.getState().videoroom;
        const { currVideoQuality } = store.getState().video;

        if (this.mediaStream) {
            let constraints = {
                width: device.mobile ? currVideoQuality.mbWidth : currVideoQuality.width,
                height: device.mobile ? currVideoQuality.mbHeight : currVideoQuality.height,
            };
            this.mediaStream.getVideoTracks().forEach(async track => {
                try {
                    await track.applyConstraints(constraints);
                }
                catch { }
            })
        }
    }

    updatePreviewVideoQuality = async (option) => {
        const { device } = store.getState().videoroom
        let constraints = {
            width: { ideal: device.mobile ? option.mbWidth : option.width },
            height: { ideal: device.mobile ? option.mbHeight : option.height },
            aspectRatio: { ideal: 1.777 },
            resizeMode: 'none'
        };
        this.previewStream.getVideoTracks().forEach(async track => {
            try {
                await track.applyConstraints(constraints);
            }
            catch { }
        })

    }

    updateVideoQuality = async (option) => {
        const { device } = store.getState().videoroom
        let constraints = {
            width: { ideal: device.mobile ? option.mbWidth : option.width },
            height: { ideal: device.mobile ? option.mbHeight : option.height },
            aspectRatio: { ideal: 1.777 },
            resizeMode: 'none'
        };
        this.mediaStream.getVideoTracks().forEach(async track => {
            try {
                await track.applyConstraints(constraints);
            }
            catch (err) { console.log(err) }
        })
    }

    getScreenStream = () => {
        return new Promise((resolve, reject) => {
            navigator.mediaDevices.getDisplayMedia({
                video: {
                    width: 1920,
                    height: 1080,
                    frameRate: 20
                },
                audio: true
            })
                .then((stream) => {
                    this.screenStream = stream
                    resolve({ screenStream: stream })
                })
                .catch(() => {
                    reject({ msg: ERROR.SCREEN_PERM })
                })
        })
    }

    stopScreenStream = () => {
        this.screenStream.getTracks().forEach(track => track.stop())
    }

    getCanvasStream = () => {
        let canvas = document.getElementById('vc_dummy_canvas');
        if (canvas) {
            // eslint-disable-next-line
            var ctx = canvas.getContext("2d");
            let stream = canvas.captureStream(0);
            stream.getVideoTracks()[0].requestFrame();
            return stream
        }
    }

    setCanvasStream = (stream) => {
        this.canvasStream.addTrack(stream.getVideoTracks()[0])
    }

    stopCanvasStream = () => {
        let canvas = document.getElementById('vc_dummy_canvas');
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
    }

    createAnnotations = (className, color) => {
        const { device } = store.getState().videoroom;
        if (this.canvasStream.getVideoTracks()[0]) {
            this.canvasStream.getVideoTracks()[0].requestFrame();
        }
        let canvas = document.getElementById("vc_dummy_canvas");
        if (canvas) {
            let context = canvas.getContext("2d");
            context.fillStyle = '#303a4c';
            context.fillRect(0, 0, canvas.width, canvas.height);

            const centerX = canvas.width / 2;
            const centerY = canvas.height / 2;
            context.beginPath();
            context.fillStyle = color;
            context.arc(centerX, centerY, device.laptop ? 40 : 75, 0, 2 * Math.PI, false);
            context.fill();

            context.font = device.laptop ? '24px Lato' : '36px Lato';
            context.fillStyle = 'white';
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            context.fillText(className.toUpperCase().slice(0, 2), centerX, centerY);
        }
    }

    stopAllTracks = () => {
        this.mediaStream.getTracks().forEach(track => track.stop())
        this.previewStream.getTracks().forEach(track => track.stop())
        this.screenStream.getTracks().forEach(track => track.stop())
        this.canvasStream.getTracks().forEach(track => track.stop())
    }
}